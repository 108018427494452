import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import CategoryActionDropdown from "../../components/CategoryActionDropDown";
import PackageSizesActionDropdown from "../../components/PackageSizesActionDropdown";

const EcommerceOrderColumns = (setDataObject,handleModalShow,getAllCategories,deleteType,isDuration) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "duration",
    text: "Duration",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {`${row.duration} DAYS`}
      </Link>
    ),
  },
  
  {
    dataField: "type",
    text: "Transportation Type",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.type === "AIR" ? "success" : "warning")
        }
        color={row.type === "AIR"  ? "success" : "warning"}
        pill
      >
        {row.type}
      </Badge>
    ),
  },
  
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <PackageSizesActionDropdown
        row={row}
        setDataObject={setDataObject}
        handleModalShow={handleModalShow}
        getAllCategories={getAllCategories}
        deleteType={deleteType}
        isDuration={isDuration}
      />
    ),
  },
  
];

export default EcommerceOrderColumns;
