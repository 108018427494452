/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";

// ecommerce active tab
export const CHANGE_ACTIVE_ECOMMERCE_TAB = "CHANGE_ACTIVE_ECOMMERCE_TAB";


// vendor active tabs
export const VENDOR_ACTIVE_TABS = "VENDOR_ACTIVE_TABS"

// address form title
export const FORM_PAGE_TITLE = "FORM_PAGE_TITLE";

// umbrella api call
export const UMBRELLA_API_CALL_STATE = "UMBRELLA_API_CALL_STATE"; 