import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { setActiveTab } from "../../store/userAuthInfo/actions"; 
import { useDispatch } from "react-redux";

import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import MasterForm from "../../components/VendorRegistration/MasterForm";

const EcommerceOrders = () => {
   const activeTabs = useSelector((state) => state.userAuthInfo.activeTab);
   
  const [viewModal, setViewModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTabs] = useState(() => {
    if (activeTabs === "ACTIVE") return "ACTIVE";
    if (activeTabs === "BLOCKED") return "BLOCKED";
    if (activeTabs === "PENDING") return "PENDING";
    if(activeTabs === "ALL") return "ALL";
    return "ALL";
  });
  const [searchString, setSearchString] = useState("");
  const [documents, setDocuments] = useState([]);
  const user = useSelector((state) => state.userAuthInfo.user);
  
  const handleAddVendorModal = () => {
    setViewModal((prev) => !prev);
  };
  const handleShowImageModal = () => {
    setShowImageModal((prev) => !prev);
  };
  const dispatch = useDispatch();
   const handleTabChange = (newTab) => {
     dispatch(setActiveTab(newTab)); 
     if (activeTab !== newTab) {
       setActiveTabs(newTab);
      }
   };
  useEffect(() => {
    getAllVendorRequests();
  }, [activeTab,user]);
  async function getAllVendorRequests() {
    try {
      const content = await post("/api/vendor-requests", { status: activeTab });
      setOrders(content);
    } catch (e) {
      console.log(e);
    }
  }
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };


const handleDownload = (fileUrl) => {
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "document");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => console.error("Error downloading the file:", error));
};

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Vendor Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Vendor Management", link: "/vendor-requests" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "ALL" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("ALL")}
                              >
                                ALL
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "PENDING" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("PENDING")}
                              >
                                PENDING
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "ACTIVE" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("ACTIVE")}
                              >
                                ACTIVE
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "BLOCKED" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("BLOCKED")}
                              >
                                BLOCKED
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          getAllVendorRequests,
                          setDocuments,
                          handleShowImageModal,
                          activeTab
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      value={searchString}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddVendorModal}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Add New
                                    Vendor
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ height: "500px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={viewModal} toggle={handleAddVendorModal}>
        <ModalHeader toggle={handleAddVendorModal}>Add New Vendor</ModalHeader>
        <ModalBody>
          <MasterForm
            isAdmin={true}
            handleAddVendorModal={handleAddVendorModal}
            getAllVendorRequests={getAllVendorRequests}
          />
        </ModalBody>
      </Modal>
      <Modal size="xl" isOpen={showImageModal} toggle={handleShowImageModal}>
        <ModalHeader toggle={handleShowImageModal}>
          Vendor Documents
        </ModalHeader>
        <ModalBody>
          <div>
            {documents?.vendor?.panFilePath ? (
              <>
                <div style={{ fontSize: "20px" }}>Pan Card</div>
                <img
                  src={`${
                    process.env.REACT_APP_AUTHDOMAIN
                  }/api/${documents?.vendor?.panFilePath?.replace(/\\/g, "/")}`}
                  width="200px"
                />
                <Button
                  style={{
                    marginLeft: "20px",
                    backgroundColor: "#00b4d8",
                    border: "none",
                  }}
                  onClick={() =>
                    handleDownload(
                      `${
                        process.env.REACT_APP_AUTHDOMAIN
                      }/api/${documents?.vendor?.panFilePath?.replace(
                        /\\/g,
                        "/"
                      )}`
                    )
                  }
                >
                  Download
                </Button>
              </>
            ) : null}
          </div>
          <div style={{ marginTop: "20px" }}>
            <div style={{ fontSize: "20px" }}>Aadhar Card</div>
            {documents?.vendor?.aadharFilePath ? (
              <>
                <img
                  src={`${
                    process.env.REACT_APP_AUTHDOMAIN
                  }/api/${documents?.vendor?.aadharFilePath?.replace(
                    /\\/g,
                    "/"
                  )}`}
                  width="200px"
                />
                <Button
                  style={{
                    marginLeft: "20px",
                    backgroundColor: "#00b4d8",
                    border: "none",
                  }}
                  onClick={() =>
                    handleDownload(
                      `${
                        process.env.REACT_APP_AUTHDOMAIN
                      }/api/${documents?.vendor?.aadharFilePath?.replace(
                        /\\/g,
                        "/"
                      )}`
                    )
                  }
                >
                  Download
                </Button>
              </>
            ) : null}
          </div>
          <div style={{ marginTop: "20px" }}>
            <div style={{ fontSize: "20px" }}>GST Certificate</div>
            {documents?.vendor?.gstCertificateFilePath ? (
              <>
                <img
                  src={`${
                    process.env.REACT_APP_AUTHDOMAIN
                  }/api/${documents?.vendor?.gstCertificateFilePath?.replace(
                    /\\/g,
                    "/"
                  )}`}
                  width="200px"
                />
                <Button
                  style={{
                    marginLeft: "20px",
                    backgroundColor: "#00b4d8",
                    border: "none",
                  }}
                  onClick={() =>
                    handleDownload(
                      `${
                        process.env.REACT_APP_AUTHDOMAIN
                      }/api/${documents?.vendor?.aadharFilePath?.replace(
                        /\\/g,
                        "/"
                      )}`
                    )
                  }
                >
                  Download
                </Button>
              </>
            ) : null}
          </div>
          <div style={{ marginTop: "20px" }}>
            <div style={{ fontSize: "20px" }}>Cancelled Cheque</div>
            {documents?.vendor?.cancelledChequeFilePath ? (
              <>
                <img
                  src={`${
                    process.env.REACT_APP_AUTHDOMAIN
                  }/api/${documents?.vendor?.cancelledChequeFilePath?.replace(
                    /\\/g,
                    "/"
                  )}`}
                  width="200px"
                />
                <Button
                  style={{
                    marginLeft: "20px",
                    backgroundColor: "#00b4d8",
                    border: "none",
                  }}
                  onClick={() =>
                    handleDownload(
                      `${
                        process.env.REACT_APP_AUTHDOMAIN
                      }/api/${documents?.vendor?.aadharFilePath?.replace(
                        /\\/g,
                        "/"
                      )}`
                    )
                  }
                >
                  Download
                </Button>
              </>
            ) : null}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EcommerceOrders;
