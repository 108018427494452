import {
  ADD_USER_INFO,
  REMOVE_USER_INFO,
  SELECTED_TICKET,
  SELECT_CATEGORY,
  SELECT_PRODUCT,
  SET_ACTIVE_TAB,
} from "./actionTypes";

const initialState = {
  user: null,
  selectedCategoryForUpdate: null,
  selectedProductForUpdate: null,
  selectedticketToViewChat: null,
  activeTab: "PLACED",
};

const userAuthInfo = (state = initialState, action) => {
    switch (action.type) {
      case ADD_USER_INFO:
        state = {
          ...state,
          user: action.payload,
        };
        break;
      case SELECT_CATEGORY:
        state = {
          ...state,
          selectedCategoryForUpdate: action.payload,
        };
        break;
      case SELECT_PRODUCT:
        state = {
          ...state,
          selectedProductForUpdate: action.payload,
        };
        break;
      case SELECTED_TICKET:
        state = {
          ...state,
          selectedticketToViewChat: action.payload,
        };
        break;
      case REMOVE_USER_INFO:
        state = {
          ...state,
          user: null,
        };
        break;
      case SET_ACTIVE_TAB:
        return {
          ...state,
          activeTab: action.payload, 
        };

      default:
        state = { ...state };
        break;
    }
    return state;
}

export default userAuthInfo;