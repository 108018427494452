import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
  TabContent,
  TabPane,
  FormGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import toastr from "toastr";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../helpers/api_helper";
import { selectCategoryToUpdate } from "../../store/userAuthInfo/actions";

const AddNewChildCategories = () => {
  const dispatch = useDispatch();
  const selectedCategoryForUpdate = useSelector(
    (state) => state.userAuthInfo.selectedCategoryForUpdate
  );

  const [showDropdown, setShowDropdown] = useState(false);
  const [showSubDropdown, setShowSubDropdown] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState({
    name: selectedCategoryForUpdate?.row?.name || "",
    description: selectedCategoryForUpdate?.row?.description || "",
    slug: selectedCategoryForUpdate?.row?.slug || "",
  });
  const [selectedItem, setSelectedItem] = useState(
    selectedCategoryForUpdate?.row?.parent_category_id || null
  );
  // Set subcategory ID from selectedCategoryForUpdate if available
  const [subcategoryId, setSubCategoriesId] = useState(
    selectedCategoryForUpdate?.row?.sub_category_id || null
  );

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const navigation = useHistory();
  const [categoryFile, setCategoryFile] = useState(null);
  const [categoryIconFile, setCategoryIconFile] = useState(null);
  const [isActiveCategory, setIsActiveCategory] = useState(
    selectedCategoryForUpdate?.row?.isActive || true
  );
  const [loading, setLoading] = useState(false);

  const breadcrumbItems = [
    { title: "Dashboard", link: "/" },
    { title: "Categories", link: "/categories" },
    { title: "Add New Child Category", link: "#" },
  ];

  function toggleDropdown() {
    setShowDropdown((prev) => !prev);
  }

  function toggleSubCategoryDropdown() {
    setShowSubDropdown((prev) => !prev);
  }

  const handleItemClick = (item) => {
    setSelectedItem(item?.id);
    setSubCategoriesId(null); // Reset subcategory selection when changing parent category
  };

  const handleSubCategoryClick = (item) => {
    setSubCategoriesId(item.id);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCategoryInfo((prev) => ({ ...prev, [name]: value }));
  };

  const isImageType = (file) => {
    const imageTypes = ["image/jpeg", "image/png"];
    return imageTypes.includes(file.type);
  };

  React.useEffect(() => {
    getAllCategories();
    getAllSubCategories();
    return () => {
      dispatch(selectCategoryToUpdate(null));
    };
  }, []);

  async function getAllCategories() {
    try {
      const content = await post("/api/ecom/get-categories", {});
      setCategories(content);
    } catch (e) {
      console.log(e);
    }
  }

  async function getAllSubCategories() {
    try {
      const content = await post("/api/ecom/get-sub-categories", {});
      setSubCategories(content);
    } catch (e) {
      console.log(e);
    }
  }

  const handleCategoryFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && isImageType(selectedFile)) {
      setCategoryFile(
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile),
        })
      );
    } else {
      alert("Please select a valid image file (JPEG, PNG)");
    }
  };

  const handleSubmit = async (event, values) => {
    try {
      setLoading(true);
      if (categoryInfo.name === "" || categoryInfo.slug === "") {
        let errorMessage = "Please fill in the following fields:\n";
        if (categoryInfo.name === "") {
          errorMessage += "- Name\n";
        }
        if (!categoryFile) {
          errorMessage += "- Category image\n";
        }
        if (categoryInfo.slug === "") {
          errorMessage += "- Slug\n";
        }
        if (!selectedItem) {
          errorMessage += "- Parent category is required\n";
        }
        alert(errorMessage);
        setLoading(false);
      } else {
        const formData = new FormData();
        formData.append("categoryImage", categoryFile);
        formData.append("categoryIcon", categoryIconFile);
        formData.append(
          "objectDetails",
          JSON.stringify({
            ...categoryInfo,
            ...(selectedCategoryForUpdate?.row?.id && {
              id: selectedCategoryForUpdate?.row?.id,
            }),
            parentCategoryId: selectedItem,
            subCategoryId: subcategoryId,
          })
        );

        const config = {
          method: "POST",
          body: formData,
        };
        let responseObj = await fetch(
          `${process.env.REACT_APP_AUTHDOMAIN}/api/ecom/create-childcategory`,
          config
        );
        const res = await responseObj.json();
        if (res?.message) {
          toastr.error(res?.message, "Error");
          setLoading(false);
          return;
        }
        navigation.push("/ecom-child-categories");
        setLoading(false);
      }
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Add Child Category"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div
                    id="addproduct-nav-pills-wizard"
                    className="twitter-bs-wizard"
                  >
                    <TabContent
                      activeTab={1}
                      className="twitter-bs-wizard-tab-content"
                    >
                      <TabPane tabId={1}>
                        <CardTitle className="h5">Basic Information</CardTitle>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                        <div
                          className="mb-3"
                          style={{ display: "flex", gap: "50px" }}
                        >
                          <div>
                            <Label
                              className="form-label me-4"
                              htmlFor="categoryPublish"
                            >
                              Parent Category
                            </Label>
                            <Dropdown
                              isOpen={showDropdown}
                              toggle={toggleDropdown}
                            >
                              <DropdownToggle color="primary" caret>
                                {categories.find(
                                  (element) => element.id === selectedItem
                                )?.name || "Select Parent Category"}
                                <i className="mdi mdi-chevron-down"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                {categories
                                  .filter((el) => !el.parent_category_id)
                                  .map((item) => (
                                    <DropdownItem
                                      key={item.id}
                                      onClick={() => handleItemClick(item)}
                                    >
                                      {item.name}
                                    </DropdownItem>
                                  ))}
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                          {selectedItem != null && (
                            <div>
                              <Label
                                className="form-label me-4"
                                htmlFor="categoryPublish"
                              >
                                Sub Category
                              </Label>
                              <Dropdown
                                isOpen={showSubDropdown}
                                toggle={toggleSubCategoryDropdown}
                              >
                                <DropdownToggle color="primary" caret>
                                  {subCategories.find(
                                    (element) => element.id === subcategoryId
                                  )?.name || "Select Sub Category"}{" "}
                                  <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {subCategories
                                    .filter(
                                      (el) =>
                                        el.parent_category_id === selectedItem
                                    )
                                    .map((item) => (
                                      <DropdownItem
                                        key={item.id}
                                        onClick={() =>
                                          handleSubCategoryClick(item)
                                        }
                                      >
                                        {item.name}
                                      </DropdownItem>
                                    ))}
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="productname">
                            Child Category Name
                          </Label>
                          <Input
                            id="productname"
                            name="name"
                            type="text"
                            value={categoryInfo?.name}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="productdesc">
                            Child Category Slug
                          </Label>
                          <Input
                            id="productdesc"
                            name="slug"
                            type="text"
                            value={categoryInfo?.slug}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                          <div className="mb-3">
                           <Label for="pan">Category image</Label>
                           <FormGroup>
                             <Input
                              type="file"
                              name="file"
                              id="category"
                              onChange={handleCategoryFileChange}
                            />
                            <img
                              src={
                                categoryFile?.preview
                                  ? categoryFile?.preview
                                  : selectedCategoryForUpdate?.row?.image_url
                                  ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${selectedCategoryForUpdate?.row?.image_url}`
                                  : ``
                              }
                              width="150px"
                            />
                          </FormGroup>
                        </div>
                        <FormGroup className="mb-0">
                          <div className="d-flex">
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="btn btn-primary"
                            >
                              {loading ? (
                                <Spinner size="sm" color="light" />
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </FormGroup>
                      </TabPane>
                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddNewChildCategories;
