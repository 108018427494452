import React, { useState } from 'react';
import Select from 'react-select';

const SearchableDropdown = ({ options, onSelect,defaultValue }) => {
  
  const [selectedOption, setSelectedOption] = useState(defaultValue?defaultValue:null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelect(selectedOption);
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      isSearchable={true}
      placeholder="Select an option..."
    />
  );
};

export default SearchableDropdown;
