import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import AdminWithdrawalAction from "../../components/AdminWithdrawalAction";

const EcommerceOrderColumns = (
  getWithdrawalRequests,
  setBankDetails,
  handleAddVendorModal,
  activeTab
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },

  {
    dataField: "amount",
    text: "Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.amount ? `$${row.amount}` : "-"}
      </Link>
    ),
  },
  {
    dataField: "note",
    text: "Notes",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.note ? row.note : "-"}
      </Link>
    ),
  },
  {
    dataField: "vendor.email",
    text: "Vendor Email",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.vendor?.email}
      </Link>
    ),
  },
  {
    dataField: "status",
    text: "Request Status",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.status === "ACCEPTED"
            ? "success"
            : row.status === "PENDING"
            ? "warning"
            : "danger")
        }
        color={
          row.status === "ACCEPTED"
            ? "success"
            : row.status === "PENDING"
            ? "warning"
            : "danger"
        }
        pill
      >
        {row.status}
      </Badge>
    ),
  },
  activeTab !== "ACCEPTED" &&
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <AdminWithdrawalAction
        row={row}
        getWithdrawalRequests={getWithdrawalRequests}
        activeTab={activeTab}
      />
    ),
  },
  {
    dataField: "bank",
    isDummyField: true,
    text: "Bank Details",
    formatter: (cellContent, row) => (
      <Button
        onClick={() => {
          setBankDetails(row?.vendor?.bank_details[0]);
          handleAddVendorModal();
        }}
        style={{ backgroundColor: "#1fbcdd" }}
      >
        <i className="mdi mdi-bank font-size-18"></i>
      </Button>
    ),
  },
];

export default EcommerceOrderColumns;
