import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Editor from "react-simple-wysiwyg";

import toastr from "toastr";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { del, get, post } from "../../helpers/api_helper";
import ImageAndUrlHandler from "../../components/AddNewSliderImage";
import AddProductToPopularList from "../../components/AddProductToPopularList";

const FAQS = () => {
  const [faq, setFaq] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [showForm, setShowForm] = React.useState(false);

  React.useEffect(() => {
    fetchAllFAQS();
  }, []);

  async function fetchAllFAQS() {
    try {
      const response = await get("/api/faq");
      if (response) {
        setFaq(response.map((el) => ({ ...el, isOpen: false })));
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function createFaq() {
    try {
      if (!title) {
        toastr.error("Please provide title");
        return;
      }
      if (!description) {
        toastr.error("Please provide description");
        return;
      }
      await post("/api/faq/create", { title, description });
      setDescription("");
      setTitle("");
      fetchAllFAQS();
      setShowForm((prev) => !prev);
    } catch (e) {
      console.log(e);
    }
  }
  async function deleteFaq(id) {
    try {
      await del(`/api/faq/${id}`);
      fetchAllFAQS();
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Frequently Asked Questions"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Frequently Asked Questions", link: "#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <h3>FAQ's</h3>
                  <div style={{ margin: "20px 0" }}>
                    {faq?.length
                      ? faq.map((item) => {
                          return (
                            <Row>
                              <Col md={10}>
                                <Card>
                                  <Link
                                    to="#"
                                    className="text-dark"
                                    onClick={() => {
                                      const itemIndex = faq.findIndex(
                                        (element) => element.id === item.id
                                      );
                                      const objectInfo = faq[itemIndex];
                                      objectInfo.isOpen = !objectInfo.isOpen;
                                      faq[itemIndex] = objectInfo;
                                      setFaq(faq);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <CardHeader id="gen-question-headingOne">
                                      <h5 className="font-size-14 m-0">
                                        <i
                                          className={
                                            item?.isOpen
                                              ? "mdi mdi-chevron-up accor-arrow-icon"
                                              : "mdi mdi-chevron-right accor-arrow-icon"
                                          }
                                        ></i>{" "}
                                        {item?.title}
                                      </h5>
                                    </CardHeader>
                                  </Link>

                                  <Collapse isOpen={item?.isOpen}>
                                    <CardBody>{item?.description}</CardBody>
                                  </Collapse>
                                </Card>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "start",
                                  
                                }}
                              >
                                <Button
                                  color="primary"
                                  className="primary"
                                  onClick={() => {
                                    deleteFaq(item?.id);
                                  }}
                                >
                                  <i className="mdi mdi-delete font-size-14"></i>
                                </Button>
                              </Col>
                            </Row>
                          );
                        })
                      : null}
                  </div>
                  {!showForm ? (
                    <Link to="#" onClick={() => setShowForm((prev) => !prev)}>
                      Add new FAQ
                    </Link>
                  ) : null}
                  {showForm ? (
                    <>
                      <Row>
                        <Col md={12}>
                          <Label for="pan">New FAQ Title</Label>
                          <FormGroup>
                            <Input
                              type="text"
                              name="attachedUrl"
                              id="category"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label className="form-label">
                            New FAQ Description
                          </Label>
                          <Editor
                            containerProps={{ style: { height: "200px" } }}
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Col md={3}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                          }}
                        >
                          <Button
                            style={{ marginTop: "30px" }}
                            color="primary"
                            className="primary"
                            onClick={createFaq}
                          >
                            Save
                          </Button>
                        </div>
                      </Col>
                    </>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={false} toggle={() => {}}>
        <ModalHeader toggle={() => {}}>Add New Vendor</ModalHeader>
        <ModalBody></ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default FAQS;
