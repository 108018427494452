import React from "react";
import { FormGroup, Label, Input, FormText, Row, Col, Alert } from "reactstrap";

const Step4 = (props) => {
  if (props.currentStep !== 3) {
    return null;
  }
  return (
    <>
      <h4 style={{ fontWeight: 600 }}>Verification Documents</h4>
      <h5 style={{ fontWeight: 400 }}>
        Please upload the following documents for identity and business
        verification:
      </h5>

      <DivWIthMargin>
        <Label for="pan">PAN</Label>
        <FormGroup>
          <Input
            type="file"
            name="file"
            id="pan"
            onChange={props.handlePanFileChange}
          />
        </FormGroup>
      </DivWIthMargin>
      <DivWIthMargin>
        <Label for="aadhar">Aadhar Card</Label>
        <FormGroup>
          <Input
            type="file"
            name="file"
            id="aadhar"
            onChange={props.handleAadharFileChange}
          />
        </FormGroup>
      </DivWIthMargin>
      <DivWIthMargin>
        <Label for="aadhar">GST Certificate</Label>
        <FormGroup>
          <Input
            type="file"
            name="file"
            id="aadhar"
            onChange={props.handleGSTFileChange}
          />
        </FormGroup>
      </DivWIthMargin>
      <DivWIthMargin>
        <Label for="aadhar">Cancelled Cheque</Label>
        <FormGroup>
          <Input
            type="file"
            name="file"
            id="aadhar"
            onChange={props.handleChequeFileChange}
          />
        </FormGroup>
      </DivWIthMargin>

      <DivWIthMargin>
        <FormGroup tag="fieldset">
          <Label for="approval">
            {!props.isAdmin
              ? `Are you planning to sell products that require approval from
            Shipora?`
              : `Is the vendor planning to sell products that require approval from
            Shipora?`}
          </Label>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                id="yesplan"
                checked={props.vendorDetails.isProductApprovalRequired === "true"}
                name="isProductApprovalRequired"
                value="true"
                onChange={props.handleChange}
              />
              Yes
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                id="noplan"
                checked={props.vendorDetails.isProductApprovalRequired === "false"}
                name="isProductApprovalRequired"
                value="false"
                onChange={props.handleChange}
              />
              No
            </Label>
          </FormGroup>
        </FormGroup>
      </DivWIthMargin>
      {!props.isAdmin ? (
        <DivWIthMargin>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={props.shiporaAgreement}
                onChange={() => props.setShiporaAgreement((prev) => !prev)}
              />
              I have reviewed and agree to Shipora Agreement.
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={props.shiporaPolicies}
                onChange={() => {
                  props.setShiporaPolicies((prev) => !prev);
                }}
              />
              I have reviewed and agree to Shipora's policies.
            </Label>
          </FormGroup>
        </DivWIthMargin>
      ) : null}
    </>
  );
};

function DivWIthMargin({ children }) {
  return <div style={{ margin: "20px 0" }}>{children}</div>;
}

export default Step4;
