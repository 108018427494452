import React from "react";
import { Input, Label } from "reactstrap";
import Editor from "react-simple-wysiwyg";
import { post } from "../helpers/api_helper";
import SearchableDropdownProducts from "./SearchableDropdownProducts";

function ProductBasicDetails({
  categories,
  handleChange,
  product,
  setProduct,
  subCategory,
  childCategory,
}) {
  console.log("categories",categories);
  console.log("sub categories",subCategory);
  console.log("child categories",childCategory);
    const [subcategoryKey, setSubcategoryKey] = React.useState(0);
    const [childCategoryKey, setChildCategoryKey] = React.useState(0);

    function handleCategoryChange(item) {
      setProduct((prev) => ({
        ...prev,
        category_id: item?.value,
        sub_category_id: null,
        child_category_id: null,
      }));
      setSubcategoryKey((prevKey) => prevKey + 1); 
      setChildCategoryKey((prevKey) => prevKey + 1);
    }

    function handleSubCategoryChange(item) {
      setProduct((prev) => ({
        ...prev,
        sub_category_id: item?.value,
        child_category_id: null, 
      }));
      setChildCategoryKey((prevKey) => prevKey + 1); 
    }

    function handleChildCategoryChange(item) {
      setProduct((prev) => ({ ...prev, child_category_id: item.value }));
    }
  
  
  
  // function handleCategoryChange(item) {
  //   setProduct((prev) => ({ ...prev,category_id: item?.value}));
  // }
  // function handleSubCategoryChange(item) {
  //   setProduct((prev) => ({ ...prev, sub_category_id: item?.value }));
  // }
  // function handleChildCategoryChange(item){
  //   setProduct((prev) => ({ ...prev, child_category_id : item.value }));
  // }
  
  return (
    <form>
      <div className="mb-3">
        <Label className="form-label" htmlFor="productname">
          Product name
        </Label>
        <Input
          id="productname"
          name="name"
          value={product.name}
          onChange={handleChange}
          type="text"
          className="form-control"
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="productdesc">
          Product Description
        </label>
        <Editor
          containerProps={{ style: { height: "200px" } }}
          value={product.description}
          onChange={(e) => {
            setProduct((prev) => ({
              ...prev,
              description: e.target.value,
            }));
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="key_features">
          Key Features
        </label>
        <Editor
          containerProps={{ style: { height: "200px" } }}
          value={product.key_features}
          onChange={(e) => {
            setProduct((prev) => ({
              ...prev,
              key_features: e.target.value,
            }));
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="materials">
          Materials
        </label>
        <Editor
          containerProps={{ style: { height: "200px" } }}
          value={product.materials}
          onChange={(e) => {
            setProduct((prev) => ({
              ...prev,
              materials: e.target.value,
            }));
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="assembly_instructions">
          Assembly Instructions
        </label>
        <Editor
          containerProps={{ style: { height: "200px" } }}
          value={product.assembly_instructions}
          onChange={(e) => {
            setProduct((prev) => ({
              ...prev,
              assembly_instructions: e.target.value,
            }));
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="additional_information">
          Additional Information
        </label>
        <Editor
          containerProps={{ style: { height: "200px" } }}
          value={product.additional_information}
          onChange={(e) => {
            setProduct((prev) => ({
              ...prev,
              additional_information: e.target.value,
            }));
          }}
        />
      </div>
      {/* <div className="mb-3">
        <Label className="form-label" htmlFor="brand_name">
          Brand name
        </Label>
        <Input
          id="brand_name"
          name="brand_name"
          value={product.brand_name}
          onChange={handleChange}
          type="text"
          className="form-control"
        />
      </div> */}

      {/* <div className="row">
        <div className="col-md-4">
          <div className="mb-3">
            <h5 style={{ marginTop: "10px" }}>Select Category</h5>
            <div>
              <SearchableDropdownProducts
                defaultValue={
                  product?.category_id || product?.category_id?.value
                }
                options={categories}
                onSelect={handleCategoryChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-3">
            <h5 style={{ marginTop: "10px" }}>Select Sub Category</h5>
            <div>
              <SearchableDropdownProducts
                defaultValue={ product?.sub_category_id || product?.sub_category_id?.value }
                options={subCategory}
                onSelect={handleSubCategoryChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-3">
            <h5 style={{ marginTop: "10px" }}>Select Child Category</h5>
            <div>
              <SearchableDropdownProducts
                defaultValue={ product?.child_category_id || product?.child_category_id?.value }
                options={childCategory}
                onSelect={handleChildCategoryChange}
              />
            </div>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-4">
          <div className="mb-3">
            <h5 style={{ marginTop: "10px" }}>Select Category</h5>
            <div>
              <SearchableDropdownProducts
                defaultValue={product?.category_id || ""}
                options={categories}
                onSelect={handleCategoryChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-3">
            <h5 style={{ marginTop: "10px" }}>Select Sub Category</h5>
            <div>
              <SearchableDropdownProducts
                key={subcategoryKey} 
                defaultValue={product?.sub_category_id || ""}
                options={subCategory}
                onSelect={handleSubCategoryChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-3">
            <h5 style={{ marginTop: "10px" }}>Select Child Category</h5>
            <div>
              <SearchableDropdownProducts
                key={childCategoryKey}
                defaultValue={product?.child_category_id || ""}
                options={childCategory}
                onSelect={handleChildCategoryChange}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ProductBasicDetails;

