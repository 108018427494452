import React from "react";
import { Link } from "react-router-dom";
import { Badge} from "reactstrap";

const EcommerceOrderColumns = () => [
  {
    dataField: "name",
    text: "Category name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.name}
      </Link>
    ),
  },
  {
    dataField: "slug",
    text: "Slug",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.slug}
      </Link>
    ),
  },
  {
    dataField: "commission",
    text: "Category Commission",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {`${row.ecom_category_commission}%`}
      </Link>
    ),
  },
];

export default EcommerceOrderColumns;
