import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import CategoryActionDropdown from "../../components/EcomCategoryActionDropDown";

const EcommerceOrderColumns = (getAllVendorRequests, categories) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "name",
    text: "Category name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.name}
      </Link>
    ),
  },
  {
    dataField: "slug",
    text: "Slug",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.slug}
      </Link>
    ),
  },
  {
    dataField: "commission",
    text: "Category Commission",
    sort: true,
    formatter: (cellContent, row) =>
      <Link to="#" className="text-body fw-bold">
        {`${row.ecom_category_commission}%`}
      </Link>
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.isActive === true ? "success" : "danger")
        }
        color={row.isActive === true ? "success" : "danger"}
        pill
      >
        {row?.isActive ? "ACTIVE" : "HIDDEN"}
      </Badge>
    ),
  },

  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <CategoryActionDropdown
        row={row}
        getAllVendorRequests={getAllVendorRequests}
        categories={categories}
        isEcom={true}
      />
    ),
  },
];

export default EcommerceOrderColumns;
