import React, { useState } from "react";
import { Input, Label, FormGroup, Col, Row, Spinner, Button } from "reactstrap";
import ProductImages from "./ProductImages";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import toastr from "toastr";

function SoloProduct({
  setLoading,
  user,
  handleChange,
  product,
  setProduct,
  selectedProductForUpdate,
  loading,
  calculateShippingCost,
}) {
  const navigation = useHistory();
  const [selectedFiles, setSelectedFiles] = useState(
    selectedProductForUpdate?.row?.image_urls
      ? selectedProductForUpdate?.row?.image_urls.map((item) => ({
          name: item,
          preview: `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item}`,
          oldImage: true,
        }))
      : []
  );
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const productPrice = parseInt(product.discountedPrice)
        ? product.discountedPrice
        : product.MRP;
      if (
        productPrice < calculateShippingCost(product.weight, "AIR") ||
        productPrice < calculateShippingCost(product.weight, "SHIP")
      ) {
        alert("Product current price must be greater than shipping cost");
        return;
      }
      if (product?.is_shipping_free &&
        window.confirm(
          `Free shipping is enabled for this product \nAmount of shipping for Air: ${calculateShippingCost(
            product.weight,
            "AIR"
          )}, Ship: ${calculateShippingCost(
            product.weight,
            "SHIP"
          )} as per selection by user will be deducted from vendor wallet amount`
        ) === false
      ) {
        return;
      }
      setLoading(true);

      if (
        product.name === "" ||
        product.description === "" ||
        !selectedFiles.length ||
        !product.MRP ||
        !product.width ||
        !product.length ||
        !product.weight ||
        !product.height ||
        // !product.brand_name ||
        !product.category_id ||
        !product.sub_category_id ||
        !product.child_category_id ||
        selectedFiles.length > 3
      ) {
        let errorMessage = "Please fill in the following fields:\n";
        if (product.name === "") {
          errorMessage += "- Name\n";
        }
        if (product.description === "") {
          errorMessage += "- Description\n";
        }
        if (product.width === "") {
          errorMessage += "- Width\n";
        }
        if (product.length === "") {
          errorMessage += "- Length\n";
        }
        if (product.weight === "") {
          errorMessage += "- Weight\n";
        }
        if (product.height === "") {
          errorMessage += "- Height\n";
        }
        // if (product.brand_name === "") {
        //   errorMessage += "- Brand name\n";
        // }
        if (!selectedFiles.length) {
          errorMessage += "- At least one file must be selected\n";
        }
        if (selectedFiles.length > 3) {
          errorMessage += "- Only 3 files can be uploaded\n";
        }
        if (!product.MRP) {
          errorMessage += "- MRP\n";
        }
        if (!product.category_id) {
          errorMessage += "- Category\n";
        }
        if (!product.sub_category_id) {
          errorMessage += "- Sub Category\n";
        }
         if (!product.child_category_id) {
           errorMessage += "- Child Category\n";
         }
        alert(errorMessage);
        setLoading(false);
      } else {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
          if (!selectedFiles[i]?.oldImage) {
            formData.append("files", selectedFiles[i]);
          }
        }

        formData.append(
          "objectDetails",
          JSON.stringify({
            ...product,
            vendor_id: user.id,
            ...(selectedProductForUpdate?.row?.id && {
              id: selectedProductForUpdate?.row?.id,
            }),
            old_images: selectedFiles.filter((el) => el.oldImage),
          })
        );

        const config = {
          method: "POST",
          body: formData,
        };
        
        const responseObj = await fetch(
          `${process.env.REACT_APP_AUTHDOMAIN}/api/product`,
          config
        );
        const res = await responseObj.json();
        toastr.success("Product added!", "Success");
        navigation.push("/manage-products");
        setLoading(false);
      }
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      setLoading(false);
      console.error(e);
    }
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles((prev) => [...prev, ...files]);
  };
  return (
    <>
      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="price">
                MRP
              </Label>
              <Input
                id="mrp"
                name="MRP"
                value={product.MRP}
                onChange={(e) => handleChange(e, true)}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="price">
                Discounted Price
              </Label>
              <Input
                id="price"
                name="discountedPrice"
                value={product.discountedPrice}
                onChange={(e) => handleChange(e, true)}
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="weight">
                Weight
              </Label>
              <Input
                id="weight"
                name="weight"
                value={product.weight}
                onChange={(e) => handleChange(e, true)}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="height">
                Height (CM)
              </Label>
              <Input
                id="height"
                name="height"
                value={product.height}
                onChange={(e) => handleChange(e, true)}
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="length">
                Length (CM)
              </Label>
              <Input
                id="length"
                name="length"
                value={product.length}
                onChange={(e) => handleChange(e, true)}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="width">
                Width (CM)
              </Label>
              <Input
                id="width"
                name="width"
                value={product.width}
                onChange={(e) => handleChange(e, true)}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="airshippingcharges">
                Air Shipping Charges
              </Label>
              <Input
                id="airshippingcharges"
                name="airshippingcharges"
                value={calculateShippingCost(product.weight, "AIR")}
                disabled
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Label className="form-label" htmlFor="shipshippingcharges">
                Ship Shipping Charges
              </Label>
              <Input
                id="shipshippingcharges"
                name="shipshippingcharges"
                value={calculateShippingCost(product.weight, "SHIP")}
                disabled
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
      </form>
      <ProductImages
        handleAcceptedFiles={handleAcceptedFiles}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "30px auto",
        }}
      >
        <Button
          size="md"
          disabled={loading}
          onClick={handleSubmit}
          style={{
            backgroundColor: "#0077B6",
            color: "white",
          }}
        >
          {loading ? (
            <Spinner
              style={{ width: "2rem", height: "2rem" }}
              children={false}
            />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </>
  );
}

export default SoloProduct;
function Offsymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      No
    </div>
  );
}

function OnSymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      Yes
    </div>
  );
}
