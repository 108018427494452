import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  Form,
  ListGroup,
  Label,
  Input,
  ListGroupItem,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import toastr from "toastr";

const AdminLocationManagement = () => {
  const [countryValue, setCountryValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [country, setCountry] = useState([]);
  const [city, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  React.useEffect(() => {
    fetchInitial();
  }, []);
  const handleCountrySubmit = async (e) => {
    e.preventDefault();
    if (countryValue.trim() !== "") {
      try {
        await post("/api/add-country", {
          country: countryValue,
        });
        setCountryValue("");
        setSelectedCountry(null)
        fetchInitial();
        toastr.success("Added country");
      } catch (e) {
        console.log(e);
      }
    }
  };

  async function fetchInitial() {
    try {
      const countries = await post("/api/get-countries", {});
      setCountry(countries);
      if(selectedCountry){
        setSelectedCountry(countries.find(item=>item.name === selectedCountry.name))
      }
    } catch (e) {
      console.log(e);
    }
  }
  const handleCitySubmit = async (e) => {
    e.preventDefault();
    if (cityValue.trim() !== "") {
      try {
        await post("/api/add-city", {
          city: cityValue,
          countryId:selectedCountry.id
        });
        setCityValue("");
        fetchInitial();
        toastr.success("Added city");
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleCityRemove = async (id) => {
    try {
      await post("/api/remove-city", {
        cityId: id,
      });
      fetchInitial();
      toastr.success("removed city");
    } catch (e) {
      console.log(e);
    }
  };
  const handleCountryRemove = async (id) => {
    try {
      await post("/api/remove-country", {
        countryId: id,
      });
      fetchInitial();
      toastr.success("removed country");
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Location Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Location Management", link: "/admin-queries" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Container>
                    <h5 className="text-secondary">ADD COUNTRY</h5>
                    <p className="text-primary">
                      Note* click on country name to add/view cities
                    </p>
                    <Row>
                      <Col md={6}>
                        <form onSubmit={handleCountrySubmit}>
                          <Label>Enter Country:</Label>
                          <Input
                            type="text"
                            placeholder="Type something..."
                            value={countryValue}
                            onChange={(e) => setCountryValue(e.target.value)}
                          />
                          <Button variant="primary" type="submit">
                            Add Country
                          </Button>
                        </form>
                        <br />
                        <div
                          style={{
                            maxHeight: "150px",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <ListGroup>
                            {country.map((item, index) => (
                              <Row onClick={() => setSelectedCountry(item)}>
                                <Col>
                                  <ListGroupItem key={index}>
                                    {item?.name}
                                  </ListGroupItem>
                                </Col>
                                <Col>
                                  <Button
                                    variant="danger"
                                    className="float-right"
                                    onClick={(e) =>{
                                      e.stopPropagation(); 
                                      handleCountryRemove(item?.id)
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            ))}
                          </ListGroup>
                        </div>
                      </Col>
                    </Row>
                    {selectedCountry && (
                      <>
                        <h5 className="text-secondary mt-4">
                          ADD CITY FOR ({selectedCountry?.name?.toUpperCase()})
                        </h5>
                        <Row>
                          <Col md={6}>
                            <form onSubmit={handleCitySubmit}>
                              <Label>Enter City:</Label>
                              <Input
                                type="text"
                                placeholder="Type something..."
                                value={cityValue}
                                onChange={(e) => setCityValue(e.target.value)}
                              />
                              <Button variant="primary" type="submit">
                                Add City
                              </Button>
                              <Button
                                variant="primary"
                                style={{marginLeft:"10px"}}
                                onClick={() => {
                                  setSelectedCountry(null);
                                }}
                              >
                                Cancel
                              </Button>
                            </form>
                            <br />
                            <div
                              style={{
                                maxHeight: "150px",
                                overflowY: "auto",
                                overflowX: "hidden",
                              }}
                            >
                              <ListGroup>
                                {selectedCountry.cities.map((item, index) => (
                                  <Row>
                                    <Col>
                                      <ListGroupItem key={index}>
                                        {item?.name}
                                      </ListGroupItem>
                                    </Col>
                                    <Col>
                                      <Button
                                        variant="danger"
                                        className="float-right"
                                        onClick={() =>
                                          handleCityRemove(item?.id)
                                        }
                                      >
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                ))}
                              </ListGroup>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminLocationManagement;
