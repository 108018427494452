import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import toastr from "toastr";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Table,
  Label,
} from "reactstrap";


import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post } from "../../helpers/api_helper";

const AddNewTitle = () => {
  const navigation = useHistory();
  const [pageTitle, setPageTitle] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [sectionIndex, setSectionIndex] = useState();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  async function getProducts() {
    const products = await post("/api/content-management/add-products", {
      query: "",
    });
    setProducts(
      products.map((el) => ({ ...el, label: el.name, value: el.id }))
    );
  }

  async function savePage() {
    try {
      if (!pageTitle.trim()) {
        toastr.error("Please enter section title");
        return;
      }
      if (!selectedProducts.length) {
        toastr.error("Please select some products");
        return;
      }

      const formData = new FormData();
      formData.append("pageTitle", pageTitle);
      formData.append("sectionIndex", sectionIndex);
      formData.append(
        "objectDetails",
        JSON.stringify({ products: selectedProducts })
      );

      const config = {
        method: "POST",
        body: formData,
      };

      const responseObj = await fetch(
        `${process.env.REACT_APP_AUTHDOMAIN}/api/marketplace-sections`,
        config
      );
      const res = await responseObj.json();
      if (!res?.status) {
        toastr.error(res.message);
        return;
      }
      navigation.push("/content-management-website");
    } catch (e) {
      console.error(e);
    }
  }

  const toggleProductSelection = (product) => {
    if (selectedProducts.some((selected) => selected.id === product.id)) {
      setSelectedProducts(
        selectedProducts.filter((selected) => selected.id !== product.id)
      );
    } else {
      setSelectedProducts((prev) => [...prev, product]);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Add New Section"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Add New Section", link: "#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="mb-3">
                  <div className="mb-3 row">
                    <div className="col-md-6">
                      <Label className="form-label" htmlFor="pageTitleWeb">
                        Section Title
                      </Label>
                      <Input
                        value={pageTitle}
                        onChange={(e) => setPageTitle(e.target.value)}
                        type="text"
                        className="form-control"
                        placeholder="Enter section title"
                      />
                    </div>
                    <div className="col-md-6">
                      <Label className="form-label" htmlFor="sectionIndex">
                        Section Index
                      </Label>
                      <Input
                        value={sectionIndex}
                        onChange={(e) => setSectionIndex(e.target.value)}
                        type="number"
                        className="form-control"
                        placeholder="Enter section index"
                      />
                    </div>
                  </div>

                  <Label>Add Products to Page</Label>
                  <div className="product-list">
                    <Table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product Image</th>
                          <th>Name</th>
                          {/* <th>Brand Name</th> */}
                          <th>MRP</th>
                          <th>Height</th>
                          <th>Weight</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products?.map((product) => (
                          <tr>
                            <th scope="row">
                              <Input
                                type="checkbox"
                                checked={selectedProducts.some(
                                  (selected) => selected.id === product?.id
                                )}
                                onChange={() => toggleProductSelection(product)}
                              />
                            </th>
                            <td>
                              {" "}
                              <img
                                height={50}
                                width={70}
                                src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${product?.image_urls[0]}`}
                              />
                            </td>
                            <td>{product?.name ? `${product?.name}` : "-"}</td>
                            {/* <td>{product?.brand_name ? `${product?.brand_name}` : "-"}</td> */}
                            <td>{product?.MRP ? `${product?.MRP}` : "-"}</td>
                            <td>
                              {product?.height ? `${product?.height}` : "-"}
                            </td>
                            <td>
                              {product?.width ? `${product?.width}` : "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  {selectedProducts.length > 0 && (
                    <div style={{ marginTop: "20px" }}>
                      <h5>Selected Products:</h5>
                      {selectedProducts.map((item) => (
                        <Row
                          key={item.id}
                          style={{
                            marginTop: "10px",
                            border: "1px solid #E3E3E3",
                            color: "#5E6363",
                            padding: "5px",
                            marginLeft: "3px",
                          }}
                        >
                          <Col md={11} className="d-flex align-items-center">
                            <b>{item.name}</b>
                          </Col>
                          <Col
                            md={1}
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <Button
                              color="primary"
                              className="primary"
                              onClick={() => {
                                setSelectedProducts(
                                  selectedProducts.filter(
                                    (elem) => elem.id !== item.id
                                  )
                                );
                              }}
                            >
                              <i className="mdi mdi-delete font-size-14"></i>
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{ marginTop: "30px" }}
                      color="primary"
                      className="primary"
                      onClick={savePage}
                    >
                      Save
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddNewTitle;
