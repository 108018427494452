import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import CategoryActionDropdown from "../../components/CategoryActionDropDown";
import PackageEnquiryActionDropdown from "../../components/PackageEnquiry";

const EcommerceOrderColumns = (
  setDataObject,
  handleModalShow,
  getAllCategories,
  fetchFollowUpMessage
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row?.id}</>,
  },
  {
    dataField: "full_name",
    text: "Date",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.createdAt.split("T")[0]}
      </Link>
    ),
  },
  {
    dataField: "full_name",
    text: "Full Name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.full_name}
      </Link>
    ),
  },
  {
    dataField: "country_code",
    text: "Country Code",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.country_code}
      </Link>
    ),
  },
  {
    dataField: "mobile_number",
    text: "Mobile Number",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.mobile_number}
      </Link>
    ),
  },
  {
    dataField: "approx_package_weight",
    text: "Package Weight (KG)",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.approx_package_weight}
      </Link>
    ),
  },
  {
    dataField: "approx_package_length",
    text: "Length X Width X Height (Inch)",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.approx_package_length} X {row?.approx_package_width} X{" "}
        {row?.approx_package_height}
      </Link>
    ),
  },

  {
    dataField: "message",
    text: "Message",
    sort: true,
    formatter: (cellContent, row) => (
      <span className="text-body">
        {row.message?.trim()?.split("")?.slice(0, 9)?.join("") + "..."}
      </span>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row?.status === "RESOLVED" ? "success" : "warning")
        }
        color={row?.status === "RESOLVED" ? "success" : "warning"}
        pill
      >
        {row?.status}
      </Badge>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) =>
      <Button
        color="primary"
        className="primary"
        onClick={async () => {
          await fetchFollowUpMessage(row.id);
          setDataObject(row);
          handleModalShow();
        }}
      >
        Add Follow Up
      </Button>
  },
];

export default EcommerceOrderColumns;
