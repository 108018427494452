import axios from "axios";

//apply base url for axios
const API_URL = process.env.REACT_APP_AUTHDOMAIN;

function getAccessToken() {
  let token = localStorage.getItem("auth_token");

  const accessToken = `Bearer ${token}`;
  return accessToken;
}

// Function to create a new Axios instance with the current token value
const createAxiosInstance = () => {
  const token = getAccessToken();
  const axiosApi = axios.create({
    baseURL: API_URL,
  });

  axiosApi.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
  );

  return axiosApi;
};

const axiosApi = createAxiosInstance();

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, {
      ...config,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(
      url,
      { ...data },
      {
        ...config,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
    .then((response) => response.data);
}
export async function postFormData(url, data) {
  const config = {
    method: "POST",
    body: data,
  };

  try {
    const req = await fetch(url, config);
    if (req.ok) {
      const res = await req.json();
      if (res.success) {
        return await res.data;
      }
    }
  } catch (err) {
    return await err;
  }
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(
      url,
      { ...data },
      {
        ...config,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, {
      ...config,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
    .then((response) => response.data);
}
