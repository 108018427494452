import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { put } from "../../helpers/api_helper";
import toastr from "toastr";
import { useDispatch,useSelector } from "react-redux";
import { selectCategoryToUpdate } from "../../store/userAuthInfo/actions";
import { useHistory } from "react-router-dom";
import { setCommission } from "../../store/e-commerce/actions";
function EcomCommissionActionDropdown({
  isEcom,
  row,
  getAllVendorRequests,
  categories,
}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const dispatch = useDispatch();
    const navigation = useHistory();
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const commissions = useSelector((state) => state.commission);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
    updateVendorRequestStatus(item);
  };
  async function updateVendorRequestStatus(status) {
    try {
      const url = isEcom ? "ecom/" : "";
      await put(`/api/${url}category/${row.id}`, {
        isActive: status,
      });
      toastr.success("Status updated!", "Success");
      getAllVendorRequests();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
   row = row.id
  
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
      <DropdownToggle className="bg-primary" caret>
        <i className="mdi mdi-menu font-size-18"></i>
      </DropdownToggle>

      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            dispatch(selectCategoryToUpdate({ row, categories }));
            dispatch(setCommission({ row, categories })); 
            navigation.push("/update-commission");
          }}
        >
          <i className="mdi mdi-pencil font-size-18 me-2"></i>EDIT
        </DropdownItem>
      
      </DropdownMenu>
    </Dropdown>
  );
}

export default EcomCommissionActionDropdown;
