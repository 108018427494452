import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import toastr from "toastr";

import {
  Spinner,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
} from "reactstrap";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import MasterForm from "../../components/VendorRegistration/MasterForm";

const ShipmentCharges = () => {
  const navigation = useHistory();
  const [dataObject, setDataObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const dispatch = useDispatch();

  const handleModalShow = () => {
    setModalShow((prev) => !prev);
  };

  
  async function handleNewDataObjectCreation() {
    if(!dataObject?.shipping_charges_type){
      toastr.error("shipping type is required")
      return

    }
    if(!dataObject?.Order_charges_type){
      toastr.error("order type is required")
      return

    }
    try {
      setLoading(true);
      await post("/api/shipping-charges-create", dataObject);

      
      handleModalShow();
      getAllCategories();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toastr.error(e?.response?.data?.message || "something went wrong")
    }
  }
  const handleObjectChange = (event) => {
    const { name, value } = event.target;
     if (parseInt(value) < 0) {
       toastr.error("Negative values are not allowed.")
       return; 
     }
    setDataObject((prev) => ({
      ...prev,
      [name]: value ? parseInt(value) : 0,
    }));
  };
  function getTotalFieldValue() {
    const local_delivery_charge = dataObject?.local_delivery_charge
      ? dataObject?.local_delivery_charge
      : 0;
    const international_courier_charge =
      dataObject?.international_courier_charge
        ? dataObject?.international_courier_charge
        : 0;
    const human_resource_charge = dataObject?.human_resource_charge
      ? dataObject?.human_resource_charge
      : 0;
    const taxes = dataObject?.taxes ? dataObject?.taxes : 0;
    let grandTotal = local_delivery_charge + international_courier_charge;
    if (dataObject?.Order_charges_type === "ECOM") {
      grandTotal += human_resource_charge;
    }
    const taxAmount = (grandTotal * taxes) / 100;
    return taxAmount+grandTotal;
  }
  useEffect(() => {
    getAllCategories();
  }, []);
  async function getAllCategories() {
    try {
      const content = await get("/api/shipping-charges", {});
      setOrders(content);
    } catch (e) {
      console.log(e);
    }
  }
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Shipment Charges"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Shipment Charges", link: "/shipment-charges" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          setDataObject,
                          handleModalShow,
                          getAllCategories
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <a
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleModalShow();
                                      setDataObject({});
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Add New Shipment Charge
                                  </a>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ minHeight: "200px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal size="xl" isOpen={modalShow} toggle={handleModalShow}>
        <ModalHeader toggle={handleModalShow}>Shipment Charges</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="human_resource_charge">
                  For Order Type
                </Label>
                <DropDownComponent
                  options={["ECOM", "SHIPMENT","MARKETPLACE"]}
                  name={"For Order Type"}
                  updateName={"Order_charges_type"}
                  objectToBeUpdated={setDataObject}
                  defaultValue={dataObject?.Order_charges_type}
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="human_resource_charge">
                  Shipment Type
                </Label>
                <DropDownComponent
                  options={["AIR", "SHIP"]}
                  name={"Shipment Type"}
                  updateName={"shipping_charges_type"}
                  objectToBeUpdated={setDataObject}
                  defaultValue={dataObject?.shipping_charges_type}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_name">
                  International Charges
                </Label>
                <Input
                  id="international_courier_charge"
                  name="international_courier_charge"
                  type="number"
                  value={dataObject?.international_courier_charge}
                  onChange={handleObjectChange}
                  className="form-control"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_length">
                  Local Charges
                </Label>
                <Input
                  id="local_delivery_charge"
                  name="local_delivery_charge"
                  type="number"
                  value={dataObject?.local_delivery_charge}
                  onChange={handleObjectChange}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>

          <Row>
            {dataObject?.Order_charges_type === "ECOM" ? (
              <Col>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="human_resource_charge">
                    Human Resources Charges
                  </Label>
                  <Input
                    id="human_resource_charge"
                    name="human_resource_charge"
                    type="number"
                    value={dataObject?.human_resource_charge}
                    onChange={handleObjectChange}
                    className="form-control"
                  />
                </div>
              </Col>
            ) : null}

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="taxes">
                  Taxes (%)
                </Label>
                <Input
                  id="taxes"
                  name="taxes"
                  type="number"
                  value={dataObject?.taxes}
                  onChange={handleObjectChange}
                  className="form-control"
                />
              </div>
            </Col>
            {dataObject?.Order_charges_type !== "ECOM" ? <Col></Col> : null}
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="total">
                  Total
                </Label>
                <Input
                  id="total"
                  name="total"
                  type="number"
                  value={(getTotalFieldValue())?.toFixed(2)}
                  disabled="true"
                  className="form-control"
                />
              </div>
            </Col>
            <Col></Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                ) : (
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light"
                    type="submit"
                    onClick={handleNewDataObjectCreation}
                  >
                    Save
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export function DropDownComponent({
  options = [],
  name,
  updateName,
  objectToBeUpdated,
  defaultValue = "",
}) {
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  function toggleDropdown() {
    setDropDownOpen((prev) => !prev);
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#000">
      <DropdownToggle className="bg-primary" caret>
        {selectedValue || name}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((element, index) => {
          return (
            <DropdownItem
              key={index}
              onClick={() => {
                setSelectedValue(element);
                objectToBeUpdated((prev) => ({
                  ...prev,
                  [updateName]: element,
                }));
              }}
            >
              {element}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}
export default ShipmentCharges;
