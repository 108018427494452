import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import NotesImageModal from "../pages/WidthrawalAdmin/NotesImageModal";
function AdminWithdrawalAction({ row, getWithdrawalRequests, activeTab }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setSelectedAction(item);
    setDropdownOpen(false);
    setModalOpen(true);
  };
  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
        <DropdownToggle
          caret
          className="primary"
          style={{ backgroundColor: "#1fbcdd" }}
        >
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleItemClick("ACCEPTED")}>
            <i className="mdi mdi-clock font-size-18 me-2"></i>Mark Paid
          </DropdownItem>
          {/* {activeTab === "ACCEPTED" && ( */}
            <DropdownItem
              onClick={() =>
                handleItemClick(
                  row.status === "ACCEPTED" ? "PENDING" : "REJECTED"
                )
              }
            >
              <i className="mdi mdi-check font-size-18 me-2"></i>
              {row.status === "ACCEPTED" ? "Mark Pending" : "Mark Rejected"}
            </DropdownItem>
          {/* )} */}
        </DropdownMenu>
      </Dropdown>
      <NotesImageModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        action={selectedAction}
        requestId={row?.id}
        getWithdrawalRequests={getWithdrawalRequests}
      />
    </>
  );
}

export default AdminWithdrawalAction;
