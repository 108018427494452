import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import SubCategoryActionDropdown from "../../components/SubCategoryActionDropDown";

const EcommerceOrderColumns = (getAllVendorRequests, categories) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "name",
    text: "Category name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.name ? row?.name : "-"}
      </Link>
    ),
  },
  {
    dataField: "parent category",
    text: "Parent Category",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.parent_category?.name ? row?.parent_category?.name : "-"}
      </Link>
    ),
  },
  {
    dataField: "image",
    text: "Category Image",
    sort: true,
    formatter: (cellContent, row) => (
      <img
        height={30}
        src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${row?.image_url}`}
        alt="categoryimage"
      />
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <SubCategoryActionDropdown
        row={row}
        getAllVendorRequests={getAllVendorRequests}
        categories={categories}
      />
    ),
  },
];

export default EcommerceOrderColumns;
