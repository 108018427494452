import React from 'react'

const UnderProcessScreen = () => {
     const styles = {
       container: {
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         height: "100vh",
         textAlign: "center",
         fontSize: "24px",
       },
     };
  return (
    <>
    <div style={styles.container}>In Process</div>
    </>
  )
}

export default UnderProcessScreen