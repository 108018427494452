import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { removeUserInfo } from '../../store/userAuthInfo/actions';

const Logout = () => {
    const navigation = useHistory()
    const dispatch = useDispatch()
  useEffect(() => {
    localStorage.clear()
    dispatch(removeUserInfo())
    navigation.push("/login")
  }, []);

  return <h1>&nbsp;</h1>;
};

export default Logout;
