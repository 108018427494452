import React from "react";
import styles from "./common.modules.css";
import { del, get, post } from "../helpers/api_helper";
import toastr from "toastr";

function DisplayAttributesWithRespectiveValues({
  attributesArr,
  setProduct,
  product,
  handleModalActive
}) {
  return (
    <>
      {attributesArr.map((element, count) => {
        return (
          <div style={{ display: "flex",justifyContent:"space-between", width: "70%",margin:"10px 0" }}>
            <div style={{ width: "10%", textAlign: "left" }}>
              
              {element.name}
            </div>
            <div style={{ display: "flex",justifyContent:"flex-start",alignItems:"center",width:"50%" }}>
              {element?.values?.map((item, index) => (
                <span
                  key={index}
                  onClick={async () => {
                    const arrayOfItems = element.values;
                    arrayOfItems.splice(index, 1);
                    const joinedItems = arrayOfItems.join("|");
                    await post("/api/product/create-attribute", {
                      ...element,
                      values: JSON.stringify(joinedItems),
                    });
                    const attributes = await get(
                      `/api/product/attribute/${product.id}`
                    );
                    setProduct((prev) => ({
                      ...prev,
                      attributes: attributes,
                    }));
                  }}
                  style={{
                    background: "#00b4d8",
                    color: "white",
                    textAlign: "center",
                    margin: "0 5px",
                    minWidth: "40px",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {item}
                </span>
              ))}
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <span
                onClick={async () => {
                  try{

                      setProduct((prev) => ({
                        ...prev,
                        tempAttributeName: element?.name,
                        tempAttributeValueName: element?.values?.join("|"),
                        tempAttributeId:element.id
                      }));
                      handleModalActive()
                    }catch(e){
                      console.log(e)
                      toastr.error(e?.response?.data?.message);
                    }
                  }}
              >
                <i className="mdi mdi-pencil font-size-18 "></i>
              </span>
              <span
                onClick={async () => {
                  try{

                    const res = await del(`/api/product/attribute/${element.id}`);
                    if (!res.status) {
                      toastr.error(res.message);
                      return;
                    }
                    const attributes = await get(
                      `/api/product/attribute/${product.id}`
                      );
                      setProduct((prev) => ({
                        ...prev,
                        attributes: attributes,
                      }));
                    }catch(e){
                      console.log(e)
                      toastr.error(e?.response?.data?.message);
                    }
                  }}
              >
                <i className="mdi mdi-delete font-size-18 "></i>
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default DisplayAttributesWithRespectiveValues;
