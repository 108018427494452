import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { del } from "../helpers/api_helper";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { selectCategoryToUpdate } from "../store/userAuthInfo/actions";
import { useHistory } from "react-router-dom";
function PackageSizesActionDropdown({
  row,
  setDataObject,
  handleModalShow,
  getAllCategories,
  deleteType,
  isDuration,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const navigation = useHistory();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
    updateVendorRequestStatus(item);
  };
  async function updateVendorRequestStatus(status) {
    try {
      await del(`/api/package-size/${row?.id}`, {});
      toastr.success("Status updated!", "Success");
      getAllCategories();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#000">
      <DropdownToggle className="bg-primary" caret>
        <i className="mdi mdi-menu font-size-18"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            setDataObject(row);
            handleModalShow();
          }}
        >
          <i className="mdi mdi-pencil font-size-18 me-2"></i>EDIT
        </DropdownItem>
        {!isDuration ? (
          <DropdownItem
            onClick={() => {
              if (deleteType) {
                deleteType(row?.id);
                return;
              }
              handleItemClick("DELETE");
            }}
          >
            <i className="mdi mdi-delete font-size-18 me-2"></i>DELETE
          </DropdownItem>
        ) : null}
      </DropdownMenu>
    </Dropdown>
  );
}

export default PackageSizesActionDropdown;
