import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { del, post } from "../helpers/api_helper";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { selectCategoryToUpdate } from "../store/userAuthInfo/actions";
import { useHistory } from "react-router-dom";
function PackageEnquiryActionDropdown({ row, getAllCategories }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const navigation = useHistory();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  async function updateVendorRequestStatus(bool, data) {
    try {
      if (bool) {
        await post(`/api/create-package-enquiry`, data);
      } else {
        await del(`/api/package-enquiry/${row.id}`, {});
      }
      toastr.success("Status updated!", "Success");
      getAllCategories();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#000">
      <DropdownToggle className="bg-primary" caret>
        CHANGE STATUS
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            const data = { ...row, status: "WORK_IN_PROGRESS" };
            updateVendorRequestStatus(true, data);
          }}
        >
          <i className="mdi mdi-account-edit font-size-18 me-2"></i>WORK IN
          PROGRESS
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            const data = { ...row, status: "RESOLVED" };
            updateVendorRequestStatus(true, data);
          }}
        >
          <i className="mdi mdi-pencil font-size-18 me-2"></i>RESOLVED
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            const data = { ...row, status: "PENDING" };
            updateVendorRequestStatus(true, data);
          }}
        >
          <i className="mdi mdi-clock font-size-18 me-2"></i>PENDING
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            const data = { ...row, status: "CLOSED" };
            updateVendorRequestStatus(true, data);
          }}
        >
          <i className="mdi mdi-close font-size-18 me-2"></i>CLOSED
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default PackageEnquiryActionDropdown;
