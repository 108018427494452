import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import CategoryActionDropdown from "../../components/CategoryActionDropDown";
import PackageSizesActionDropdown from "../../components/PackageSizesActionDropdown";
import ShippingCalculatorDropdown from "../../components/ShippingCalculatorDropdown";

const EcommerceOrderColumns = (
  setDataObject,
  handleModalShow,
  getAllCategories,
  deleteType,
  isDuration
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "package_weight",
    text: "Package Weight",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.package_weight ? `${row.package_weight} KG` : "-"}
      </Link>
    ),
  },
  {
    dataField: "air_duration",
    text: "Air Duration",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.air_duration ? `${row.air_duration} ${" "}DAYS` : "-"}
      </Link>
    ),
  },

  {
    dataField: "air_amount",
    text: "Air Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.air_amount ? `$${row.air_amount}` : "-"}
      </Link>
    ),
  },
  {
    dataField: "ship_duration",
    text: "Ship Duration",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.ship_duration ? `${row.ship_duration} ${" "}DAYS` : "-"}
      </Link>
    ),
  },

  {
    dataField: "ship_amount",
    text: "Ship Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.ship_amount ? `$${row.ship_amount}` : "-"}
      </Link>
    ),
  },
  {
    dataField: "costPerKGAir",
    text: "Per KG Air Cost",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.costPerKGAir ? `$${parseFloat(row.costPerKGAir).toFixed(2)}` : "-"}
      </Link>
    ),
  },
  {
    dataField: "costPerKGShip",
    text: "Per KG Ship Cost",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.costPerKGShip ? `$${parseFloat(row.costPerKGShip).toFixed(2)}` : "-"}
      </Link>
    ),
  },

  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <ShippingCalculatorDropdown
        row={row}
        setDataObject={setDataObject}
        handleModalShow={handleModalShow}
        getAllCategories={getAllCategories}
        deleteType={deleteType}
        isDuration={isDuration}
      />
    ),
  },
];

export default EcommerceOrderColumns;
