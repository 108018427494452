import React, { useState, useEffect } from "react";
import { Row, Col, Button, Alert, Container, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logodark from "../../assets/images/logo-dark.png";
import RegisterLogo from "../../assets/images/authentication-bg.png"
import {
  apiError,
  registerUser,
  registerUserFailed,
} from "../../store/actions";
import { post } from "../../helpers/api_helper";
import MasterForm from '../../components/VendorRegistration/MasterForm'

const Register = () => {
  
  const loading = useSelector((state) => state.Account.loading);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

 

  useEffect(() => {
    document.body.classList.add("auth-body-bg");

    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, []);

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={8}>
            <MasterForm />
            </Col>
            <Col lg={4}>
              <div >
                <div  style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh',width:'100%'}}>
                  <img src={RegisterLogo} width={'100%'} style={{margin:'auto'}}/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
