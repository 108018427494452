import React, { useEffect, useState } from "react";
import {
  Spinner,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Label,
  Button,
  Tooltip,
} from "reactstrap";
import { get, post } from "../../helpers/api_helper";
import toastr from "toastr";

const InsuranceCharges = () => {
  const [dataObject, setDataObject] = useState({});
  const [loading, setLoading] = useState(false);

  // Tooltip state management
  const [tooltipOpen, setTooltipOpen] = useState({
    percentage: false,
    gst: false,
    upto: false,
  });

  const toggleTooltip = (tooltip) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [tooltip]: !prevState[tooltip],
    }));
  };

  async function handleNewDataObjectCreation() {
    try {
      setLoading(true);
      await post("/api/create-insurance-charge", dataObject);

      getAllCategories();
      setLoading(false);
      toastr.success("Values updated successfully!");
    } catch (e) {
      setLoading(false);
      toastr.error(e?.response?.data?.message || "Something went wrong");
    }
  }

  const handleObjectChange = (event) => {
    const { name, value } = event.target;
    setDataObject((prev) => ({
      ...prev,
      [name]: value ? parseFloat(value) : 0,
    }));
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  async function getAllCategories() {
    try {
      const content = await get("/api/get-insurance-charge", {});
      setDataObject(content);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="percentage_amount"
                        >
                          Percent Amount
                          <i
                            className="mdi mdi-information-outline font-size-18"
                            id="percentageTooltip"
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            onClick={() => toggleTooltip("percentage")}
                          ></i>
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen.percentage}
                            target="percentageTooltip"
                            toggle={() => toggleTooltip("percentage")}
                          >
                            Calculate the percentage amount based on the total
                            value.
                          </Tooltip>
                        </Label>
                        <Input
                          id="percentage_amount"
                          name="percentage_amount"
                          type="number"
                          value={dataObject?.percentage_amount}
                          onChange={handleObjectChange}
                          className="form-control"
                          step=".01"
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="gst_percentage">
                          GST Percent
                          <i
                            className="mdi mdi-information-outline font-size-18"
                            id="gstTooltip"
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            onClick={() => toggleTooltip("gst")}
                          ></i>
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen.gst}
                            target="gstTooltip"
                            toggle={() => toggleTooltip("gst")}
                          >
                            Apply GST at the specified percentage rate.
                          </Tooltip>
                        </Label>
                        <Input
                          id="gst_percentage"
                          name="gst_percentage"
                          type="number"
                          value={dataObject?.gst_percentage}
                          onChange={handleObjectChange}
                          className="form-control"
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="upto_value">
                          Upto Value
                          <i
                            className="mdi mdi-information-outline font-size-18"
                            id="uptoTooltip"
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            onClick={() => toggleTooltip("upto")}
                          ></i>
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen.upto}
                            target="uptoTooltip"
                            toggle={() => toggleTooltip("upto")}
                          >
                            Cap the total amount to the maximum specified value.
                          </Tooltip>
                        </Label>
                        <Input
                          id="upto_value"
                          name="upto_value"
                          type="number"
                          value={dataObject?.upto_value}
                          onChange={handleObjectChange}
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {loading ? (
                          <Spinner style={{ width: "2rem", height: "2rem" }} />
                        ) : (
                          <Button
                            color="primary"
                            className="w-md waves-effect waves-light"
                            onClick={handleNewDataObjectCreation}
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InsuranceCharges;
