import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import CategoryActionDropdown from "../../components/CategoryActionDropDown";

const EcommerceOrderColumns = (getAllVendorRequests, categories) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "name",
    text: "Category name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.name}
      </Link>
    ),
  },
  {
    dataField: "name",
    text: "Category Image",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        <img
          height={20}
          src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${row.image_url}`}
        />
       </Link>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <CategoryActionDropdown
        row={row}
        getAllVendorRequests={getAllVendorRequests}
        categories={categories}
      />
    ),
  },
];

export default EcommerceOrderColumns;
