import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logodark from "../../assets/images/Shipora.png";
import logolight from "../../assets/images/Shipora.png";
import { post } from "../../helpers/api_helper";
import { addUserInfo } from "../../store/userAuthInfo/actions";

const Login = () => {
  const navigation = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loginSuccess, setLoginSuccess] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (event, values) => {
    try {
      const responseObj = await post("/api/auth/vendor-signin", {
        email: username,
        password,
      });
      await localStorage.setItem("auth_token", responseObj.accessToken);
      await localStorage.setItem("auth_info", JSON.stringify(responseObj));
      window.setTimeout(() => {
        dispatch(addUserInfo(responseObj));
        setLoginSuccess(responseObj.message);
        setLoginError(null);
        
        if (responseObj.isAdmin) {
          navigation.push("/dashboard");
        } else {
          navigation.push("/vendor-dashboard");
        }
      }, 2500);
    } catch (e) {
      setLoginSuccess(null);
      setLoginError(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");

    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, []);

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              lg={4}
              className="scrollable-content py-5"
              style={{ overflowY: "auto" }}
            >
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="">
                              <img
                                src={logodark}
                                alt=""
                                height="40"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src={logolight}
                                alt=""
                                height="40"
                                className="auth-logo logo-light mx-auto"
                              />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                          <p className="text-muted">
                            Sign in to continue to Shipora.
                          </p>
                        </div>

                        {loginError && loginError ? (
                          <Alert color="danger">{loginError}</Alert>
                        ) : null}
                        {loginSuccess && loginSuccess ? (
                          <Alert color="success">{loginSuccess}</Alert>
                        ) : null}

                        <div className="p-2 mt-5">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleSubmit}
                          >
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="username">Email</Label>
                              <AvField
                                name="username"
                                value={username}
                                type="text"
                                className="form-control"
                                id="username"
                                validate={{ email: true, required: true }}
                                placeholder="Enter username"
                                onChange={(e) => setUsername(e.target.value)}
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="userpassword">Password</Label>
                              <AvField
                                name="password"
                                value={password}
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>

                            <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </Label>
                            </div>

                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </Button>
                            </div>

                            {/* <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1"></i> Forgot
                                your password?
                              </Link>
                            </div> */}
                          </AvForm>
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            Don't have an account ?{" "}
                            <Link
                              onClick={() => {
                                window.location =
                                  "https://vendor-registration.shipora.io/vendor-registration";
                              }}
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Register{" "}
                            </Link>
                          </p>
                          <p>
                            © {new Date().getFullYear()} Crafted with{" "}
                            <i className="mdi mdi-heart text-danger"></i> by
                            Shipora.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
