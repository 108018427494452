import React from "react";
import { Link } from "react-router-dom";
import { Badge} from "reactstrap";

const EcommerceOrderColumnDefinitions = (
) => [
  {
    text: "ID",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "user.date",
    text: "Date",
    sort: true,
    formatter: (cellContent, row) =>
        <Link to="#" className="text-body fw-bold">
          {row?.createdAt.split("T")[0] || ""}
        </Link>
  },
  {
    dataField: "user.email",
    text: "Mobile Number",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.vendor.mobilenumber}
      </Link>
    ),
  },
  {
    dataField: "user.email",
    text: "UserName",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.vendor?.username || ""}
      </Link>
    ),
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.vendor.email ? row.vendor.email : "-"}
      </Link>
    ),
  },
  {
    dataField: "bussnessname",
    text: "Bussness Name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.vendor.businessName ? row.vendor.businessName : "-"}
      </Link>
    ),
  },
  {
    dataField: "gst",
    text: "Gst",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.vendor.gstin ? row.vendor.gstin : "-"}
      </Link>
    ),
  },
  {
    dataField: "balance",
    text: "Available Balance",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.vendor.balance ? `$${parseFloat(row?.vendor.balance).toFixed(2)}` : 0}
      </Link>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.status === 'ACTIVE' ? "success" : "danger")
        }
        color={row.isActive === true ? "success" : "danger"}
        pill
      >
        {row?.status ? "ACTIVE" : "HIDDEN"}
      </Badge>
    ),
  },
];

export default EcommerceOrderColumnDefinitions;
