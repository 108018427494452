import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { del, post, put } from "../helpers/api_helper";
import toastr from "toastr";
function EmailTemplateActionDropdown({
  getEmailTemplates,
  row,
  toggleModal,
  setEmailTemplate,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  async function deleteEmailTemplate() {
    try {
      await post("/api/email-template-delete", { id: row.id });
      toastr.success("Success!");
      getEmailTemplates();
    } catch (e) {
      toastr.error(e?.response?.data?.message, "Error");
      console.log(e);
    }
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
      <DropdownToggle color="primary" className="primary" caret>
        Actions
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            setDropdownOpen(false);
            setEmailTemplate(row);
            toggleModal();
          }}
        >
          <i className="mdi mdi-pencil font-size-18 me-2"></i>EDIT
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setDropdownOpen(false);
            deleteEmailTemplate();
          }}
        >
          <i className="mdi mdi-delete font-size-18 me-2"></i>DELETE
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default EmailTemplateActionDropdown;
