import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Alert,
} from "reactstrap";
import toastr from "toastr";
import axios from "axios";
import styles from "./masterform.module.css";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./step4";

import MultiStepProgressBar from "./MultiStepProgressBar";
import { get, post, postFormData } from "../../helpers/api_helper";
import { useHistory, Link } from "react-router-dom";

const MasterForm = ({
  isAdmin,
  handleAddVendorModal,
  getAllVendorRequests,
}) => {
  const navigation = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [vendorRegistraionDetails, setVendorRegistrationDetails] = useState({});
  const [isChecked, setIsChecked] = React.useState(false);
  const [panFile, setPanFile] = useState(null);
  const [aadharFile, setAadharFile] = useState(null);
  const [gstCertificate, setGstCertificate] = useState(null);
  const [cancelledCheque, setCancelledCheque] = useState(null);
  const [shiporaAgreement, setShiporaAgreement] = useState(false);
  const [shiporaPolicies, setShiporaPolicies] = useState(false);
  const [registrationError, setRegistraionError] = useState("");
  const [registrationSuccess, setRegistraionSuccess] = useState("");
  const [hideDetails, setHideDetails] = useState(false);
  const [states,setStates] = useState([])

  const isImageType = (file) => {
    const imageTypes = ["image/jpeg", "image/png"]; 
    return imageTypes.includes(file.type);
  };

  async function getAllStates(){
    try{
      const info = await get("/api/all-states")
      setStates(info||[])
    }catch(e){
      console.log(e)
    }
  }
const handlePanFileChange = (event) => {
  const selectedFile = event.target.files[0];
  const maxSize = 100 * 1024; 

  const isImageType = (file) => {
    const allowedTypes = ["image/jpeg", "image/png"];
    return allowedTypes.includes(file.type);
  };

  if (selectedFile) {
    if (!isImageType(selectedFile)) {
      alert("Please select a valid image file (JPEG, PNG)");
      event.target.value = null;
    } else if (selectedFile.size > maxSize) {
      alert("File size must be less than 100 KB");
      event.target.value = null;
    } else {
      setPanFile(selectedFile); 
    }
  }
};

  const handleAadharFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const maxSize = 100 * 1024; 

    const isImageType = (file) => {
      const allowedTypes = ["image/jpeg", "image/png"];
      return allowedTypes.includes(file.type);
    };

    if (selectedFile) {
      if (selectedFile.size > maxSize) {
        alert("File size must be less than 100 KB");
        event.target.value = null;
      } else if (!isImageType(selectedFile)) {
        alert("Please select a valid image file (JPEG, PNG)");
        event.target.value = null;
      } else {
        setAadharFile(selectedFile); 
      }
    }
  };


  const handleChequeFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const maxFileSize = 100 * 1024; 

    const isImageType = (file) => {
      const allowedTypes = ["image/jpeg", "image/png"];
      return allowedTypes.includes(file.type);
    };

    if (selectedFile) {
      if (!isImageType(selectedFile)) {
        alert("Please select a valid image file (JPEG, PNG)");
        event.target.value = null; 
      } else if (selectedFile.size > maxFileSize) {
        alert("File size should be less than 100 KB");
        event.target.value = null; 
      } else {
        setCancelledCheque(selectedFile); 
      }
    }
  };

const handleGSTFileChange = (event) => {
  const selectedFile = event.target.files[0];
  const maxFileSize = 100 * 1024; 

  const isImageType = (file) => {
    const allowedTypes = ["image/jpeg", "image/png"];
    return allowedTypes.includes(file.type);
  };

  if (selectedFile) {
    if (!isImageType(selectedFile)) {
      alert("Please select a valid image file (JPEG, PNG)");
      event.target.value = null; 
    } else if (selectedFile.size > maxFileSize) {
      alert("File size should be less than 100 KB");
      event.target.value = null; 
    } else {
      setGstCertificate(selectedFile); 
    }
  }
};

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setVendorRegistrationDetails((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(()=>{
    getAllStates()
  },[])
  const validateGSTNumber = (gst) => {
    const gstPattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1}$/;
    if (!gstPattern.test(gst)) {
      toastr.error("Enter valid GST format");
      return false;
    }
    return true;
  };
  const validateEmail = (email) => {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (!emailPattern.test(email)) {
      toastr.error("Enter a valid email address");
      return false;
    }
    return true;
  };
  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-=/\\]).+$/;
    if (!passwordPattern.test(password)) {
      toastr.error(
        "Password must contain at least one uppercase letter, one digit, and one special character"
      );
      return false;
    }
    return true;
  };

  const validateVendorRegistration = async(
    vendorRegistrationDetails,
    fieldToErrorName
  ) => {
    const requiredFields = Object.keys(fieldToErrorName);
    const missingFields = requiredFields.filter(
      (field) => !vendorRegistrationDetails[field]
    );
    
    if (missingFields.length > 0) {
      const firstMissingField = missingFields[0];
      const errorMessage = fieldToErrorName[firstMissingField];
      // toastr.error(`${errorMessage} is required`);
      return false;
    }
    return true;
  };
    const checkEmailExists = async (username, email) => {
      try {
        let formData = {username, email}
        const response = await post(`${process.env.REACT_APP_AUTHDOMAIN}/api/check-email`,formData)
        if (response.exists == true){
          toastr.error(response.message)
          
        }
          return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    };

  const handleSubmit = async (event, values) => {
    try {
      const formData = new FormData();
      formData.append("panFile", panFile);
      formData.append("aadharFile", aadharFile);
      formData.append("gstCertificate", gstCertificate);
      formData.append("cancelledCheque", cancelledCheque);


      for (const key in vendorRegistraionDetails) {
        if (Object.hasOwnProperty.call(vendorRegistraionDetails, key)) {
          const value = vendorRegistraionDetails[key];
          formData.append(
            key,
            value
          );
        }
      }

      const config = {
        method: "POST",
        body: formData,
      };
      let responseObj = await fetch(
        `${process.env.REACT_APP_AUTHDOMAIN}/api/auth/vendor-signup/admin`,
        config
      );
      const res = await responseObj.json();
      if(!res?.status){
        toastr.error(res?.message || "Something went wrong")
        return 
      }
      if (isAdmin) {
        getAllVendorRequests();
        handleAddVendorModal();
      }
      setRegistraionSuccess(res.message);
      setRegistraionError(null);
      setHideDetails(true);
    } catch (e) {
      toastr.error(e?.response?.data?.message || "something went wrong");

    }
  };
  const handleNext = async() => {
    if (currentStep === 1) {
      const checkform = validateVendorRegistration(vendorRegistraionDetails, {
        account_type: "Account Type",
        businessname: "Business Name",
        businessaddress: "Business Address",
        state_id: "State",
        city: "City",
        zip: "ZIP Code",
        mobilenumber: "Mobile Number",
        gst: "GST Number",
        email: "Email",
        username: "Username",
        password: "Password",
      }); 
      
      if (!checkform) {
        return;
      }
      
      const {
        username,
        email,
        password,
        state_id,
        gst,
        businessname,
        businessaddress,
        city,
        zip,
        account_type,
        mobilenumber,
      } = vendorRegistraionDetails;
      
      if(!account_type){
        toastr.error("Account type is required")
        return
      }
      if(!businessname){
        toastr.error("Bussness name is required")
        return
      }
      if(!businessaddress){
        toastr.error("Business address is required")
        return
      }
      if(businessaddress.length < 30){
        toastr.error("Business address length must be 30 characters")
        return
      }
      if(!state_id){
        toastr.error("state is required")
        return
      }
      if(!city){
        toastr.error("City is required")
        return
      }
      if(!zip){
        toastr.error("zipcode is required")
        return
      }
      
      if (zip && !/^\d{6}$/.test(zip)) {
        toastr.error("ZIP Code must be exactly 6 digits");
        return;
      }
      if(!mobilenumber){
        toastr.error("Mobile number is required")
        return
      }
      
      if(mobilenumber.length !== 10){
        toastr.error("Mobile number is exact 10 digit")
        return
      }
         if (mobilenumber && !/^\d{10}$/.test(mobilenumber)) {
           toastr.error("Mobile Number must be exactly 10 digits");
           return false;
         }
      if(!gst){
        toastr.error("Gst is required")
        return
      }
       if (gst && !validateGSTNumber(gst)) {
         return false;
       }
      if(!email){
        toastr.error("email is required")
        return
      }
         if (email && !validateEmail(email)) {
           return false;
         }
      if(!username){
        toastr.error("Username is required")
        return
      }
      const emailCheck = await checkEmailExists(username, email);
      if (emailCheck) return; 
      if(!password){
        toastr.error("password is required")
        return
      }
      if (password && !validatePassword(password)) {
        return false;
      }

 
      
    }
    if (currentStep === 2) {
      const checkform = validateVendorRegistration(vendorRegistraionDetails, {
        bankname: "Bank Name",
        accountholdername: "Account Holder Name",
        accountnumber: "Account Number",
        ifsc: "IFSC Code",
      });
      if (!checkform) {
        return;
      }
     const { bankname, accountholdername, accountnumber, ifsc } = vendorRegistraionDetails;
     if (!bankname) {
       toastr.error("Bank name is required");
       return false;
     }
     if (!accountholdername) {
       toastr.error("Account holder name is required");
       return false;
     }
     if (!accountnumber) {
       toastr.error("Account number is required");
       return false;
     }
     if (!/^\d{9,18}$/.test(accountnumber)) {
       toastr.error("Account number must be between 9 and 18 digits");
       return false;
     }
     if (!ifsc) {
       toastr.error("IFSC code is required");
       return false;
     }
     if (!/^[A-Z]{4}\d{7}$/.test(ifsc)) {
       toastr.error("Invalid Ifsc code");
       return false;
     }

    }
    
    if (currentStep === 3) {
      let checkform = validateVendorRegistration(vendorRegistraionDetails, {
        isProductApprovalRequired: "Approval type",
      });

      if (!checkform) {
        return;
      }
      if (!panFile) {
        toastr.error("please upload your pan file")
        return;
      }
      if (!aadharFile) {
        toastr.error("please upload your aadharFile file");
        return;
      }
      if (!isAdmin) {
        if (!shiporaAgreement) {
          alert("Please agree our Agreement to continue");
          return;
        }
        if (!shiporaPolicies) {
          alert("Please agree our Policies to continue");
          return;
        }
      }
      handleSubmit();
    }
    setCurrentStep((prevStep) => (prevStep >= 3 ? 3 : prevStep + 1));
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => (prevStep <= 1 ? 1 : prevStep - 1));
  };

  const submitNextBtnStyles = {
    backgroundColor: "#0077B6",
    color: "white",
  };
  const previousButton = currentStep !== 1 && (
    <Button styel={{ backgroundColor: "#0077B6", color: "white" }} onClick={handlePrevious} >
      Previous
    </Button>
  );

  const nextButton = currentStep < 4 && (
    <Button size="lg" style={submitNextBtnStyles} onClick={handleNext}>
      Next
    </Button>
  );

  const submitButton = currentStep > 3 && (
    <Button style={submitNextBtnStyles} type="submit">
      Submit
    </Button>
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card>
          {!hideDetails && (
            <>
              <CardBody>
                <CardTitle>
                  <MultiStepProgressBar currentStep={currentStep} />
                </CardTitle>
                <CardText />
                <Step1
                  currentStep={currentStep}
                  handleChange={handleChange}
                  vendorDetails={vendorRegistraionDetails}
                  states ={states}
                />
                <Step2
                  currentStep={currentStep}
                  handleChange={handleChange}
                  vendorDetails={vendorRegistraionDetails}
                />
                <Step4
                  currentStep={currentStep}
                  handleChange={handleChange}
                  vendorDetails={vendorRegistraionDetails}
                  handlePanFileChange={handlePanFileChange}
                  handleAadharFileChange={handleAadharFileChange}
                  handleGSTFileChange={handleGSTFileChange}
                  handleChequeFileChange={handleChequeFileChange}
                  setShiporaPolicies={setShiporaPolicies}
                  setShiporaAgreement={setShiporaAgreement}
                  shiporaAgreement={shiporaAgreement}
                  shiporaPolicies={shiporaPolicies}
                  isAdmin={isAdmin}
                />
              </CardBody>
            </>
          )}
          {registrationError ? (
            <Alert color="danger">{registrationError}</Alert>
          ) : null}
          {registrationSuccess ? (
            <Alert color="success">{registrationSuccess}</Alert>
          ) : null}
          {!hideDetails && (
            <div className={styles.btnWrapper}>
              {previousButton}
              {nextButton}
              {submitButton}
            </div>
          )}
          {hideDetails ? (
            isAdmin ? null : (
              <div className={styles.btnWrapper}>
                <Button
                  style={submitNextBtnStyles}
                  onClick={() => {
                    navigation.push("/login");
                  }}
                >
                  Go to login
                </Button>
              </div>
            )
          ) : null}
          {!hideDetails ? (
            isAdmin ? null : (
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Login{" "}
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()} Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Shipora.
                </p>
              </div>
            )
          ) : null}
        </Card>
      </Form>
    </>
  );
};

export default MasterForm;
