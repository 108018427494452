import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import CategoryActionDropdown from "../../components/CategoryActionDropDown";
import PackageSizesActionDropdown from "../../components/PackageSizesActionDropdown";
import { selectedTicketToViewChat } from "../../store/userAuthInfo/actions";

const EcommerceOrderColumns = (dispatch, navigation, user) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "tiitle",
    text: "Title",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.title}
      </Link>
    ),
  },
  {
    dataField: "subject",
    text: "Subject",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.subject}
      </Link>
    ),
  },

  {
    dataField: "ticket_priority",
    text: "Ticket Priority",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row?.ticket_priority === "HIGH"
            ? "danger"
            : row?.ticket_priority === "MEDIUM"
            ? "warning"
            : "success")
        }
        color={
          row?.ticket_priority === "HIGH"
            ? "danger"
            : row?.ticket_priority === "MEDIUM"
            ? "warning"
            : "success"
        }
        pill
      >
        {row?.ticket_priority}
      </Badge>
    ),
  },
  {
    dataField: "ticket_status",
    text: "Ticket Status",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row?.ticket_status === "OPEN" ? "success" : "danger")
        }
        color={row?.ticket_status === "OPEN" ? "success" : "danger"}
        pill
      >
        {row?.ticket_status}
      </Badge>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <Button
        onClick={() => {
          dispatch(selectedTicketToViewChat(row));
          navigation.push("/support-ticket");
        }}
        style={{ backgroundColor: "#00b4d8" }}
      >
        view
      </Button>
    ),
  },
];

export default EcommerceOrderColumns;
