import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { post } from "../helpers/api_helper";
import toastr from "toastr";
import { useSelector } from "react-redux";

function MultipleOrderUpdate({ selectedOrders, fetchDataAgain }) {
  const activeTab = useSelector((state) => state.userAuthInfo.activeTab);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleItemClick = (item) => {
    updateVendorRequestStatus(item);
  };

  async function updateVendorRequestStatus(status) {
    try {
      await post(`/api/admin/update-all-orders-status`, {
        status: status,
        orders: selectedOrders,
        isShipment : false
      });
      toastr.success("Status updated!", "Success");
      fetchDataAgain();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }

  const statusIcons = {
    PLACED: "mdi-check",
    RECEIVED: "mdi-package",
    SHIPPED: "mdi-truck",
    DELIVERED: "mdi-check-all",
    HOLD: "mdi-pause",
    REACHED_TO_LOCAL_PARTNER: "mdi-city",
    CANCELLED: "mdi-cancel",
  };

  const statuses = [
    "PLACED",
    "RECEIVED",
    "SHIPPED",
    "REACHED_TO_LOCAL_PARTNER",
    "DELIVERED",
  ];

  const filteredStatuses = statuses.filter(
    (status) => statuses.indexOf(status) > statuses.indexOf(activeTab)
  );

  const statusDisplayLabels = {
    PLACED: "Placed",
    RECEIVED: "Received",
    SHIPPED: "Shipped",
    REACHED_TO_LOCAL_PARTNER: "REACHED TO LOCAL PARTNER",
    DELIVERED: "Delivered",
  };

  return (
    <>
      {filteredStatuses.length > 0 && (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
          <DropdownToggle color="primary" className="primary" caret={false}>
            Update Bulk Order Status
          </DropdownToggle>
          <DropdownMenu>
            {filteredStatuses.map((status) => (
              <DropdownItem
                key={status}
                onClick={() => handleItemClick(status)}
              >
                <i
                  className={`mdi ${statusIcons[status]} font-size-18 me-2`}
                ></i>
                {statusDisplayLabels[status]}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
}

export default MultipleOrderUpdate;
