import React, { Component } from "react";

import { connect } from "react-redux";
import {
    Row,
    Col,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";

import { Link } from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import { withNamespaces } from "react-i18next";

//Import Megamenu
import MegaMenu from "./MegaMenu";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//Import logo Images
import logosmdark from "../../assets/images/logo-sm-dark.png";
import logodark from "../../assets/images/logo-dark.png";
import logosmlight from "../../assets/images/Shipora.png";
import logolight from "../../assets/images/Shipora.png";
import shiporaLightLogo from "../../assets/images/Shipora-white.png";
import shiporaGroupIcon from "../../assets/images/SHIPORA(1).png"

//Import Social Profile Images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            isSocialPf: false
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleRightbar = this.toggleRightbar.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
    }
    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.toggleMenuCallback();
    }

    /**
     * Toggles the sidebar
     */
    toggleRightbar() {
        this.props.toggleRightSidebar();
    }


    toggleFullscreen() {
        if (
            !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    render() {
        return (
          <React.Fragment>
            <header id="page-topbar">
              <div className="navbar-header">
                <div className="d-flex">
                  <div className="navbar-brand-box">
                    <Link to="#" className="logo logo-dark">
                      <span className="logo-sm">
                        <img src={shiporaLightLogo} alt="" height="22" />
                      </span>
                      <span className="logo-lg">
                        <img src={shiporaLightLogo} alt="" height="20" />
                      </span>
                    </Link>

                    <Link
                      to="#"
                      className="logo logo-light"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        // padding: "0px 59px",
                      }}
                    >
                      <span className="logo-sm">
                        <img src={shiporaGroupIcon} alt="" height="30"  />
                      </span>
                      <span className="logo-lg">
                        <img src={shiporaGroupIcon} alt="" height="50" />
                      </span>
                    </Link>
                  </div>

                  <Button
                    size="sm"
                    color="none"
                    type="button"
                    onClick={this.toggleMenu}
                    className="px-3 font-size-24 header-item waves-effect"
                    id="vertical-menu-btn"
                  >
                    <i className="ri-menu-2-line align-middle"></i>
                  </Button>
                </div>

                <div className="d-flex">
                  <div className="dropdown d-none d-lg-inline-block ms-1">
                    <Button
                      color="none"
                      type="button"
                      className="header-item noti-icon waves-effect"
                      onClick={this.toggleFullscreen}
                    >
                      <i className="ri-fullscreen-line"></i>
                    </Button>
                  </div>

                  <NotificationDropdown />

                  <ProfileMenu />
                </div>
              </div>
            </header>
          </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header));
