import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import SubCategoryActionDropdown from "../../components/EcomSubCategoryActionDropdown";
import EcomChildCategoryActionDropDown from "../../components/EcomChildCategoryActionDropdown";

const EcommerceOrderColumns = (getAllVendorRequests, categories) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "child-category",
    text: "Child Category",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.name ? row.name : "-"}
      </Link>
    ),
  },
  {
    dataField: "sub-category",
    text: "Sub Category",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.sub_category_name ? row.sub_category_name : "-"}
      </Link>
    ),
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.parent_category_name ? row.parent_category_name : "-"}
      </Link>
    ),
  },
  {
    dataField: "slug",
    text: "Slug",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.slug ? row.slug : "-"}
      </Link>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.isActive === true ? "success" : "danger")
        }
        color={row.isActive === true ? "success" : "danger"}
        pill
      >
        {row?.isActive ? "ACTIVE" : "HIDDEN"}
      </Badge>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <EcomChildCategoryActionDropDown
        row={row}
        getAllVendorRequests={getAllVendorRequests}
        categories={categories}
        isEcom={true}
      />
    ),
  },
];

export default EcommerceOrderColumns;
