import React from 'react'
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
  } from "reactstrap";

function DropDownComponent({
    options = [],
    name,
    updateName,
    objectToBeUpdated,
    defaultValue = "",
  }) {
    const [dropdownOpen, setDropDownOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(defaultValue);
    function toggleDropdown() {
      setDropDownOpen((prev) => !prev);
    }
    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#000">
        <DropdownToggle className="bg-primary" caret>
          {selectedValue || name}
        </DropdownToggle>
        <DropdownMenu>
          {options.map((element, index) => {
            return (
              <DropdownItem
                key={index}
                onClick={() => {
                  setSelectedValue(element);
                  objectToBeUpdated((prev) => ({
                    ...prev,
                    [updateName]: element,
                  }));
                }}
              >
                {element}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  }

  export default DropDownComponent