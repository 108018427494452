import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { post, put } from "../helpers/api_helper";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import {
  selectCategoryToUpdate,
  selectProductToUpdate,
} from "../store/userAuthInfo/actions";
import { useHistory } from "react-router-dom";
function CouponActionDropdown({ row, getCoupons,setCoupon,setViewModal,isAdmin=false }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = () => {
    setDropdownOpen(false);
  };
  async function deleteCoupon() {
    try {
      if(isAdmin){
        await post(`/api/coupon-admin-delete`, {
          couponId: row.id,
        });
      }else{
        await post(`/api/coupon-delete`, {
          couponId: row.id,
        });
      }
      toastr.success("Status updated!", "Success");
      getCoupons();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
  async function getCouponByName() {
    try {
     const coupon =  await post(`/api/get-coupon-by-name`, {
        coupon_name: row.coupon_name,
      });
      const updatedCoupon = {...coupon,expiration_date:new Date(coupon.expiration_date),amount:parseFloat(coupon.amount),min_order_amount:parseFloat(coupon.min_order_amount)}
      setCoupon(updatedCoupon)
      setViewModal(true)
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
      <DropdownToggle caret style={{ backgroundColor: "#00b4d8" }}>
        <i className="mdi mdi-menu font-size-18"></i>
      </DropdownToggle>

      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            getCouponByName();
          }}
        >
          <i className="mdi mdi-clipboard-edit font-size-18 me-2"></i>EDIT
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            deleteCoupon();
            handleItemClick();
          }}
        >
          <i className="mdi mdi-delete font-size-18 me-2"></i>DELETE
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default CouponActionDropdown;
