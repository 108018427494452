import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { post } from "../helpers/api_helper";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { selectCategoryToUpdate } from "../store/userAuthInfo/actions";
import { useHistory } from "react-router-dom";

function SubCategoryActionDropdown({
  isEcom,
  row,
  getAllVendorRequests,
  categories,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDropdownOpen, setModalDropdownOpen] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedModalItem, setSelectedModalItem] = useState(null);
  const [newCategoryId, setNewCategoryId] = useState("");

  const dispatch = useDispatch();
  const navigation = useHistory();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (!dropdownOpen) {
      fetchDropdownOptions();
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleModalDropdown = () => {
    setModalDropdownOpen(!modalDropdownOpen);
  };

  const fetchDropdownOptions = async () => {
    try {
      const response = await post("/api/ecom/categories", {
        id: row?.id,
      });
      setDropdownOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleModalYes = async () => {
    let response = await post("/api/ecom/ecom-category-delete", {
      id: row?.id,
      newCategoryId: newCategoryId,
    });
    if (response) {
      toastr.success(response?.message);
    }
    getAllVendorRequests();
    toggleModal();
  };

  const handleModalNo = () => {
    setSelectedModalItem(null);
    toggleModal();
  };

  const handleItemClick = async (item) => {
    if (item === true || item === false) {
      let body = {
        id: row?.id,
        status: item,
      };
      try {
        let response = await post(
          "/api/ecom/ecom-category-status-change",
          body
        );
        toastr.success(response?.message);
        getAllVendorRequests(); 
      } catch (e) {
        console.log(e);
      }
    
    }
    if (item === "DELETE") {
      setIsModalOpen(true);
    }
      setSelectedItem(item);
      setDropdownOpen(false);
      await updateVendorRequestStatus(item);
  };

   async function updateVendorRequestStatus(status) {
     try {
       let response = await post("/api/ecom/ecom-category-check", {
         id: row.id,
       });
       if (response.isChildCategoryAvailable) {
       } else {
         let response = await post("/api/ecom/ecom-category-delete", {
           id: row?.id,
           newCategoryId: newCategoryId,
         });
         if (response) {
           toastr.success(response?.message);
         }
         getAllVendorRequests();
       }
     } catch (e) {
       console.log(e);
     }
   }

  const handleModalItemClick = (option) => {
    setSelectedModalItem(option);
  };

  const handleCategoryChange = (newId) => {
    setNewCategoryId(newId);
  };

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
        <DropdownToggle className="bg-primary" caret>
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem onClick={() => handleItemClick(true)}>
            <i className="mdi mdi-clock font-size-18 me-2"></i>ACTIVE
          </DropdownItem>
          <DropdownItem onClick={() => handleItemClick(false)}>
            <i className="mdi mdi-eye-off font-size-18 me-2"></i>HIDE
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              dispatch(selectCategoryToUpdate({ row, categories }));
              navigation.push("/add-new-ecom-category");
            }}
          >
            <i className="mdi mdi-pencil font-size-18 me-2"></i>EDIT
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              handleItemClick("DELETE");
            }}
          >
            <i className="mdi mdi-delete font-size-18 me-2"></i>DELETE
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      {/* Modal */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Category</ModalHeader>
        <ModalBody>
          Please select another Category to delete this one.
          <Dropdown
            isOpen={modalDropdownOpen}
            toggle={toggleModalDropdown}
            className="mt-3"
          >
            <DropdownToggle
              caret
              color="primary"
              disabled={dropdownOptions.length === 0}
            >
              {dropdownOptions.length > 0
                ? selectedModalItem
                  ? selectedModalItem
                  : "Select an Option"
                : "No categories available"}
            </DropdownToggle>
            <DropdownMenu>
              {dropdownOptions?.map((option, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => {
                    handleModalItemClick(option?.name);
                    handleCategoryChange(option.id);
                  }}
                >
                  {option?.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleModalYes}
            disabled={selectedModalItem == null}
          >
            Yes
          </Button>

          <Button color="primary" onClick={handleModalNo}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default SubCategoryActionDropdown;
