import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post } from "../../helpers/api_helper";
import { useDispatch } from "react-redux";
const VendorRevenueReport = () => {
  const [orders, setOrders] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [startDate, setStartDate] = useState(null); 
  const formattedDate = startDate ? new Date(startDate.getTime() + 24 * 60 * 60 * 1000).toISOString().split("T")[0] : null;
  const [selectedOrderForEmail, setSelectedOrderForEmail] = useState(null);

  const [documents, setDocuments] = useState([]);

  const user = useSelector((state) => state.userAuthInfo.user);
  useEffect(() => {
    getAllVendorRequests();
  }, [user,formattedDate]);
  async function getAllVendorRequests(status) {
    try {
      const content = await post("/api/get-all-vendor-revenue-report",{date : formattedDate});
      setOrders(content);
    } catch (e) {
      console.log(e);
    }
  }
  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: orders?.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  function toggleModal() {
    setSelectedOrderForEmail(null);
  }
   const clearDate = () => {
     setStartDate(null);
   };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Vendor Revenue Report"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Vendor Revenue Report", link: "#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        exportCSV={true}
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          getAllVendorRequests,
                          setDocuments,
                          toggleModal,
                          setSelectedOrderForEmail,
                          orders.length
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      value={searchString}
                                      onChange={(e) =>
                                        setSearchString(e.target.value)
                                      }
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col></Col>
                              <Col></Col>
                              <Col></Col>
                              <Col sm="2" className="d-inline-block">
                                <div className="date-picker">
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    placeholderText="Select date"
                                  />
                                </div>
                              </Col>
                              {startDate != null &&
                              <Col sm="1" className="d-inline-block">
                                <button
                                  onClick={clearDate}
                                  className="btn btn-primary"
                                >
                                  Clear
                                </button>
                              </Col>
                              }
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ height: "500px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default VendorRevenueReport;