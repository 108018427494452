import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import {
  Spinner,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  FormGroup,
  ModalHeader,
  ModalBody,
  Label,
  Button,
} from "reactstrap";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import MasterForm from "../../components/VendorRegistration/MasterForm";
import toastr from "toastr";
const WarehouseAddress = () => {
  const navigation = useHistory();
  const [dataObject, setDataObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const handleModalShow = () => {
    setModalShow((prev) => !prev);
  };
  async function handleNewDataObjectCreation() {
    try {
      setLoading(true);
      await post("/api/update-warehouse-address", dataObject);
      getAllCategories();
      setLoading(false);
      toastr.success("Address updated successfully");
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }
  const handleObjectChange = (event) => {
    const { name, value } = event.target;
    setDataObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    getAllCategories();
  }, []);
  async function getAllCategories() {
    try {
      const content = await get("/api/warehouse-address", {});
      setDataObject(content);
    } catch (e) {
      console.log(e);
    }
  }
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const selectRow = {
    mode: "checkbox",
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Warehouse Address"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Warehouse Address", link: "/warehouse-address" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="warehouse_address"
                        >
                          Warehouse Address
                        </Label>
                        <Input
                          id="warehouse_address"
                          name="address"
                          type="text"
                          value={dataObject?.address}
                          onChange={handleObjectChange}
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <FormGroup>
                        <Label for="expiry">Next Ship Dispatch</Label>
                        {dataObject?.next_ship_dispatch ? (
                          <DatePicker
                            minDate={new Date()}
                            selected={
                              dataObject?.next_ship_dispatch
                                ? new Date(dataObject?.next_ship_dispatch)
                                : new Date()
                            }
                            onChange={(date) => {
                              setDataObject((prev) => ({
                                ...prev,
                                next_ship_dispatch: date,
                              }));
                            }}
                          />
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <FormGroup>
                        <Label for="expiry">Next Air Dispatch</Label>
                        {dataObject?.next_air_dispatch ? (
                          <DatePicker
                            minDate={new Date()}
                            selected={
                              dataObject?.next_air_dispatch
                                ? new Date(dataObject?.next_air_dispatch)
                                : new Date()
                            }
                            onChange={(date) => {
                              setDataObject((prev) => ({
                                ...prev,
                                next_air_dispatch: date,
                              }));
                            }}
                          />
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row> */}

                  <Row style={{ marginTop: "120px" }}>
                    <Col xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {loading ? (
                          <Spinner
                            style={{ width: "2rem", height: "2rem" }}
                            children={false}
                          />
                        ) : (
                          <Button
                            color="primary"
                            className="w-md waves-effect waves-light"
                            type="submit"
                            onClick={handleNewDataObjectCreation}
                          >
                            Update
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

function DropDownComponent({
  options = [],
  name,
  updateName,
  objectToBeUpdated,
  defaultValue = "",
}) {
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  function toggleDropdown() {
    setDropDownOpen((prev) => !prev);
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#000">
      <DropdownToggle className="bg-primary" caret>
        {selectedValue || name}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((element, index) => {
          return (
            <DropdownItem
              key={index}
              onClick={() => {
                setSelectedValue(element);
                objectToBeUpdated((prev) => ({
                  ...prev,
                  [updateName]: element,
                }));
              }}
            >
              {element}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}
export default WarehouseAddress;
