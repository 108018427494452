import React, { Component } from "react";
import { Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setActiveTab } from "../../store/userAuthInfo/actions";

class MiniWidgets extends Component {
  handleNavigation = (path, title) => {
    if (title === "Blocked Vendors") {
      this.props.setActiveTab("BLOCKED");
      this.props.history.push(path);
    }
    if(title ==="Active Vendors"){
        this.props.setActiveTab("ACTIVE")
        this.props.history.push(path)
    }
    if (title === "Pending Vendors"){
        this.props.setActiveTab("PENDING")
        this.props.history.push(path);
    }
    if(title === "Total Vendor"){
      this.props.setActiveTab("ALL")
      this.props.history.push(path)
    }
    this.props.history.push(path);
  };

  render() {
    return (
      <React.Fragment>
        {this.props.reports.map((report, key) => (
          <Col
            key={key}
            md={4}
            onClick={() => this.handleNavigation(report.route, report.title)}
            style={{ cursor: "pointer" }}
          >
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">
                      {report.title}
                    </p>
                    <h4 className="mb-0">{report.value}</h4>
                  </div>
                  <div className="text-primary">
                    <i className={report.icon + " font-size-24"}></i>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

// Map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
});

// Wrap with `connect` and `withRouter`
export default withRouter(connect(null, mapDispatchToProps)(MiniWidgets));
