import React, { useEffect } from "react";
import MetisMenu from "metismenujs";
import { Link, useLocation, useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import { logDOM } from "@testing-library/react";

function SidebarContent(props) {
  const location = useLocation();
  const navigation = useHistory();
  useEffect(() => {
    initMenu();
  }, [props.type]);

  useEffect(() => {
    const matchingMenuItem = findMatchingMenuItem();
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [location.pathname]);

  const initMenu = () => {
    new MetisMenu("#side-menu");
  };

  const findMatchingMenuItem = () => {
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        return items[i];
      }
    }
    return null;
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); 
          parent3.childNodes[0].classList.add("mm-active"); 
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };
  let auth_info = JSON.parse(localStorage.getItem("auth_info"));
  let vendorName = auth_info.username;
  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t("Menu")}</li>

          {props?.user?.isAdmin ? (
            <>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-ship-fill"></i>

                  <span className="ms-1">{props.t("Shipment")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/dashboard">
                      <span className="ms-1">{props.t("Dashboard")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/users">
                      <span className="ms-1">{props.t("Customers")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/shipment-orders">
                      <span className="ms-1">
                        {props.t("Orders Management")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/shipping-calculator">
                      <span className="ms-1">
                        {props.t("Shipping Calculator")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/umbrella">
                      <span className="ms-1">
                        {props.t("Umbrella Management")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/email-templates">
                      <span className="ms-1">{props.t("Email Templates")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <span className="ms-1">
                        {props.t("Category Management")}
                      </span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/categories">
                          <span className="ms-1">{props.t("Categories")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/sub-category">
                          <span className="ms-1">
                            {props.t("Sub Categories")}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/package-sizes">
                      <span className="ms-1">{props.t("Package Sizes")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/coupon-admin">
                      <span className="ms-1">
                        {props.t("Coupon Management")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/insurance-charge">
                      <span className="ms-1">
                        {props.t("Insurance Charges")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/shipment-duration">
                      <span className="ms-1">
                        {props.t("Shipment Duration")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/shipment-enquiries">
                      <span className="ms-1">
                        {props.t("Shipment Enquiries")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/shipment-charges">
                      <span className="ms-1">
                        {props.t("Shipment Charges")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/warehouse-address">
                      <span className="ms-1">
                        {props.t("Warehouse Address")}
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-store-2-line"></i>
                  <span className="ms-1">{props.t("Marketplace")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/admin-ecommerce-dashboard">
                      <span className="ms-1">
                        {props.t("Ecommerce Dashboard")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/vendor-requests">
                      <span className="ms-1">
                        {props.t("Vendor Management")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/vendor-revenue-report">
                      <span className="ms-1">
                        {props.t("Vendor Revenue Report")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-management">
                      <span className="ms-1">
                        {props.t("Product Management")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin-ecommerce-orders">
                      <span className="ms-1">
                        {props.t("Order Management")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <span className="ms-1">
                        {props.t("Category Management")}
                      </span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/ecom-categories">
                          <span className="ms-1">{props.t("Categories")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/ecom-sub-categories">
                          <span className="ms-1">
                            {props.t("Sub Categories")}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/ecom-child-categories">
                          <span className="ms-1">
                            {props.t("Child-Categories")}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <span className="ms-1">
                        {props.t("Content Management")}
                      </span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/content-management-website">
                          <span className="ms-1">
                            {props.t("Website Content Management")}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/content-management-mobile">
                          <span className="ms-1">
                            {props.t("Mobile Content Management")}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/faqs">
                          <span className="ms-1">{props.t("FAQ")}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li style={{ width: "101%" }}>
                    <Link to="/handle-vendor-commission">
                      <span className="ms-1">{props.t("Platform Fees")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/management-admin-withdarawal">
                      <span className="ms-1">
                        {props.t("Withdrawal Management")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/shipment-charges">
                      <span className="ms-1">
                        {props.t("Shipment Charges")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin-queries">
                      <span className="ms-1">{props.t("Support")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/vendor-dashboard" className=" waves-effect">
                  <i className="ri-table-2"></i>
                  <span className="ms-1">Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to="/store-management" className=" waves-effect">
                  <i className="ri-table-2"></i>
                  <span className="ms-1">Store Management</span>
                </Link>
              </li>
              <li>
                <Link to="/manage-products" className=" waves-effect">
                  <i className="ri-table-2"></i>
                  <span className="ms-1">Product Management</span>
                </Link>
              </li>
              <li>
                <Link to="/vendor-ecommerce-orders" className=" waves-effect">
                  <i className="ri-table-2"></i>
                  <span className="ms-1">Order Management</span>
                </Link>
              </li>
              <li>
                <Link to="/vendor-withdarawal" className=" waves-effect">
                  <i className="ri-table-2"></i>
                  <span className="ms-1">Withdrawal Management</span>
                </Link>
              </li>

              <li>
                <Link to="/vendor-platform-free" className=" waves-effect">
                  <i className="ri-table-2"></i>
                  <span className="ms-1">Platform Fees Overview</span>
                </Link>
              </li>
              <li>
                <Link to="/support" className=" waves-effect">
                  <i className="ri-table-2"></i>
                  <span className="ms-1">Support</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return { ...state.Layout, user: state.userAuthInfo.user };
};

export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changePreloader,
})(withNamespaces()(SidebarContent));
