import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import avatar2 from "../../../assets/images/user-icon-header.png";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "../../../helpers/api_helper";
import {
  addUserInfo,
  removeUserInfo,
} from "../../../store/userAuthInfo/actions";
import { useHistory } from "react-router-dom";

const ProfileMenu = ({ t }) => {
  const [menu, setMenu] = useState(false);
  const user = useSelector((state) => state.userAuthInfo.user);
  const dispatch = useDispatch();
  const navigation = useHistory();
  const toggle = () => {
    setMenu((prevMenu) => !prevMenu);
  };
  async function getUserInfo() {
    const token = JSON.parse(localStorage.getItem("auth_info"));
    if (token) {
      try {
        const content = await post("/api/auth/info");
        dispatch(addUserInfo(content));
      } catch (e) {
        localStorage.clear();
        dispatch(removeUserInfo());
        navigation.push("/login");
        console.log(e);
      }
    }
  }

  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user me-1"
            src={avatar2}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 text-transform">
            {user?.username}
          </span>
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem href="#">
            <i className="ri-user-line align-middle me-1"></i> {t("Profile")}
          </DropdownItem>
          <DropdownItem href="#">
            <i className="ri-wallet-2-line align-middle me-1"></i>{" "}
            {t("My Wallet")}
          </DropdownItem>
          <DropdownItem className="d-block" href="#">
            <span className="badge badge-success float-end mt-1">11</span>
            <i className="ri-settings-2-line align-middle me-1"></i>{" "}
            {t("Settings")}
          </DropdownItem>
          <DropdownItem href="#">
            <i className="ri-lock-unlock-line align-middle me-1"></i>{" "}
            {t("Lock screen")}
          </DropdownItem> 
          <DropdownItem divider />
          */}
          <DropdownItem className="text-danger" onClick={()=>{
            localStorage.clear()
            dispatch(removeUserInfo())
            navigation.push("/login")
          }}>
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
            {t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withNamespaces()(ProfileMenu);
