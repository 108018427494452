// import React, { useState } from "react";
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   Button,
//   Input,
// } from "reactstrap";
// import { put, post } from "../helpers/api_helper";
// import toastr from "toastr";
// import { useSelector } from "react-redux";
// function OrderActionDropDOwn({
//   row,
//   fetchDataAgain,
//   fetchData,
//   shipmentType,
//   activeTab,
// }) {
//   // const activeTab = useSelector((state) => state.userAuthInfo.activeTab);
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [reasonModalOpen, setReasonModalOpen] = useState(false);
//   const [reason, setReason] = useState("");

//   const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
//   const toggleReasonModal = () => setReasonModalOpen(!reasonModalOpen);
//   const handleItemClick = (item) => {
//     setSelectedItem(item);
//     setDropdownOpen(false);
//     if (item === "HOLD" || item === "CANCELLED") {
//       toggleReasonModal();
//     } else {
//       updateVendorRequestStatus(item);
//     }
//   };

//   const handleSubmitReason = async () => {
//     try {
//       await post("/api/add-cancel-reason", {
//         reason,
//         id: row.id,
//       });
//       await updateVendorRequestStatus(selectedItem);
//       toggleReasonModal();
//       setReason("");
//     } catch (e) {
//       toastr.error("Failed to add reason", "Error");
//       console.log(e);
//     }
//   };

//   async function updateVendorRequestStatus(status) {
//     try {
//       await put(`/api/update-shipment-order/${row.id}`, {
//         status: status,
//       });
//       toastr.success("Status updated!", "Success");
//       fetchDataAgain();
//       fetchData();
//     } catch (e) {
//       toastr.error("Something went wrong", "Error");
//       console.log(e);
//     }
//   }
//   console.log("shipment type", shipmentType);
//   // if (shipmentType === "MARKETPLACE") {
//   //   const statusIcons = {
//   //     PLACED: "mdi-check",
//   //     RECEIVED: "mdi-package",
//   //     SHIPPED: "mdi-truck",
//   //     REACHED_TO_LOCAL_PARTNER: "mdi-city",
//   //     DELIVERED: "mdi-check-all",
//   //     HOLD: "mdi-pause",
//   //     CANCELLED: "mdi-cancel",
//   //   };

//   //   const statuses = [
//   //     "PLACED",
//   //     "RECEIVED",
//   //     "SHIPPED",
//   //     "REACHED_TO_LOCAL_PARTNER",
//   //     "DELIVERED",
//   //     "HOLD",
//   //     "CANCELLED",
//   //   ];
//   //   return statusIcons, statuses;
//   // } else {
//   //   const statusIcons = {
//   //     PLACED: "mdi-check",
//   //     RECEIVED: "mdi-package",
//   //     SHIPPED: "mdi-truck",
//   //     REACHED_TO_LOCAL_PARTNER: "mdi-city",
//   //     DELIVERED: "mdi-check-all",
//   //     HOLD: "mdi-pause",
//   //     CANCELLED: "mdi-cancel",
//   //   };

//   //   const statuses = [
//   //     "PLACED",
//   //     "RECEIVED",
//   //     "SHIPPED",
//   //     "REACHED_TO_LOCAL_PARTNER",
//   //     "DELIVERED",
//   //     "HOLD",
//   //     "CANCELLED",
//   //   ];
//   //   return statusIcons,statuses
//   // }
// const getStatusesAndIcons = (shipmentType) => {
//   if (shipmentType === "MARKETPLACE") {
//     const statusIcons = {
//       PLACED: "mdi-check",
//       READY_TO_DISPATCH: "mdi-truck-check",
//       PROCESSING: "mdi-cogs",
//       RECEIVED: "mdi-package",
//       SHIPPED: "mdi-truck",
//       REACHED_TO_LOCAL_PARTNER: "mdi-city",
//       DELIVERED: "mdi-check-all",
//       HOLD: "mdi-pause",
//       CANCELLED: "mdi-cancel",
//     };

//     const statuses = [
//       "PLACED",
//       "READY_TO_DISPATCH",
//       "PROCESSING",
//       "RECEIVED",
//       "SHIPPED",
//       "REACHED_TO_LOCAL_PARTNER",
//       "DELIVERED",
//       "HOLD",
//       "CANCELLED",
//     ];
//     return { statusIcons, statuses };
//   } else {
//     const statusIcons = {
//       PLACED: "mdi-check",
//       RECEIVED: "mdi-package",
//       SHIPPED: "mdi-truck",
//       REACHED_TO_LOCAL_PARTNER: "mdi-city",
//       DELIVERED: "mdi-check-all",
//       HOLD: "mdi-pause",
//       CANCELLED: "mdi-cancel",
//     };

//     const statuses = [
//       "PLACED",
//       "RECEIVED",
//       "SHIPPED",
//       "REACHED_TO_LOCAL_PARTNER",
//       "DELIVERED",
//       "HOLD",
//       "CANCELLED",
//     ];
//     return { statusIcons, statuses };
//   }
// };
//     const { statusIcons, statuses } = getStatusesAndIcons(shipmentType);


//   const getFilteredStatuses = (activeTab) => {
//     if (activeTab === "HOLD") {
//       return statuses.filter((status) => status !== "HOLD");
//     }
//     if (["DELIVERED", "CANCELLED"].includes(activeTab)) {
//       return [];
//     }
//     const index = statuses.indexOf(activeTab);
//     if (index === -1) {
//       return statuses;
//     }
//     return statuses.slice(index + 1);
//   };
//   return (
//     <>
//       <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
//         <DropdownToggle color="primary" className="primary" caret={false}>
//           <i className="mdi mdi-menu font-size-18"></i>
//         </DropdownToggle>
//         <DropdownMenu>
//           {getFilteredStatuses(activeTab).map((status) => (
//             <DropdownItem key={status} onClick={() => handleItemClick(status)}>
//               <i className={`mdi ${statusIcons[status]} font-size-18 me-2`}></i>
//               {status}
//             </DropdownItem>
//           ))}
//         </DropdownMenu>
//       </Dropdown>
//       <Modal
//         isOpen={reasonModalOpen}
//         toggle={toggleReasonModal}
//         style={{ maxWidth: "600px", width: "100%", height: "100%" }}
//       >
//         <ModalHeader toggle={toggleReasonModal}>Enter Reason</ModalHeader>
//         <ModalBody>
//           <Input
//             type="textarea"
//             value={reason}
//             onChange={(e) => setReason(e.target.value)}
//             placeholder="Enter reason for cancellation/hold"
//           />
//         </ModalBody>
//         <ModalFooter>
//           <Button color="primary" onClick={handleSubmitReason}>
//             Submit
//           </Button>
//         </ModalFooter>
//       </Modal>
//     </>
//   );
// }

// export default OrderActionDropDOwn;



import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { put, post } from "../helpers/api_helper";
import toastr from "toastr";

function OrderActionDropDOwn({
  row,
  fetchDataAgain,
  fetchData,
  shipmentType,
  activeTab,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [reason, setReason] = useState("");

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleReasonModal = () => setReasonModalOpen(!reasonModalOpen);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
    if (item === "HOLD" || item === "CANCELLED") {
      toggleReasonModal();
    } else {
      updateVendorRequestStatus(item);
    }
  };

  const handleSubmitReason = async () => {
    try {
      await post("/api/add-cancel-reason", {
        reason,
        id: row.id,
      });
      await updateVendorRequestStatus(selectedItem);
      toggleReasonModal();
      setReason("");
    } catch (e) {
      toastr.error("Failed to add reason", "Error");
      console.log(e);
    }
  };

  async function updateVendorRequestStatus(status) {
    try {
      await put(`/api/update-shipment-order/${row.id}`, {
        status: status,
      });
      toastr.success("Status updated!", "Success");
      fetchDataAgain();
      fetchData();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }

  const getStatusesAndIcons = (shipmentType) => {
    if (shipmentType === "MARKETPLACE") {
      const statusIcons = {
        PLACED: "mdi-check",
        READY_TO_DISPATCH: "mdi-truck-check",
        PROCESSING: "mdi-cogs",
        RECEIVED: "mdi-package",
        SHIPPED: "mdi-truck",
        REACHED_TO_LOCAL_PARTNER: "mdi-city",
        DELIVERED: "mdi-check-all",
        HOLD: "mdi-pause",
        CANCELLED: "mdi-cancel",
      };

      const statuses = [
        "PLACED",
        "READY_TO_DISPATCH",
        "PROCESSING",
        "RECEIVED",
        "SHIPPED",
        "REACHED_TO_LOCAL_PARTNER",
        "DELIVERED",
        "HOLD",
        "CANCELLED",
      ];
      return { statusIcons, statuses };
    } else {
      const statusIcons = {
        PLACED: "mdi-check",
        RECEIVED: "mdi-package",
        SHIPPED: "mdi-truck",
        REACHED_TO_LOCAL_PARTNER: "mdi-city",
        DELIVERED: "mdi-check-all",
        HOLD: "mdi-pause",
        CANCELLED: "mdi-cancel",
      };

      const statuses = [
        "PLACED",
        "RECEIVED",
        "SHIPPED",
        "REACHED_TO_LOCAL_PARTNER",
        "DELIVERED",
        "HOLD",
        "CANCELLED",
      ];
      return { statusIcons, statuses };
    }
  };

  const { statusIcons, statuses } = getStatusesAndIcons(shipmentType);

  const getFilteredStatuses = (activeTab) => {
    if (activeTab === "HOLD") {
      return statuses.filter((status) => status !== "HOLD");
    }
    if (["DELIVERED", "CANCELLED"].includes(activeTab)) {
      return [];
    }

    // Exclude past statuses based on activeTab
    const index = statuses.indexOf(activeTab);
    if (index === -1) {
      return statuses;
    }
    return statuses.slice(index + 1);
  };

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
        <DropdownToggle color="primary" className="primary" caret={false}>
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>
        <DropdownMenu>
          {getFilteredStatuses(activeTab).map((status) => (
            <DropdownItem key={status} onClick={() => handleItemClick(status)}>
              <i className={`mdi ${statusIcons[status]} font-size-18 me-2`}></i>
              {status}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <Modal
        isOpen={reasonModalOpen}
        toggle={toggleReasonModal}
        style={{ maxWidth: "600px", width: "100%", height: "100%" }}
      >
        <ModalHeader toggle={toggleReasonModal}>Enter Reason</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Enter reason for cancellation/hold"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmitReason}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default OrderActionDropDOwn;
