import React from "react";
import { Link } from "react-router-dom";
import EmailTemplateActionDropdown from "../../components/EmailTemplateActionDropdown";
function stripHtml(html) {
  var temp = document.createElement("div");
  temp.innerHTML = html;
  var text = temp.textContent || temp.innerText || "";
  temp.remove();
  return text;
}
const EcommerceOrderColumns = (
  setEmailTemplate,
  toggleModal,
  getEmailTemplates
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row?.id}</>,
  },
  {
    dataField: "title",
    text: "Title",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.title || ""}
      </Link>
    ),
  },
  {
    dataField: "subject",
    text: "Subject",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.subject?.trim()?.split("")?.slice(0, 9)?.join("") + "..."}
      </Link>
    ),
  },
  {
    dataField: "message",
    text: "Message",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {stripHtml(row?.message)?.trim()?.split("")?.slice(0, 17)?.join("") + "..." || "-"}
        {console.log("row log", row?.image)}
        {/* <img
          src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${row?.image}`}
          width="150px"
        /> */}
      </Link>
    ),
  },
  {
    dataField: "image",
    text: "Image",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        <img
          src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${row?.image}`} alt=""
          width="100px"
          height='auto'
        />
      </Link>
    ),
  },

  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <EmailTemplateActionDropdown
        getEmailTemplates={getEmailTemplates}
        row={row}
        setEmailTemplate={setEmailTemplate}
        toggleModal={toggleModal}
        fetchDataAgain={null}
      />
    ),
  },
];

export default EcommerceOrderColumns;
