import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { put } from "../helpers/api_helper";
import toastr from "toastr";

function UserActionDropdown({ row, fetchDataAgain, activeTab }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showSuspendPopup, setShowSuspendPopup] = useState(false);
  const [remarks, setRemarks] = useState("");

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleSuspendPopup = () => {
    setShowSuspendPopup(!showSuspendPopup);
  };

  const handleItemClick = (item) => {
    if (item === "SUSPEND") {
      setShowSuspendPopup(true);
    } else {
      updateVendorRequestStatus(item);
    }
    setDropdownOpen(false);
  };

  const handleSuspend = async () => {
    try {
      await put(`/api/auth/update-users-admin/${row.id}`, {
        status: "SUSPEND",
        remarks: remarks,
      });
      toastr.success("User suspended with remarks!", "Success");
      fetchDataAgain();
      setRemarks(""); 
      setShowSuspendPopup(false);
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  };

  async function updateVendorRequestStatus(status) {
    try {
      await put(`/api/auth/update-users-admin/${row.id}`, {
        status: status,
      });
      toastr.success("Status updated!", "Success");
      fetchDataAgain();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
        <DropdownToggle color="primary" caret>
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>
        <DropdownMenu>
          {activeTab === "ACTIVE" ? (
            <DropdownItem onClick={() => handleItemClick("SUSPEND")}>
              <i className="mdi mdi-clock font-size-18 me-2"></i>SUSPEND
            </DropdownItem>
          ) : (
            <DropdownItem onClick={() => handleItemClick("ACTIVE")}>
              <i className="mdi mdi-check font-size-18 me-2"></i>ACTIVE
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>

      {/* Suspend Remarks Popup */}
      <Modal isOpen={showSuspendPopup} toggle={toggleSuspendPopup}>
        <ModalHeader toggle={toggleSuspendPopup}>
          Add Remarks for Suspension
        </ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Enter remarks here..."
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSuspend}>
            Submit
          </Button>
          <Button color="secondary" onClick={toggleSuspendPopup}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default UserActionDropdown;
