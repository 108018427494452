import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardTitle, Col, Row, Form } from "reactstrap";
import Dropzone from "react-dropzone";

function SupportTicketAttachments({
  handleAcceptedFiles,
  selectedFiles,
  setSelectedFiles,
}) {
  return (
    <>
      <CardTitle className="h4">Attachment Files</CardTitle>
      <p className="card-title-desc">Upload attachment files</p>
      <Form className="dropzone">
        <Dropzone
        //   accept={["image/jpeg", "image/jpg", "image/png"]}
          onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <div>
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />

                <div className="mb-3">
                  <i className="display-4 text-muted ri-upload-cloud-2-line" />
                </div>
                <h4>Drop files here or click to upload.</h4>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="dropzone-previews mt-3" id="file-previews">
          {selectedFiles.map((f, i) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={f.preview}
                      />
                    </Col>
                    <Col>
                      <Link to="#" className="text-muted font-weight-bold">
                        {f.name}
                      </Link>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                    <Col>
                      <Button
                        color="danger"
                        onClick={() => {
                          const arrayOfImages = [...selectedFiles];
                          arrayOfImages.splice(i, 1);
                          setSelectedFiles(arrayOfImages);
                        }}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            );
          })}
        </div>
      </Form>
    </>
  );
}

export default SupportTicketAttachments;
