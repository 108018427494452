import React, { Component } from "react";
import { Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom"; 
import { formatDollars } from "../../helpers/amountFormatter";

class MiniWidgets extends Component {
  handleCardClick = (report) => {
    this.props.history.push(report); 
  };

  render() {
    return (
      <React.Fragment>
        {this.props.reports.map((report, key) => {
          const value =
            report.title === "Total Revenue"
              ? formatDollars(report.value)
              : report.title === "Total Order Weight"
              ? `${report.value} KG`
              : report.value;

          return (
            <Col key={key} md={4}>
              <Card
                onClick={() => this.handleCardClick(report.route)}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        {report.title}
                      </p>
                      <h4 className="mb-0">{value}</h4>
                    </div>
                    <div className="text-primary">
                      <i className={report.icon + " font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withRouter(MiniWidgets);
