import React from "react";
import { Container, Row, Col, CardBody, Card, Table } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "./MiniWidgets";
import { get } from "../../helpers/api_helper";
import { useSelector } from "react-redux";

const AdminShipmentDashboard = () => {
  const [data, setData] = React.useState({data:[]});
  
  const breadcrumbItems = [
    { title: "Shipora", link: "/" },
    { title: "Dashboard", link: "#" },
  ];

  const user = useSelector((state) => state.userAuthInfo.user);
  React.useEffect(() => {
    getDashboardInfo();
  }, [user]);
  async function getDashboardInfo() {
    try {
      const data = await get(`/api/admin/shipment/dashbord-info`);
      setData(data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xl={12}>
              <Row>
                <MiniWidgets reports={data?.data} />
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col  md={6}>
              <Card>
                <CardBody>
                  <div className="d-flex" style={{flexDirection:"column"}}>
                    <div className=" overflow-hidden">
                      <h4 className="mb-2">Top Categories - Items Sold</h4>
                    </div>
                    <Table>
                        <tr>
                            <th style={{fontWeight:700,fontSize:"15px"}}>Category</th>
                            <th style={{fontWeight:700,fontSize:"15px"}}>Items Sold</th>
                            <th style={{fontWeight:700,fontSize:"15px"}}>Net Sales</th>
                        </tr>
                        {data?.categoriesWithProductSold && data?.categoriesWithProductSold?.map(item=>{
                            return (
                                <tr>
                                    <td>{item?.name}</td>
                                    <td>{item?.ecommerce_order_items?.length}</td>
                                    <td>{item?.ecommerce_order_items?.reduce((acc,curr)=>{
                                        acc+=curr.grand_total
                                        return acc    
                                    },0)}</td>
                                </tr>
                            )
                        })}
                    </Table>
                  </div>
                </CardBody>

              </Card>
            </Col>
            <Col  md={6}>
              <Card>
                <CardBody>
                  <div className="d-flex" style={{flexDirection:"column"}}>
                    <div className=" overflow-hidden">
                      <h4 className="mb-2">Top Products Sold</h4>
                    </div>
                    <Table>
                        <tr>
                            <th style={{fontWeight:700,fontSize:"15px"}}>Product</th>
                            <th style={{fontWeight:700,fontSize:"15px"}}>Items Sold</th>
                            <th style={{fontWeight:700,fontSize:"15px"}}>Net Sales</th>
                        </tr>
                        {data?.topProductCount && data?.topProductCount?.map(item=>{
                            return (
                                <tr>
                                    <td>{item?.name}</td>
                                    <td>{item?.ecommerce_ordered_item?.length}</td>
                                    <td>{item?.ecommerce_ordered_item?.reduce((acc,curr)=>{
                                        acc+=curr.grand_total
                                        return acc    
                                    },0)}</td>
                                </tr>
                            )
                        })}
                    </Table>
                  </div>
                </CardBody>

              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminShipmentDashboard;
