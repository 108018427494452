import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import toastr from "toastr";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import { changeVendorActiveTab } from "../../store/actions";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import MasterForm from "../../components/VendorRegistration/MasterForm";
import SearchableDropdown from "../../components/SearchableDropdown";
import Stage1SelectUserAndPackageSize from "../../components/Stage1SelectUserAndPackageSize";
import Stage2SelectUserAddress from "../../components/Stage2SelectUserAddress";
import { useDispatch } from "react-redux";
const VendorEcommerceOrders = () => {
  const dispatch = useDispatch();
  const [viewModal, setViewModal] = useState(false);
  const [showDifferenceModal, setShowDifferenceModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [differenceAmount, setDifferenceAmount] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [activeTab, setActiveTab] = useState("PLACED");
  const [searchString, setSearchString] = useState("");
  const [documents, setDocuments] = useState([]);
  const user = useSelector((state) => state.userAuthInfo.user);
  const handleAddVendorModal = () => {
    setViewModal((prev) => !prev);
    setDifferenceAmount(0);
  };
  const handleShowDifferenceModal = () => {
    setShowDifferenceModal((prev) => !prev);
  };

  async function createDifferencePaymentLink() {
    try {
      if (!differenceAmount) {
        toastr.error("Please provide difference amount");
        return;
      }
      if (!remarks) {
        toastr.error("Please provide difference amount");
        return;
      }
      await post("/api/create-custom-difference-payment-link", {
        difference_remarks: remarks,
        difference_amount: differenceAmount,
        shipment_order_id: documents.id,
      });
      toastr.success("Success sent payment link");
      handleShowDifferenceModal();
    } catch (e) {
      console.log(e);
      toastr.error("Error creating payment link");
    }
  }
  const handleShowImageModal = () => {
    setShowImageModal((prev) => !prev);
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      dispatch(changeVendorActiveTab(tab));
    }
  };
  useEffect(() => {
    getAllVendorRequests();
  }, [activeTab, user]);
  async function getAllVendorRequests(status) {
    try {
      const content = await post("/api/ecom/get-all-orders/vendor", {
        status: status ? status : activeTab,
        vendor_id: user?.id
      });
      setOrders(content);
    } catch (e) {
      console.log(e);
    }
  }
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const { SearchBar } = Search;
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Ecommerce Orders"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Ecommerce Orders", link: "#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "PLACED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("PLACED")}
                              >
                                PLACED
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "READY_TO_DISPATCH"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => toggleTab("READY_TO_DISPATCH")}
                              >
                                READY TO DISPATCH
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "PROCESSING" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("PROCESSING")}
                              >
                                PROCESSING
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "RECEIVED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("RECEIVED")}
                              >
                                RECEIVED
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "SHIPPED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("SHIPPED")}
                              >
                                SHIPPED
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "REACHED_TO_LOCAL_PARTNER"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  toggleTab("REACHED_TO_LOCAL_PARTNER")
                                }
                              >
                                REACHED TO LOCAL PARTNER
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "DELIVERED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("DELIVERED")}
                              >
                                DELIVERED
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "HOLD" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("HOLD")}
                              >
                                HOLD
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "CANCELLED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("CANCELLED")}
                              >
                                CANCELLED
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        exportCSV={true}
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          getAllVendorRequests,
                          setDocuments,
                          handleShowImageModal,
                          orders.length,
                          activeTab
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      value={searchString}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    hidden
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddVendorModal}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Add New
                                    Order
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ height: "500px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={showImageModal} toggle={handleShowImageModal}>
        <ModalHeader toggle={handleShowImageModal}>Order Info</ModalHeader>
        <ModalBody>
          <div style={{ padding: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <span
                style={{ fontWeight: 600 }}
              >{`Order #${documents?.id}`}</span>
            </div>

            <div
              key={documents?.product?.id}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #E3E3E3",
                borderRadius: "10px",
                margin: "2px 0",
              }}
            >
              <div style={{ width: "10%" }}>
                <img
                  height={65}
                  src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${
                    documents?.product?.product_variants?.length
                      ? documents?.product?.product_variants[0]?.image_urls[0]
                      : documents?.product?.image_urls[0]
                  }`}
                />
              </div>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  height: "100%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div>{documents?.product?.name}</div>
                <div>
                  {`Type of shipment: `}
                  {documents?.shipment_type === "AIR" ? (
                    <i className="mdi mdi-airplane font-size-18 me-2"></i>
                  ) : (
                    <i className="mdi mdi-ferry font-size-18 me-2"></i>
                  )}
                </div>
                <div>{`${documents?.unit_price} X ${documents?.unit_quantity}`}</div>
                {documents?.product?.attributes?.length > 0 ? (
                  <table>
                    {documents?.product?.attributes?.map((item) => {
                      return (
                        <tr
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <td style={{ fontWeight: 700 }}>{item?.name}</td>
                          <td style={{ marginLeft: "10px" }}>
                            {item?.attribute_value[0]?.value}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                ) : null}
              </div>
            </div>

            <div style={{ margin: "20px 0" }}>
              <span style={{ fontWeight: 600 }}>Delivery Address</span>
            </div>
            <div
              style={{
                border: "1px solid #E3E3E3",
                color: "#5E6363",
                padding: "10px",
              }}
            >
              {`${documents?.ecommerce_order?.delivery_address?.address} ${documents?.ecommerce_order?.delivery_address?.area_name} ${documents?.ecommerce_order?.delivery_address?.landmark} 
                ${documents?.ecommerce_order?.delivery_address?.first_name} ${documents?.ecommerce_order?.delivery_address?.last_name} 
                ${documents?.ecommerce_order?.delivery_address?.mobile_number} `}
            </div>

            <div
              style={{
                border: "1px solid #E3E3E3",
                padding: "15px",
                margin: "20px 0",
              }}
            >
              <div style={{ margin: "20px 0" }}>
                <span style={{ fontWeight: 600 }}>Total Amount</span>
              </div>

              <div
                style={{
                  border: "1px solid #E3E3E3",
                  margin: "20px auto",
                  width: "95%",
                }}
              ></div>
              <div style={{ width: "90%", margin: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{}}>Sub Total</div>
                  <div>{documents?.sub_total || 0}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{}}>Taxes</div>
                  <div>{documents?.taxes || 0}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{}}>Grand Total</div>
                  <div>{documents?.grand_total || 0}</div>
                </div>
              </div>

              <div
                style={{
                  border: "1px solid #E3E3E3",
                  margin: "20px auto",
                  width: "95%",
                }}
              ></div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default VendorEcommerceOrders;
