import React, { useState } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Spinner,
  NavLink,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Switch from "react-switch";
import toastr from "toastr";
import axios from 'axios'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../helpers/api_helper";
import { selectCategoryToUpdate } from "../../store/userAuthInfo/actions";
function Offsymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
}

function OnSymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      Yes
    </div>
  );
}
const AddEcomCategory = () => {
  const dispatch = useDispatch();
  const selectedCategoryForUpdate = useSelector(
    (state) => state.userAuthInfo.selectedCategoryForUpdate
  );
  const [categoryIconFile, setCategoryIconFile] = useState(null);

  const [isSubCategory, setIsSubCategory] = useState(
    !!selectedCategoryForUpdate?.row?.parent_category_id || false
  );
  
  const [showDropdown, setShowDropdown] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState({
    name: selectedCategoryForUpdate?.row?.name || "",
    description: selectedCategoryForUpdate?.row?.description || "",
    slug: selectedCategoryForUpdate?.row?.slug || "",
    ecom_category_commission : selectedCategoryForUpdate?.row?.ecom_category_commission || ""
  });
  const [selectedItem, setSelectedItem] = useState(
    selectedCategoryForUpdate?.row?.id || null
  );
  const [categories, setCategories] = useState([]);
  const navigation = useHistory();
  const [categoryFile, setCategoryFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const breadcrumbItems = [
    { title: "Dashboard", link: "/" },
    { title: "Categories", link: "/categories" },
    { title: "Add New Category", link: "#" },
  ];
  function toggleDropdown() {
    setShowDropdown((prev) => !prev);
  }

const handleCategoryIconFileChange = (event) => {
  const selectedFile = event.target.files[0];
  if (selectedFile && isImageType(selectedFile)) {
    setCategoryIconFile(
      Object.assign(selectedFile, {
        preview: URL.createObjectURL(selectedFile),
      })
    );
  } else {
    alert("Please select a valid image file (JPEG, PNG)");
  }
};
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowDropdown((prev) => !prev);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
     setCategoryInfo((prevState) => ({
       ...prevState,
       [name]: name === "ecom_category_commission" ? parseFloat(value) : value,
     }));
  };
  const isImageType = (file) => {
    const imageTypes = ["image/jpeg", "image/png"];
    return imageTypes.includes(file.type);
  };
  React.useEffect(() => {
    getAllCategories();
    return () => {
      dispatch(selectCategoryToUpdate(null));
    };
  }, []);
  async function getAllCategories() {
    try {
      const content = await post("/api/ecom/get-categories", {});
      setCategories(content);
    } catch (e) {
      console.log(e);
    }
  }
  const handleCategoryFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && isImageType(selectedFile)) {
      setCategoryFile(
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile),
        })
      );
    } else {
      alert("Please select a valid image file (JPEG, PNG)");
    }
  };
  const handleSubmit = async (event, values) => {
    try {
      setLoading(true);
      if (
        categoryInfo.name === "" ||
        
        categoryInfo.slug === ""
      ) {
        let errorMessage = "Please fill in the following fields:\n";
        if (categoryInfo.name === "") {
          errorMessage += "- Name\n";
        }
        if (categoryInfo.ecom_category_commission === ""){
          errorMessage += "- Commission\n";
        }
          if (!categoryFile) {
            errorMessage += "- Category image\n";
          }
        if (!categoryIconFile) {
          errorMessage += "- Category Icon\n";
        }
        if (categoryInfo.slug === "") {
          errorMessage += "- Slug\n";
        }
        if (isSubCategory && !selectedItem) {
          errorMessage += "- Parent category is required\n";
        }
        alert(errorMessage);
        setLoading(false);
      } else {
        const formData = new FormData();
        formData.append("categoryImage", categoryFile);
        formData.append("categoryIcon", categoryIconFile);


        formData.append(
          "objectDetails",
          JSON.stringify({
            ...categoryInfo,
            ...(selectedItem && { parentCategoryId: selectedItem.id }),
            ...(selectedCategoryForUpdate?.row?.id && {
              id: selectedCategoryForUpdate?.row?.id,
            }),
          })
        );
          const config = {
            method: "POST",
            body: formData,
          };
         let responseObj = await fetch(
           `${process.env.REACT_APP_AUTHDOMAIN}/api/ecom/create-category`,
           config
         );
         
        const res = await responseObj.json();
        if (res?.message) {
          toastr.error(res?.message, "Error");
          setLoading(false);
          return;
        }
        navigation.push("/ecom-categories");
        setLoading(false);
      }
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      setLoading(false);
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Add Ecom Category"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div
                    id="addproduct-nav-pills-wizard"
                    className="twitter-bs-wizard"
                  >
                    <TabContent
                      activeTab={1}
                      className="twitter-bs-wizard-tab-content"
                    >
                      <TabPane tabId={1}>
                        <CardTitle className="h5">Basic Information</CardTitle>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="productname">
                            Category Name
                          </Label>
                          <Input
                            id="productname"
                            name="name"
                            type="text"
                            value={categoryInfo?.name}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="slug">
                            Slug
                          </Label>
                          <Input
                            id="slug"
                            value={categoryInfo?.slug}
                            onChange={handleChange}
                            name="slug"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="ecom_category_commission"
                          >
                            Commission
                          </Label>
                          <Input
                            id="ecom_category_commission"
                            value={categoryInfo?.ecom_category_commission}
                            onChange={handleChange}
                            name="ecom_category_commission"
                            type="number"
                            className="form-control"
                            max="100" 
                            onInput={(e) => {
                              if (e.target.value > 100) {
                                e.target.value = 100;
                              }
                            }}
                            required
                          />
                          <p style={{ paddingTop: "8px" }}>
                            <b>Note:</b> Commission is calculated based on a percentage of the total amount.
                          </p>
                        </div>
                        <div className="mb-3">
                          <Label for="pan">Category image</Label>
                          <FormGroup>
                            <Input
                              type="file"
                              name="file"
                              id="category"
                              onChange={handleCategoryFileChange}
                            />
                            <img
                              src={
                                categoryFile?.preview
                                  ? categoryFile?.preview
                                  : selectedCategoryForUpdate?.row?.image_url
                                  ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${selectedCategoryForUpdate?.row?.image_url}`
                                  : ``
                              }
                              width="150px"
                            />
                          </FormGroup>
                        </div>
                        <div className="mb-3">
                          <Label for="pan">Category Icon</Label>
                          <FormGroup>
                            <Input
                              type="file"
                              name="file"
                              id="categoryIcon"
                              onChange={handleCategoryIconFileChange}
                            />
                            <img
                              src={
                                categoryIconFile?.preview
                                  ? categoryIconFile?.preview
                                  : selectedCategoryForUpdate?.row
                                      ?.image_icon_url
                                  ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${selectedCategoryForUpdate?.row?.image_icon_url}`
                                  : ``
                              }
                              width="150px"
                            />
                          </FormGroup>
                        </div>
                      </TabPane>
                    </TabContent>
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li className={"next"} onClick={handleSubmit}>
                        {loading ? (
                          <Spinner
                            style={{ width: "2rem", height: "2rem" }}
                            children={false}
                          />
                        ) : (
                          <Link to="#">Save</Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEcomCategory;
