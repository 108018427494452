import React, { useEffect, useState } from "react";
import { Button, Input, Label, Row, Col } from "reactstrap";
import { post } from "../helpers/api_helper";
import toastr from "toastr";
import { formTitleText } from "../store/actions";
import { useDispatch } from "react-redux";
function AddNewUserAddress({
  user_id,
  hideAddressForm,
  getUserAddresses,
  setIsPickupclicked,
  isPickupClicked,
}) {
  const [address, setAddress] = useState({
    mobile_number: "",
    mobile_number_country_code: "+91",
    country: "India",
    address: "",
    city: "",
    zipcode: "",
    first_name: "",
    last_name: "",
    state: "",
    landmark: "",
    area_name : "",
  });
   const dispatch = useDispatch();

   useEffect(()=>{

     dispatch(formTitleText("PickUp Address"));
   },[])
  
  const [errors, setErrors] = useState({});
  const [isZipcodeValid, setIsZipcodeValid] = useState(false);

  useEffect(() => {
    if (isZipcodeValid) {
      post("/api/zipcode-pickup-address", { postalcode: address.zipcode })
        .then((res) => {
          if (res) {
            setAddress((prev) => ({
              ...prev,
              city: res[0].adminName2,
              state: res[0].adminName1,
            }));
          } else {
            toastr.error("Invalid Zip Code or data not found.");
          }
        })
        .catch((error) => {
          // toastr.error("Error fetching city/state data");
        });
    }
  }, [isZipcodeValid, address.zipcode]);

  const handleObjectChange = (event) => {
    const { name, value } = event.target;

    if (name === "first_name" || name === "last_name") {
      const regex = /^[A-Za-z\s]*$/;
      if (regex.test(value)) {
        setAddress((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "Name can only contain letters.",
        }));
      }
    } else if (name === "mobile_number") {
      const regex = /^\d{0,10}$/;
      if (regex.test(value)) {
        setAddress((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, mobile_number: "" }));
      } else {
        setErrors((prev) => ({
          ...prev,
          mobile_number:
            "Mobile number must be 10 digits and contain only numbers.",
        }));
      }
    } else if (name === "zipcode") {
      const regex = /^\d{0,6}$/; 
      if (regex.test(value)) {
        setAddress((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, zipcode: "" }));
        if (value.length === 6) {
          setIsZipcodeValid(true); 
        } else {
          setIsZipcodeValid(false);
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          zipcode:
            "Zip Code must be exactly 6 digits and contain only numbers.",
        }));
      }
    } else {
      setAddress((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateFields = () => {
    const newErrors = {};

    if (!address?.first_name) {
      newErrors.first_name = "Please enter First Name";
    }
    if (!address?.last_name) {
      newErrors.last_name = "Please enter Last Name";
    }
    if (!address?.address || address.address.length < 30) {
      newErrors.address = "Address must be at least 30 characters long.";
    }
    if (!address?.city) {
      newErrors.city = "Please enter City";
    } else if (!/^[A-Za-z\s]+$/.test(address.city)) {
      newErrors.city = "City can only contain letters.";
    }
    if (!address?.state) {
      newErrors.state = "Please enter State";
    }
    if (!address?.country) {
      newErrors.country = "Please enter Country";
    }
    if (!address?.zipcode) {
      newErrors.zipcode = "Please enter Zip Code";
    }
    if (!address?.mobile_number) {
      newErrors.mobile_number = "Please enter Mobile Number";
    }
    if (!address?.mobile_number_country_code) {
      newErrors.mobile_number_country_code =
        "Please enter Mobile Number Country Code";
    }
    if (address.mobile_number.length !== 10) {
      newErrors.mobile_number = "Mobile number must be exactly 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  async function saveUserAddress() {
    if (!validateFields()) {
      return;
    }
    try {
      await post("/api/create-delivery-address-admin", {
        ...address,
        user_id,
        isPickup: true,
      });
      toastr.success("Pickup address create successfully")
      setAddress({
        mobile_number: "",
        mobile_number_country_code: "+91",
        country: "India",
        address: "",
        city: "",
        zipcode: "",
        first_name: "",
        last_name: "",
        state: "",
        area_name : "",
      });
      setIsPickupclicked(false);
      hideAddressForm();
      getUserAddresses();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="first_name">
              First Name
            </Label>
            <Input
              id="first_name"
              name="first_name"
              type="text"
              value={address?.first_name || ""}
              onChange={handleObjectChange}
              className="form-control"
            />
            {errors.first_name && (
              <span className="text-danger">{errors.first_name}</span>
            )}
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="last_name">
              Last Name
            </Label>
            <Input
              id="last_name"
              name="last_name"
              type="text"
              value={address?.last_name || ""}
              onChange={handleObjectChange}
              className="form-control"
            />
            {errors.last_name && (
              <span className="text-danger">{errors.last_name}</span>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="address">
              Address
            </Label>
            <Input
              id="address"
              name="address"
              type="text"
              value={address?.address || ""}
              onChange={handleObjectChange}
              className="form-control"
            />
            {errors.address && (
              <span className="text-danger">{errors.address}</span>
            )}
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="mobile_number">
              Mobile Number
            </Label>
            <div className="input-group">
              <span
                className="input-group-text"
                id="mobile_number_country_code"
              >
                {address?.mobile_number_country_code || ""}
              </span>
              <Input
                id="mobile_number"
                name="mobile_number"
                type="text"
                value={address?.mobile_number || ""}
                onChange={handleObjectChange}
                className="form-control"
                aria-describedby="mobile_number_country_code"
              />
            </div>
            {errors.mobile_number && (
              <span className="text-danger">{errors.mobile_number}</span>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="country">
              Country
            </Label>
            <select
              id="country"
              name="country"
              value="India"
              onChange={handleObjectChange}
              className="form-control"
              readOnly
            >
              <option value="India">India</option>
            </select>
            {errors.country && (
              <span className="text-danger">{errors.country}</span>
            )}
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="zipcode">
              Zip Code
            </Label>
            <Input
              id="zipcode"
              name="zipcode"
              type="text"
              value={address?.zipcode || ""}
              onChange={handleObjectChange}
              className="form-control"
            />
            {errors.zipcode && (
              <span className="text-danger">{errors.zipcode}</span>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="city">
              City
            </Label>
            <Input
              id="city"
              name="city"
              type="text"
              value={address?.city || ""}
              onChange={handleObjectChange}
              className="form-control"
            />
            {errors.city && <span className="text-danger">{errors.city}</span>}
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="state">
              State
            </Label>
            <Input
              id="city"
              name="city"
              type="text"
              value={address?.state || ""}
              onChange={handleObjectChange}
              className="form-control"
              readOnly
            />
            {errors.state && (
              <span className="text-danger">{errors.state}</span>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="city">
              Landmark
            </Label>
            <Input
              id="landmark"
              name="landmark"
              type="text"
              value={address?.landmark || ""}
              onChange={handleObjectChange}
              className="form-control"
            />
            {errors.landmark && (
              <span className="text-danger">{errors.landmark}</span>
            )}
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="city">
              area_name
            </Label>
            <Input
              id="area_name"
              name="area_name"
              type="text"
              value={address?.area_name || ""}
              onChange={handleObjectChange}
              className="form-control"
            />
            {errors.landmark && (
              <span className="text-danger">{errors.area_name}</span>
            )}
          </div>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "50%",
          margin: "auto",
        }}
      >
        <Button
          style={{ marginTop: "30px" }}
          color="primary"
          onClick={hideAddressForm}
        >
          Cancel
        </Button>
        <Button
          style={{ marginTop: "30px" }}
          color="primary"
          className="primary"
          onClick={saveUserAddress}
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default AddNewUserAddress;
