import React from 'react';
import { Label, Input } from 'reactstrap';

const CustomField = ({ fieldName, fieldType, label, value, onChange }) => {
  return (
    <div className="mb-3">
      <Label className="form-label" htmlFor={fieldName}>
        {label}
      </Label>
      <Input
        id={fieldName}
        name={fieldName}
        type={fieldType}
        value={value || ""}
        onChange={(e) => onChange(e)}
        className="form-control"
      />
    </div>
  );
};

export default CustomField;
