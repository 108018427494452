import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { put } from "../helpers/api_helper";
import toastr from "toastr";
import { useSelector } from "react-redux";
function ActionDropdown({ row, getAllVendorRequests }) {
   const activeTab = useSelector((state) => state.userAuthInfo.activeTab);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
    updateVendorRequestStatus(item);
  };
  async function updateVendorRequestStatus(status) {
    try {
      await put(`/api/vendor-request/${row.id}`, {
        status: status,
      });
      toastr.success("Status updated!", "Success");
      getAllVendorRequests();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
      <DropdownToggle caret color="primary" className="primary">
        <i className="mdi mdi-menu font-size-18"></i>
      </DropdownToggle>
      <DropdownMenu>
        {/* {activeTab !== "PLACED" &&
          activeTab !== "PENDING" &&
          activeTab !== "ACTIVE" && (
            <DropdownItem onClick={() => handleItemClick("PENDING")}>
              <i className="mdi mdi-clock font-size-18 me-2"></i>PENDING
            </DropdownItem>
          )} */}
        {activeTab !== "ACTIVE" && (
          <DropdownItem onClick={() => handleItemClick("ACTIVE")}>
            <i className="mdi mdi-check font-size-18 me-2"></i>ACTIVE
          </DropdownItem>
        )}
        {activeTab !== "BLOCKED" && (
          <DropdownItem onClick={() => handleItemClick("BLOCKED")}>
            <i className="mdi mdi-block-helper font-size-18 me-2"></i>BLOCK
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

export default ActionDropdown;
