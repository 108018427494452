import React from "react";
import { Button, Col, Row, Input, Label, ModalHeader } from "reactstrap";
import { post } from "../helpers/api_helper";
import SearchableDropdown from "./SearchableDropdown";
import DropDownComponent from "./DropdownComponent";
import AddNewUserAddress from "./AddNewAddress";
import { getAddressLabelInfo } from "../helpers/helperFunctions";
import AddNewDeliveryAddress from "./AddNewDeliveryAddress";
import { formTitleText } from "../store/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
function Stage2SelectUserAddress({
  selectedPickupAddress,
  setSelectedPickupAddress,
  setCurrentStage,
  selectedUser,
  setSelectedAddress,
  shipmentType,
  setShipmentType,
  orderType,
  setOrderType,
  shipmentCharges,
  selectedPackageSize,
  currentStage,
  createShipmentOrderForUser,
  setTotalAmount,
  totalAmount,
  selectedAddress,
  setAdditionalProperties,
  additionalProperties,
  insuranceCharges,
  customPackageSize,
}) {
  const [addresses, setAddresses] = React.useState([]);
   const dispatch = useDispatch();
   useEffect(() => {
     dispatch(formTitleText("Create Order"));
   }, [dispatch]);
  
  const [pickupAddresses, setPickupAddresses] = React.useState([]);
  const [showPickupAddressForm, setShowPickupAddressForm] = React.useState(false);
  const [showDeliveryAddressForm, setShowDeliveryAddressForm] = React.useState(false);
   
  async function getUserDeliveryAddress() {
    try {
      const content = await post("/api/get-delivery-address-admin", {
        user_id: selectedUser.value,
      });
      if (content?.length) {
        const addresses = content?.map((addressObject) => ({
          label: getAddressLabelInfo(addressObject),
          value: addressObject.id,
        }));
        setAddresses(addresses || []);
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function getUserPickupAddress() {
    try {
      const content = await post("/api/get-pickup-address-admin", {
        user_id: selectedUser.value,
      });
      if (content?.length) {
        const addresses = content?.map((addressObject) => ({
          label: getAddressLabelInfo(addressObject),
          value: addressObject.id,
        }));
        setPickupAddresses(addresses || []);
      }
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    getUserDeliveryAddress();
    getUserPickupAddress();
  }, []);
   

  function calculateShipmentAmountTotal(shipType, orderType, value = 0) {
    const charges = shipmentCharges.find(
      (el) =>
        el.shipping_charges_type === shipType &&
        el.Order_charges_type === orderType
    );
    function getTotalFieldValue(info) {
    const local_delivery_charge = info?.local_delivery_charge
      ? info?.local_delivery_charge
      : 0;
    const international_courier_charge = info?.international_courier_charge
      ? info?.international_courier_charge
      : 0;
    const human_resource_charge = info?.human_resource_charge
      ? info?.human_resource_charge
      : 0;
    const taxes = info?.taxes ? info?.taxes : 0;
    let grandTotal = local_delivery_charge + international_courier_charge;
    if (info?.Order_charges_type === "ECOM") {
      grandTotal += human_resource_charge;
    }
    const taxAmount = (grandTotal * taxes) / 100;
    return taxAmount + grandTotal;
  }
    const amountWithTax = getTotalFieldValue({
      ...charges,
      Order_charges_type: orderType,
    });
    let grandTotal = amountWithTax * value;

    if (selectedPackageSize?.discount) {
      const discountAmount = (grandTotal * selectedPackageSize?.discount) / 100;
      grandTotal = grandTotal - discountAmount;
    }

    setTotalAmount(grandTotal);

    if (additionalProperties?.insurance_value) {
      let insuranceTotal =
        insuranceCharges.upto_value <= additionalProperties?.insurance_value
          ? additionalProperties?.insurance_value
          : insuranceCharges.upto_value;
      const insurancePercent =
        (insuranceTotal * insuranceCharges.percentage_amount) / 100;
      grandTotal += insurancePercent;
    }
    if (insuranceCharges?.gst_percentage) {
      const gstPercent = (grandTotal * insuranceCharges?.gst_percentage) / 100;
      grandTotal += gstPercent;
    }

    if (additionalProperties?.additional_discount) {
      grandTotal -= additionalProperties?.additional_discount;
    }

    grandTotal = parseFloat(grandTotal.toFixed(2));
    return grandTotal;
  }

  const handleSelectedAddress = (selectedOption) => {
    setSelectedAddress(selectedOption);
  };

  const handleSelectedPickupAddress = (selectedOption) => {
    setSelectedPickupAddress(selectedOption);
  };

  function hideAddressForm() {
    setShowPickupAddressForm(false);
    setShowDeliveryAddressForm(false);
  }

  return showPickupAddressForm ? (
    <AddNewUserAddress
      getUserAddresses={() => {
        getUserDeliveryAddress();
        getUserPickupAddress();
      }}
      user_id={selectedUser.value}
      hideAddressForm={hideAddressForm}
    />
  ) : showDeliveryAddressForm ? (
    <AddNewDeliveryAddress
      getUserAddresses={() => {
        getUserDeliveryAddress();
        getUserPickupAddress();
      }}
      user_id={selectedUser.value}
      hideAddressForm={hideAddressForm}
    />
  ) : (
    <>
    {/* <ModalHeader>Create Order</ModalHeader> */}
      <Row>
        <Col style={{ margin: "10px", marginLeft: "600px" }}>
          <div className="text-sm-end">
            <Button
              type="button"
              color="success"
              className="btn-rounded mb-2 me-2"
              onClick={() => {
                setShowPickupAddressForm(true);
              }}
            >
              <i className="mdi mdi-plus me-1" /> Add Pickup Address
            </Button>
          </div>
        </Col>
        <Col style={{ margin: "10px" }}>
          <div className="text-sm-end">
            <Button
              type="button"
              color="success"
              className="btn-rounded mb-2 me-2"
              onClick={() => {
                setShowDeliveryAddressForm(true); 
              }}
            >
              <i className="mdi mdi-plus me-1" /> Add Delivery Address
            </Button>
          </div>
        </Col>
      </Row>

      <h5 style={{ marginTop: "10px" }}>Select User Delivery Address</h5>
      <div>
        <SearchableDropdown
          options={addresses}
          onSelect={handleSelectedAddress}
          defaultValue={selectedAddress}
        />
      </div>
      <h5 style={{ marginTop: "10px" }}>Select User Pickup Address</h5>
      <div>
        <SearchableDropdown
          options={pickupAddresses}
          onSelect={handleSelectedPickupAddress}
          defaultValue={selectedPickupAddress}
        />
      </div>
      <Row>
        <Col style={{ marginTop: "30px" }}>
          <Col>
            <div className="mb-3">
              <Label className="form-label" htmlFor="human_resource_charge">
                Shipment Type
              </Label>
              <DropDownComponent
                options={["AIR", "SHIP"]}
                name={"Select Shipment Type"}
                updateName={"shipping_type"}
                objectToBeUpdated={setShipmentType}
                defaultValue={shipmentType?.shipment_type}
              />
            </div>
          </Col>
        </Col>
        <Col style={{ marginTop: "30px" }}>
          <Col>
            <div className="mb-3">
              <Label className="form-label" htmlFor="human_resource_charge">
                Discount Type
              </Label>
              <DropDownComponent
                options={["FLAT", "PERCENTAGE"]}
                name={"Select Discount Type"}
                updateName={"discount_type"}
                objectToBeUpdated={setShipmentType}
                // defaultValue={shipmentType?.shipment_type}
              />
            </div>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="additional_discount">
              Additional Discount
            </Label>
            <Input
              id="additional_discount"
              name="additional_discount"
              type="number"
              value={additionalProperties?.additional_discount}
              className="form-control"
              onChange={(e) => {
                setAdditionalProperties((prev) => ({
                  ...(prev && { prev }),
                  additional_discount: parseFloat(e.target.value),
                }));
              }}
            />
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="insurance_value">
              Insurance Value
            </Label>
            <Input
              id="insurance_value"
              name="insurance_value"
              type="number"
              value={additionalProperties?.insurance_value}
              className="form-control"
              onChange={(e) => {
                setAdditionalProperties((prev) => ({
                  ...(prev && { prev }),
                  insurance_value: parseFloat(e.target.value),
                }));
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label" htmlFor="totalorder">
              Amount to be paid
            </Label>
            <Input
              id="totalorder"
              name="totalorder"
              type="text"
              value={
                calculateShipmentAmountTotal(
                  shipmentType?.shipping_type,
                  "SHIPMENT",
                  customPackageSize?.size_weight
                    ? customPackageSize?.size_weight
                    : selectedPackageSize?.size_weight
                ) || "0"
              }
              disabled={true}
              className="form-control"
            />
          </div>
        </Col>
        <Col></Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "50%",
          margin: "auto",
        }}
      >
        <Button
          style={{ marginTop: "30px" }}
          color="primary"
          className="primary"
          onClick={() => setCurrentStage((prev) => prev - 1)}
        >
          Previous
        </Button>
        <Button
          style={{ marginTop: "30px" }}
          color="primary"
          className="primary"
          disabled={
            !totalAmount ||
            !selectedAddress?.value ||
            !selectedPickupAddress.value
          }
          onClick={() => {
            createShipmentOrderForUser();
          }}
        >
          {currentStage === 2 ? `Create Order` : `Next`}
        </Button>
      </div>
    </>
  );
}

export default Stage2SelectUserAddress;
