import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import Switch from "react-switch";

import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import toastr from "toastr";

const VendorCoupons = () => {
  const [viewModal, setViewModal] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState({
    amount: "",
    type: "",
    min_order_amount: "",
    max_order_amount: "",
    user_usage_count: "",
    min_order_quantity: "",
    isPublic: "",
    expiration_date: "",
    coupon_name: "",
  });
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.userAuthInfo.user);
  const handleAddCouponModal = () => {
    setViewModal((prev) => !prev);
    setCoupon(null);
  };

  function Offsymbol() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        No
      </div>
    );
  }

  function OnSymbol() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        Yes
      </div>
    );
  }
  useEffect(() => {
    getCoupons();
  }, [user]);

  async function getCoupons() {
    try {
      const content = await post("/api/get-coupons", {
        vendor_id: user?.id,
      });
      setCoupons(content);
    } catch (e) {
      console.log(e);
    }
  }

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 4,
    totalSize: coupons.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };
  async function submitRequest() {
    try {
      setLoading(true);
      const content = await post("/api/create-coupon", {
        ...coupon,
        vendor_id: user?.id,
      });
      setLoading(false);
      getCoupons();
      handleAddCouponModal();
      toastr.success("Request created successfully!");
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Coupon Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Coupon Management", link: "/coupons" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={coupons}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={coupons}
                        columns={EcommerceOrderColumns(
                          getCoupons,
                          setCoupon,
                          setViewModal
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddCouponModal}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Make New
                                    Coupon
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <div className="table-responsive" style={{height:"500px"}}>
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="md" isOpen={viewModal} toggle={handleAddCouponModal}>
        <ModalHeader toggle={handleAddCouponModal}>
          {coupon?.id ? "Edit" : "New"} Coupon Form
        </ModalHeader>
        <ModalBody>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="couponcode">Coupon Code</Label>
                <Input
                  type="text"
                  id="couponcode"
                  placeholder="Enter Coupon Code"
                  value={coupon?.coupon_name}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      coupon_name: e.target.value,
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="minordervalue">Minimum Order Value</Label>
                <Input
                  type="number"
                  id="minordervalue"
                  placeholder="Enter Minimum Order Value"
                  value={coupon?.min_order_amount}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      min_order_amount: parseInt(e.target.value),
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="minordervalue">Maximum Order Value</Label>
                <Input
                  type="number"
                  id="maxordervalue"
                  placeholder="Enter Maximum Order Value"
                  value={coupon?.max_order_amount}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      max_order_amount: parseInt(e.target.value),
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="minordervalue">Minimum Order Quantity</Label>
                <Input
                  type="number"
                  id="minorderquantity"
                  placeholder="Enter Minimum Order Quantity"
                  value={coupon?.min_order_quantity}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      min_order_quantity: parseInt(e.target.value),
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="minordervalue">User Usage Count</Label>
                <Input
                  type="number"
                  id="usrusagecount"
                  placeholder="Enter User Usage Count"
                  value={coupon?.user_usage_count}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      user_usage_count: parseInt(e.target.value),
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="expiry">Expiration Date</Label>
                <DatePicker
                  selected={coupon?.expiration_date}
                  onChange={(date) => {
                    setCoupon((prev) => ({
                      ...prev,
                      expiration_date: date,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <Label for="percenttrue">Make Coupon Amount Percentage?</Label>
              <FormGroup>
                <Switch
                  id="percenttrue"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({ ...prev, type: !coupon?.type }))
                  }
                  checked={coupon?.type}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="couponamount">
                  Coupon {coupon?.type ? "Percentage" : "Amount"}
                </Label>
                <Input
                  type="number"
                  id="couponamount"
                  max={coupon?.type ? 100 : 100000}
                  placeholder={
                    coupon?.type ? "Enter Percentage" : "Enter Amount"
                  }
                  value={coupon?.amount}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      amount: parseInt(e.target.value),
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className="my-2">
            <Col md="12">
              <Label for="percenttrue">Make Coupon Public</Label>
              <FormGroup>
                <Switch
                  id="percenttrue"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({ ...prev, isPublic: !coupon?.isPublic }))
                  }
                  checked={coupon?.isPublic}
                />
              </FormGroup>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px auto",
            }}
          >
            <Button
              size="md"
              disabled={loading}
              onClick={submitRequest}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              {loading ? (
                <Spinner
                  style={{ width: "2rem", height: "2rem" }}
                  children={false}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default VendorCoupons;
