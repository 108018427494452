import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import EcomOrderAction from "./EcomOrderActionDropDown";
import jsPDF from "jspdf";

const generatePrintSlip = (row) => {
  const printWindow = window.open("", "", "width=1000,height=1000");
  printWindow.document.write(`
    <html>
    <head>
      <title>Order Print Slip</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 20px;
          color: #333;
        }
        .header, .return-info {
          margin-bottom: 20px;
        }
        .header h2 {
          text-align: left;
          margin-bottom: 5px;
        }
        .header, .address-section {
          display: flex;
          justify-content: space-between;
        }
        .address-section div {
          width: 48%;
        }
        .address-section {
          margin-bottom: 20px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 10px;
          text-align: left;
        }
        th {
          background-color: #f2f2f2;
        }
        .totals-section {
          margin-top: 20px;
        }
        .totals-section table {
          width: 100%;
        }
        .totals-section th, .totals-section td {
          border: none;
        }
        .totals-section th {
          text-align: right;
        }
        .totals-section td {
          text-align: right;
          padding-right: 20px;
        }
        .totals-section .total {
          font-size: 16px;
          font-weight: bold;
        }
      </style>
    </head>
    <body>
      <div class="header">
        <div>
          <h2>Order ID: ${row?.display_id}</h2>
          <p>Thank you for buying from us on <strong>Marketplace</strong>.</p>
        </div>
        <div style="text-align: right;">
          <p><strong>Date:</strong> ${row?.createdAt?.split("T")[0]}</p>
        </div>
      </div>

      <div class="address-section">
        <div>
          <h4>Ship To:</h4>
          <p>
            ${row?.user?.first_name} ${row?.user?.last_name}<br>
            ${row?.ecommerce_order?.delivery_address?.address} <br>
            ${row?.ecommerce_order?.delivery_address.city}, ${row?.ecommerce_order.delivery_address.state} ${row?.ecommerce_order.delivery_address.zipcode}<br>
            ${row?.ecommerce_order.delivery_address.country}
          </p>
        </div>
        <div>
          <h4>Shipping Details</h4>
          <p><strong>Shipping Type:</strong> ${row?.shipment_type}</p>
        </div>
      </div>

      <h3>Product Details</h3>
      <table>
        <thead>
          <tr>
            <th>Qty Ordered</th>
            <th>Product Details</th>
            <th>Unit Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${1}</td>
            <td>
             Product Name : ${row?.product?.name} <br>
              Height: ${row?.product?.height} <br>
              Width : ${row.product.width} <br>
             
            </td>
            <td>$${row?.product.MRP}</td>
          </tr>
        </tbody>
      </table>

      <div class="totals-section">
        <table>
          <tr>
            <th>Sub Total:</th>
            <td>$${row?.ecommerce_order?.sub_total}</td>
          </tr>
          <tr>
            <th>Shipping:</th>
            <td>$${row?.ecommerce_order?.shipping_cost}</td>
          </tr>
          <tr class="total">
            <th>Total:</th>
            <td>$${row?.ecommerce_order?.grand_total}</td>
          </tr>
        </table>
      </div>

      <div class="return-info">
        <h4>Returning your item:</h4>
        <p>Go to "Your Account" on our website, click "Your Orders," and then find the "seller profile" link to get more information about the return policy and instructions.</p>
        <p>Visit our website for further return information.</p>
      </div>
    </body>
    </html>
  `);
  printWindow.document.close();
  printWindow.print();
};

const downloadPrintSlip = (row) => {
  const doc = new jsPDF();

  const content = `
    Order ID: ${row?.display_id}
    Thank you for buying from us on Marketplace.
    
    Date: ${row?.createdAt?.split("T")[0]}
    
    Ship To:
    ${row?.user?.first_name} ${row?.user?.last_name}
    ${row?.ecommerce_order?.delivery_address?.address}
    ${row?.ecommerce_order?.delivery_address.city}, ${
    row?.ecommerce_order.delivery_address.state
  } ${row?.ecommerce_order.delivery_address.zipcode}
    ${row?.ecommerce_order.delivery_address.country}
    
    Shipping Details:
    Shipping Type: ${row?.shipment_type}
    
    Product Details:
    Qty Ordered: 1
    Product Name: ${row?.product?.name}
    Height: ${row?.product?.height}
    Width: ${row.product.width}
    Unit Price: $${row?.product.MRP}
    
    Totals:
    Sub Total: $${row?.ecommerce_order?.sub_total}
    Shipping: $${row?.ecommerce_order?.shipping_cost}
    Total: $${row?.ecommerce_order?.grand_total}
    
    Returning your item:
    Go to "Your Account" on our website, click "Your Orders," and then find the "seller profile" link to get more information about the return policy and instructions.
  `;

  doc.text(content, 10, 10);
  doc.save(`print_slip.pdf`);
};

const EcommerceOrderColumns = (
  getAllVendorRequests,
  setDocuments,
  handleShowImageModal,
  count,
  activeTab
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "date",
    text: "Date",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.createdAt.split("T")[0] || ""}
      </Link>
    ),
  },
  {
    dataField: "orderId",
    text: "Order Id",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.display_id || ""}
      </Link>
    ),
  },
  {
    dataField: "productname",
    text: "Product Name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.product.name || ""}
      </Link>
    ),
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.category.name || ""}
      </Link>
    ),
  },
  {
    dataField: "sub-category",
    text: "Sub Category",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.sub_category.name || ""}
      </Link>
    ),
  },
  {
    dataField: "weight",
    text: "Weight",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.product?.weight ? `${row?.product?.weight} KG` : "-"}
      </Link>
    ),
  },
  {
    dataField: "height",
    text: "Height",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.product?.height ? `${row?.product?.height} CM` : "-"}
      </Link>
    ),
  },
  {
    dataField: "width",
    text: "Width",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.product?.width ? `${row?.product?.width} CM` : "-"}
      </Link>
    ),
  },
  {
    dataField: "shipmenttype",
    text: "Shipment Type",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.shipment_type || ""}
      </Link>
    ),
  },
  {
    dataField: "OrderAmount",
    text: "Order Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.ecommerce_order?.grand_total
          ? `$${row.ecommerce_order.grand_total}`
          : "-"}
      </Link>
    ),
  },
  {
    dataField: "productamount",
    text: "Product Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.ecommerce_order?.sub_total
          ? `$${row?.ecommerce_order?.sub_total}`
          : "-"}
      </Link>
    ),
  },
  {
    dataField: "shipmentamount",
    text: "Shipment Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.ecommerce_order?.shipping_cost
          ? `$${row.ecommerce_order.shipping_cost}`
          : "-"}
      </Link>
    ),
  },
  ...(activeTab !== "DELIVERED" &&
  activeTab !== "READY_TO_DISPATCH" &&
  activeTab !== "PROCESSING" &&
  activeTab !== "RECEIVED" &&
  activeTab !== "SHIPPED" &&
  activeTab !== "REACHED_TO_LOCAL_PARTNER" &&
  activeTab !== "HOLD" &&
  activeTab !== "CANCELLED"
    ? [
        {
          dataField: "printslip",
          text: "Print slip",
          sort: true,
          formatter: (cellContent, row) => (
            <Button color="primary" onClick={() => generatePrintSlip(row)}>
              Print Slip
            </Button>
          ),
        },
      ]
    : []),

  ...(activeTab !== "DELIVERED" &&
  activeTab !== "READY_TO_DISPATCH" &&
  activeTab !== "DELIVERED" &&
  activeTab !== "HOLD" &&
  activeTab !== "CANCELLED" &&
  activeTab !== "REACHED_TO_LOCAL_PARTNER" &&
  activeTab !== "SHIPPED" &&
  activeTab !== "RECEIVED" &&
  activeTab !== "PROCESSING"
    ? [
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",

          formatter: (cellContent, row) => (
            <EcomOrderAction
              count={count}
              row={row}
              fetchDataAgain={getAllVendorRequests}
            />
          ),
        },
      ]
    : []),
];

export default EcommerceOrderColumns;
