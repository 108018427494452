import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import EcomOrderAction from "../../components/EcomOrderAction";

const EcommerceOrderColumnDefinitions = (
  getAllVendorRequests,
  setDocuments,
  handleShowDifferenceModal,
  setSelectedOrderForEmail,
  toggleModal,
  setEmail,
  count
) => [
  {
    text: "ID",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "user.date",
    text: "Date",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.createdAt.split("T")[0] || ""}
      </Link>
    ),
  },
  {
    dataField: "user.orderid",
    text: "Order ID",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.display_id || ""}
      </Link>
    ),
  },
  {
    dataField: "user.fullname",
    text: "Full Name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {`${row?.user?.first_name || ""} ${row?.user?.last_name || ""}`}
      </Link>
    ),
  },
  {
    dataField: "user.email",
    text: "Mobile Number",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {`${row.user.country_code}-${row?.user?.mobilenumber || ""}`}
      </Link>
    ),
  },
  {
    dataField: "user.email",
    text: "User Email",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.user?.email || ""}
      </Link>
    ),
  },
  {
    dataField: "user.shipmenttype",
    text: "Shipment Type",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.shipment_type || ""}
      </Link>
    ),
  },
  {
    dataField: "user.orderamount",
    text: "Order Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.grand_total ? `$${row.grand_total}` : "-"}
      </Link>
    ),
  },
  {
    dataField: "user.productamount",
    text: "Product Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.unit_price ? `$${row.unit_price}` : "-"}
      </Link>
    ),
  },
  {
    dataField: "user.shipmentamount",
    text: "Shipment Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.shipping_cost ? `$${row.shipping_cost.toFixed(2)}` : "-"}
      </Link>
    ),
  },
  {
    dataField: "handleDifference",
    text: "Handle Difference",
    formatter: (cellContent, row) => (
      <Button
        color="primary"
        onClick={() => {
          setDocuments(row);
          handleShowDifferenceModal();
        }}
      >
        <i className="mdi mdi-cog font-size-18"></i>
      </Button>
    ),
  },
  {
    dataField: "user.email",
    text: "Send Mail",
    formatter: (cellContent, row) => (
      <Button
        color="primary"
        onClick={() => {
          setEmail(row);
          setSelectedOrderForEmail(row);
          toggleModal();
        }}
      >
        <i className="mdi mdi-email-send font-size-18"></i>
      </Button>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Update Status",
    formatter: (cellContent, row) => (
      <EcomOrderAction
        count={count}
        row={row}
        fetchDataAgain={getAllVendorRequests}
        isAdmin={true}
      />
    ),
  },
];

export default EcommerceOrderColumnDefinitions;
