import React from "react"
import { CardTitle, Col, Row } from "reactstrap";

function LinkWithText({title,functionToCall,heading,showLink}){

    return (
        <Row className="mb-2">
        <Col sm="4">
          <div className="search-box me-2 mb-2 d-inline-block">
            <div className="position-relative" onClick={() => {}}>
              <CardTitle className="h5">{heading}</CardTitle>
            </div>
          </div>
        </Col>
        {showLink?<Col sm="8">
          <div className="text-sm-end">
            <a
              type="button"
              color="success"
              className="btn-rounded mb-2 me-2"
              onClick={(e) => {
                e.preventDefault();
                functionToCall();
              }}
            >
              <i className="mdi mdi-plus me-1" /> {title}
            </a>
          </div>
        </Col>:null}
      </Row>
    )
}

export default LinkWithText