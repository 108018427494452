import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import ActionDropdown from "../../components/ActionDropdown";

const EcommerceOrderColumns = (
  getAllVendorRequests,
  setDocuments,
  handleShowImageModal,
  activeTab
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "vendor.gstin",
    text: "GST",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.vendor.gstin}
      </Link>
    ),
  },
  {
    dataField: "full name",
    text: "Full Name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.vendor.username}
      </Link>
    ),
  },
  {
    dataField: "vendor.email",
    text: "Email",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.vendor.email}
      </Link>
    ),
  },
  {
    dataField: "vendor.mobilenumber",
    text: "Mobile Number",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.vendor.mobilenumber}
      </Link>
    ),
  },
  {
    dataField: "vendor.businessName",
    text: "Business",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.vendor.businessName}
      </Link>
    ),
  },
  activeTab === "ALL" && {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row?.status === "ACTIVE" || row?.status === "PENDING"
            ? "success"
            : "danger")
        }
        color={row?.status === "BLOCKED" ? "success" : "danger"}
        pill
      >
        {row?.status}
      </Badge>
    ),
  },

  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <ActionDropdown row={row} getAllVendorRequests={getAllVendorRequests} />
    ),
  },
  {
    dataField: "documents",
    isDummyField: true,
    text: "Vendor Documents",
    formatter: (cellContent, row) => (
      <Button
        onClick={() => {
          setDocuments(row);
          handleShowImageModal();
        }}
        color="primary"
        className="primary"
      >
        Documents
      </Button>
    ),
  },
];

export default EcommerceOrderColumns;
