import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import MasterForm from "../../components/VendorRegistration/MasterForm";

const EcomChildCategories = () => {
  const navigation = useHistory();
  const [viewModal, setViewModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const dispatch = useDispatch();
  const handleAddVendorModal = () => {
    setViewModal((prev) => !prev);
  };
  const handleShowImageModal = () => {
    setShowImageModal((prev) => !prev);
  };

  useEffect(() => {
    getAllCategories();
  }, []);
  async function getAllCategories() {
    try {
      const content = await post("/api/ecom/get-child-categories", {});
      setOrders(content);
    } catch (e) {
      console.log(e);
    }
  }
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: orders?.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Child Category Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Child Categories", link: "/child-categories" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          getAllCategories,
                          orders
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <a
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigation.push(
                                        "/add-new-ecom-child-category"
                                      );
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Add New Child Category
                                  </a>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ minHeight: "200px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={viewModal} toggle={handleAddVendorModal}>
        <ModalHeader toggle={handleAddVendorModal}>Add New Vendor</ModalHeader>
        <ModalBody>
          <MasterForm
            isAdmin={true}
            handleAddVendorModal={handleAddVendorModal}
            getAllVendorRequests={getAllCategories}
          />
        </ModalBody>
      </Modal>
      <Modal size="xl" isOpen={showImageModal} toggle={handleShowImageModal}>
        <ModalHeader toggle={handleShowImageModal}>
          Vendor Documents
        </ModalHeader>
        <ModalBody>
          <div>
            {documents?.vendor?.panFilePath ? (
              <>
                <img
                  src={`${
                    process.env.REACT_APP_AUTHDOMAIN
                  }/api/${documents?.vendor?.panFilePath?.replace(/\\/g, "/")}`}
                  width="200px"
                />
                <Button
                  style={{ marginLeft: "20px" }}
                  onClick={() =>
                    handleDownload(
                      `${
                        process.env.REACT_APP_AUTHDOMAIN
                      }/api/${documents?.vendor?.panFilePath?.replace(
                        /\\/g,
                        "/"
                      )}`
                    )
                  }
                >
                  Download
                </Button>
              </>
            ) : null}
          </div>
          <div style={{ marginTop: "20px" }}>
            {documents?.vendor?.aadharFilePath ? (
              <>
                <img
                  src={`${
                    process.env.REACT_APP_AUTHDOMAIN
                  }/api/${documents?.vendor?.aadharFilePath?.replace(
                    /\\/g,
                    "/"
                  )}`}
                  width="200px"
                />
                <Button
                  style={{ marginLeft: "20px" }}
                  onClick={() =>
                    handleDownload(
                      `${
                        process.env.REACT_APP_AUTHDOMAIN
                      }/api/${documents?.vendor?.aadharFilePath?.replace(
                        /\\/g,
                        "/"
                      )}`
                    )
                  }
                >
                  Download
                </Button>
              </>
            ) : null}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EcomChildCategories;
