import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import CategoryActionDropdown from "../../components/EcomCategoryActionDropDown";
import EcomCommissionActionDropdown from "./EcomCommissionActionDropDown";

const EcommerceOrderColumns = (getAllVendorRequests, categories) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "name",
    text: "Category name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.name}
      </Link>
    ),
  },
  {
    dataField: "commission",
    text: "Category Commission",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {`${row.ecom_category_commission}%`}
      </Link>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <EcomCommissionActionDropdown
        row={row}
        getAllVendorRequests={getAllVendorRequests}
        categories={categories}
        isEcom={true}
      />
    ),
  },
];

export default EcommerceOrderColumns;
