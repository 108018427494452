import React from 'react'

const ContainerOrders = () => {
  return (
    <>
      <div>ContainerOrders</div>
      <div>ContainerOrders</div>
      <div>ContainerOrders</div>
      <div>ContainerOrders</div>
      <div>ContainerOrders</div>
      <div>ContainerOrders</div>
    </>
  );
}

export default ContainerOrders