import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";

const Step2 = props => {
  if (props.currentStep !== 2) {
    return null;
  }

  return (
    <>
      <h4 style={{ fontWeight: 600 }}>Bank Details</h4>

      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="bankname">Bank Name</Label>
            <Input
              type="text"
              name="bankname"
              id="bankname"
              placeholder="Enter bank name"
              value={props.vendorDetails.bankname}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="accountholdername">Account Holder Name</Label>
            <Input
              type="text"
              name="accountholdername"
              id="accountholdername"
              placeholder="Enter account holder name"
              value={props.vendorDetails.accountholdername}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="accountnumber">Account Number</Label>
            <Input
              type="number"
              name="accountnumber"
              id="accountnumber"
              placeholder="Enter account number"
              value={props.vendorDetails.accountnumber}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="ifsc">IFSC code</Label>
            <Input
              type="text"
              name="ifsc"
              id="ifsc"
              placeholder="Enter ifsc"
              value={props.vendorDetails.ifsc}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Step2;
