import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import OrderActionDropDOwn from "../../components/OrderActionDropdown";

const EcommerceOrderColumns = (
  getAllVendorRequests,
  getAllMarketplaceOrders,
  setDocuments,
  handleShowImageModal,
  // resendPaymentLink,
  handleShowDifferenceModal,
  setSelectedOrderForEmail,
  toggleModal,
  setShowReasonModal,
  differenceData,
  getEmailFromAction,
  getHandleDifferenceData,
  activeTab,
  getOrderDetails,
  typePass,
  shipmentType
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "createdAt",
    text: "Date",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {new Date(row?.createdAt)?.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }) || ""}
      </Link>
    ),
  },
  {
    text: "Order ID",
    dataField: "Order ID",
    sort: true,
    formatter: (cellContent, row) => <>{row?.display_id}</>,
  },
  {
    dataField: "user.first_name",
    text: "Full Name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {`${row?.user?.first_name || ""} ${row?.user?.last_name || ""}`}
      </Link>
    ),
  },
  {
    dataField: "user.mobilenumber",
    text: "User Mobile",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.user?.mobilenumber == null
          ? "Gmail"
          : row.user.country_code + "-" + row?.user?.mobilenumber}
      </Link>
    ),
  },
  {
    dataField: "user.email",
    text: "User Email",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.user?.email || ""}
      </Link>
    ),
  },
  {
    dataField: "shipment_type",
    text: "Shipment Type",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.shipment_type === "AIR" ? "success" : "warning")
        }
        color={row.shipment_type === "AIR" ? "success" : "warning"}
        pill
      >
        {row.shipment_type}
      </Badge>
    ),
  },
  typePass !== "MARKETPLACE" && {
    dataField: "package_size.size_name",
    text: "Shipment Size",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.package_size?.size_name || "-"}
      </Link>
    ),
  },
  typePass !== "MARKETPLACE" && {
    dataField: "insurance",
    text: "Insurance",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.insuranceCharges ? "Yes" : "No"}
      </Link>
    ),
  },
  typePass !== "MARKETPLACE" && {
    dataField: "insurance value",
    text: "Insurance value",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.insuranceCharges ? `$${row.insuranceCharges}` : "-"}
      </Link>
    ),
  },
  {
    dataField: "grandTotal",
    text: "Order Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {typePass === "MARKETPLACE"
          ? `$${parseFloat(row?.ecommerce_order?.grand_total)}`
          : `$${parseFloat(row?.grandTotal - row?.coupon_discount)?.toFixed(
              2
            )}`}
      </Link>
    ),
  },
  {
    dataField: "Info",
    isDummyField: true,
    text: "Order Details",
    formatter: (cellContent, row) =>
      row.is_created_by_admin ? null : (
        <Button
          color="primary"
          className="primary"
          onClick={() => {
            getHandleDifferenceData(row, "orderDetail");
            handleShowImageModal();
            // setDocuments(row);
          }}
        >
          <i className="mdi mdi-file-document-outline font-size-18"></i>
        </Button>
      ),
  },
  ...(activeTab !== "DELIVERED" && activeTab !== "CANCELLED"
    ? [
        {
          dataField: "Handle Difference",
          isDummyField: true,
          text: "Handle Difference",
          formatter: (cellContent, row) => (
            <Button
              color="primary"
              className="primary"
              onClick={() => {
                differenceData(row);
                handleShowDifferenceModal();
                getHandleDifferenceData(row);
                // setDocuments(row);
              }}
            >
              <i className="mdi mdi-cog font-size-18"></i>
            </Button>
          ),
        },
      ]
    : []),
  {
    dataField: "Send Email",
    isDummyField: true,
    text: "Send Email",
    formatter: (cellContent, row) => (
      <Button
        color="primary"
        className="primary"
        onClick={() => {
          setSelectedOrderForEmail(row);
          toggleModal();
          getEmailFromAction(row);
        }}
      >
        <i className="mdi mdi-email-send font-size-18"></i>
      </Button>
    ),
  },
  ...(activeTab !== "DELIVERED" && activeTab !== "CANCELLED"
    ? [
        {
          dataField: "action",
          isDummyField: true,
          text: "Update Status",
          formatter: (cellContent, row) => (
            <OrderActionDropDOwn
              row={row}
              fetchDataAgain={getAllVendorRequests}
              fetchData={getAllMarketplaceOrders}
              shipmentType={typePass}
              activeTab={activeTab}
            />
          ),
        },
      ]
    : []),
];

export default EcommerceOrderColumns;
