import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import toastr from "toastr";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useSelector } from "react-redux";
const UpdateCommission = () => {
  const selectedCategoryForUpdate = useSelector(
    (state) => state.userAuthInfo.selectedCategoryForUpdate
  );
  let id = selectedCategoryForUpdate?.row
  const matchedCategory = selectedCategoryForUpdate?.categories?.find(
    (category) => category.id === selectedCategoryForUpdate?.row
  );
  
  const [categoryInfo, setCategoryInfo] = useState({
    name: selectedCategoryForUpdate?.row?.name || "",
    description: selectedCategoryForUpdate?.row?.description || "",
    slug: selectedCategoryForUpdate?.row?.slug || "",
    ecom_category_commission: matchedCategory?.ecom_category_commission || ""
  });
  const navigation = useHistory();
  const [loading, setLoading] = useState(false);
  const breadcrumbItems = [
    { title: "Dashboard", link: "/" },
    { title: "Categories", link: "/categories" },
    { title: "Add New Category", link: "#" },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCategoryInfo((prevState) => ({
      ...prevState,
      [name]: name === "ecom_category_commission" ? parseFloat(value) : value,
    }));
  };
  
  const handleSubmit = async (event, values) => {
    try {
      setLoading(true);
        let errorMessage = "Please fill in the following fields:\n";
       
        if (categoryInfo.ecom_category_commission === "") {
          errorMessage += "- Commission\n";

        alert(errorMessage);

        setLoading(false);
      } else {
        const formData = new FormData();
        formData.append(
          "ecom_category_commission",
          categoryInfo.ecom_category_commission
        );
        formData.append('id',id)
         const body = {
           ecom_category_commission: categoryInfo.ecom_category_commission,
           id: id, 
         };
         const config = {
           method: "POST",
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(body),
         };
        let responseObj = await fetch(
          `${process.env.REACT_APP_AUTHDOMAIN}/api/ecom/commission-update`,
          config
        );
        const res = await responseObj.json();
        if (res?.message) {
          toastr.error(res?.message, "Error");
          setLoading(false);
          return;
        }
        navigation.push("/handle-vendor-commission");
        setLoading(false);
      }
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      setLoading(false);
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Commission Management"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div
                    id="addproduct-nav-pills-wizard"
                    className="twitter-bs-wizard"
                  >
                    <TabContent
                      activeTab={1}
                      className="twitter-bs-wizard-tab-content"
                    >
                      <TabPane tabId={1}>
                        <CardTitle className="h5">Basic Information</CardTitle>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="ecom_category_commission"
                          >
                            Commission
                          </Label>
                          <Input
                            id="ecom_category_commission"
                            value={categoryInfo?.ecom_category_commission}
                            onChange={handleChange}
                            name="ecom_category_commission"
                            type="number"
                            className="form-control"
                            max="100"
                            onInput={(e) => {
                              if (e.target.value > 100) {
                                e.target.value = 100;
                              }
                            }}
                          />
                          <p style={{ paddingTop: "8px" }}>
                            <b>Note:</b> Commission is calculated based on a
                            percentage of the total amount.
                          </p>
                        </div>
                      </TabPane>
                    </TabContent>
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li className={"next"} onClick={handleSubmit}>
                        {loading ? (
                          <Spinner
                            style={{ width: "2rem", height: "2rem" }}
                            children={false}
                          />
                        ) : (
                          <Link to="#">Save</Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UpdateCommission;
