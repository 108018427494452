import React from "react";
import { Container, Row, Col, CardBody, Card, Table } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "./MiniWidgets";
import { get, post } from "../../helpers/api_helper";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
const VendorDashboard = () => {
  const [data, setData] = React.useState({data:[]});
  const [selectedDate, setSelectedDate] = React.useState(null);
  const breadcrumbItems = [
    { title: "Shipora", link: "/" },
    { title: "Dashboard", link: "#" },
  ];
 const increasedDate = selectedDate ? new Date(selectedDate) : null;
 if (increasedDate) {
   increasedDate.setDate(increasedDate.getDate() + 1);
 }
 const clearDate = () =>{
  setSelectedDate(null)
 }
  const formattedDate = increasedDate?.toISOString()?.split("T")[0];
  
  const user = useSelector((state) => state.userAuthInfo.user);
  React.useEffect(() => {
    getDashboardInfo();
  }, [user, selectedDate]);
  async function getDashboardInfo() {
    try {
      const data = await post(`/api/vendor/dashboard/${user?.id}`, {date: formattedDate});
      setData(data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col></Col>
            <Col md={2} className="text-right mb-3">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="Select Date"
              />
            </Col>
            {selectedDate != null && (
              <Col sm="1" className="d-inline-block">
                <button onClick={clearDate} className="btn btn-primary">
                  Clear
                </button>
              </Col>
            )}
          </Row>
          <Row>
            <Col xl={12}>
              <Row>
                <MiniWidgets reports={data?.data} />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <div className=" overflow-hidden">
                      <h4 className="mb-2">Top Categories - Items Sold</h4>
                    </div>
                    <Table>
                      <tr>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Category
                        </th>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Items Sold
                        </th>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Net Sales
                        </th>
                      </tr>
                      {data?.categoriesWithProductSold &&
                        data?.categoriesWithProductSold?.map((item) => {
                          return (
                            <tr>
                              <td>{item?.name}</td>
                              <td>{item?.ecommerce_order_items?.length}</td>
                              <td>
                                ${" "}{item?.ecommerce_order_items?.reduce((acc, curr) => { acc += curr.grand_total; return acc;},0)}
                              </td>
                            </tr>
                          );
                        })}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <div className=" overflow-hidden">
                      <h4 className="mb-2">Top Products Sold</h4>
                    </div>
                    <Table>
                      <tr>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Product
                        </th>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Items Sold
                        </th>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Net Sales
                        </th>
                      </tr>
                      {data?.topProductCount &&
                        data?.topProductCount?.map((item) => {
                          return (
                            <tr>
                              <td>{item?.name}</td>
                              <td>{item?.ecommerce_ordered_item?.length}</td>
                              <td>
                                ${" "}{item?.ecommerce_ordered_item?.reduce((acc, curr) => {acc += curr.grand_total; return acc;},0)}
                              </td>
                            </tr>
                          );
                        })}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VendorDashboard;
