import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";

const Step1 = ({
  currentStep,
  handleChange,
  vendorDetails,
  states,
}) => {
  if (currentStep !== 1) {
    return null;
  }

  return (
    <>
      <h4 style={{ fontWeight: 600 }}>Basic Details</h4>
      <FormGroup tag="fieldset">
        <Label>Account type</Label>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              checked={vendorDetails.account_type === "INDIVIDUAL"}
              name="account_type"
              value="INDIVIDUAL"
              onChange={handleChange}
            />
            Individual Seller
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              checked={vendorDetails.account_type === "PROFESSIONAL"}
              name="account_type"
              value="PROFESSIONAL"
              onChange={handleChange}
            />
            Professional Seller (Recommended for higher sales volume)
          </Label>
        </FormGroup>
      </FormGroup>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="businessname">Legal Business Name</Label>
            <Input
              type="text"
              name="businessname"
              id="businessname"
              placeholder="Enter business name"
              value={vendorDetails.businessname}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="businessaddress">Business Address</Label>
            <Input
              type="text"
              name="businessaddress"
              id="businessaddress"
              placeholder="Enter business address"
              value={vendorDetails.businessaddress}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="state_id">State</Label>
            <Input
              type="select"
              name="state_id"
              id="state_id"
              value={vendorDetails.state_id}
              onChange={handleChange}
            >
              <option value={""}>Select state</option>
              {states.map((state) => {
                return (
                  <option key={state.id} value={state.id}>
                    {state?.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="city">City</Label>
            <Input
              type="text"
              name="city"
              id="city"
              placeholder="Enter city"
              value={vendorDetails.city}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="zip">Zip</Label>
            <Input
              type="text"
              name="zip"
              id="zip"
              placeholder="Enter zipcode"
              value={vendorDetails.zip}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="mobilenumber">Contact Number</Label>
            <Input
              type="number"
              name="mobilenumber"
              id="mobilenumber"
              placeholder="Enter contact number"
              value={vendorDetails.mobilenumber}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="gst">
              Goods and Services Tax (GST) Identification Number
            </Label>
            <Input
              type="text"
              name="gst"
              id="gst"
              placeholder="Enter Goods and Services Tax (GST) Identification Number"
              value={vendorDetails.gst}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              value={vendorDetails.email}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="username">Username</Label>
            <Input
              type="text"
              name="username"
              id="username"
              placeholder="Enter username"
              value={vendorDetails.username}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Enter your password"
              value={vendorDetails.password}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Step1;
