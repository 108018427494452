// import React, { useState } from "react";
// import Select from "react-select";

// const SearchableDropdownProducts = ({ options, onSelect, defaultValue }) => {
//   const [selectedOption, setSelectedOption] = useState(
//     defaultValue ? defaultValue : null
//   );

//   const handleChange = (selectedOption) => {
//     setSelectedOption(selectedOption);
//     onSelect(selectedOption);
//   };
  
//   React.useEffect(() => {
//       const foundItem = options?.find((el) => el?.value === defaultValue);
//       if(foundItem){
//           setSelectedOption(foundItem);
//           onSelect(foundItem);
//         }
    
//   }, [options,defaultValue]);

//   return (
//     <>
//     <Select
//       value={selectedOption}
//       onChange={handleChange}
//       options={options}
//       isSearchable={true}
//       placeholder="Select an option..."
//       />
//       </>
//   );
// };

// export default SearchableDropdownProducts;

import React, { useState, useEffect } from "react";
import Select from "react-select";

const SearchableDropdownProducts = ({ options, onSelect, defaultValue }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      const foundItem = options?.find((el) => el?.value === defaultValue);
      setSelectedOption(foundItem || null);
    } else {
      setSelectedOption(null);
    }
  }, [defaultValue, options]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelect(selectedOption);
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      isSearchable={true}
      placeholder="Select an option..."
    />
  );
};

export default SearchableDropdownProducts;
