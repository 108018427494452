export function getAddressLabelInfo(addressObject){

    return `
    ${addressObject.first_name} ${addressObject.last_name}, 
    ${
      addressObject.address ? `${addressObject.address}, ` : ""
    }${addressObject.landmark ? `${addressObject.landmark}, ` : ""}${
      addressObject.area_name ? `${addressObject.area_name}, ` : ""
    }
    ${addressObject.city}, ${addressObject.state} ${
      addressObject.zipcode
    }
    ${addressObject.country}
    Mobile: ${addressObject.mobile_number_country_code} ${
      addressObject.mobile_number
    }
    `
}