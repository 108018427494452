import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import toastr from "toastr";

const NotesImageModal = ({
  isOpen,
  toggle,
  action,
  requestId,
  getWithdrawalRequests,
}) => {
  const [notes, setNotes] = useState("");
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

const handleSubmit = async () => {
  const formData = new FormData();
  formData.append("notes", notes);
  formData.append("image", image);
  formData.append("status", action);
  formData.append("requestId", requestId);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_AUTHDOMAIN}/api/withdrawal-update`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    getWithdrawalRequests();
    toastr.success("Request updated!");
    toggle();
  } catch (error) {
    console.error("Submit error:", error);
    toastr.error("Failed to update request.");
  }
};
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Update Request</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="notes">Notes</Label>
          <Input
            type="textarea"
            id="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="image" style={{paddingTop : "10px"}}>Upload Image (Optional)</Label><br/>
          <Input type="file" id="image" onChange={handleImageChange} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NotesImageModal;
