import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useSelector } from "react-redux";
import { put, post } from "../helpers/api_helper";
import toastr from "toastr";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";

function EcomOrderAction({ row, fetchDataAgain, count, isAdmin }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const activeTab = useSelector((state) => state.Layout.activeEcommerceTab);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);

    if (item === "HOLD" || item === "CANCELLED") {
      toggleReasonModal();
    } else {
      updateVendorRequestStatus(item);
    }
  };

  const toggleReasonModal = () => setReasonModalOpen(!reasonModalOpen);

  const handleSubmitReason = async () => {
    try {
      await post("/api/ecom/add-cancel-reason", {
        reason,
        id: row.id,
      });
      await updateVendorRequestStatus(selectedItem);
      toggleReasonModal();
      setReason(""); 
    } catch (e) {
      toastr.error("Failed to add reason", "Error");
      console.log(e);
    }
  };

  async function updateVendorRequestStatus(status) {
    try {
      await put(`/api/ecom/update-ecom-order/vendor/${row.id}`, {
        status: status,
      });
      toastr.success("Status updated!", "Success");
      fetchDataAgain();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }

  const orderStatus = [
    "PLACED",
    "READY_TO_DISPATCH",
    "PROCESSING",
    "RECEIVED",
    "SHIPPED",
    "REACHED_TO_LOCAL_PARTNER",
    "HOLD",
    "CANCELLED",
    "DELIVERED",
  ];

  const statusIcons = {
    PLACED: "mdi mdi-check",
    READY_TO_DISPATCH: "mdi mdi-truck-delivery",
    PROCESSING: "mdi mdi-cog-outline",
    RECEIVED: "mdi mdi-package-variant",
    SHIPPED: "mdi mdi-truck",
    REACHED_TO_LOCAL_PARTNER: "mdi mdi-map-marker-check",
    CANCELLED: "mdi mdi-close-circle",
    HOLD: "mdi mdi-pause-circle",
    DELIVERED: "mdi mdi-check-all",
  };

  const currentStatusIndex = orderStatus.indexOf(activeTab);
  const availableStatuses = orderStatus.slice(currentStatusIndex + 1);

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        direction={count.length <= 4 ? "up" : "down"}
        toggle={toggleDropdown}
        color="#a8d1df"
      >
        <DropdownToggle color="primary" className="primary" caret>
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>
        <DropdownMenu>
          {availableStatuses.map((status) => (
            <DropdownItem key={status} onClick={() => handleItemClick(status)}>
              <i className={`${statusIcons[status]} font-size-18 me-2`}></i>
              {status.replace(/_/g, " ")}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>

      <Modal
        isOpen={reasonModalOpen}
        toggle={toggleReasonModal}
        style={{ maxWidth: "600px", width: "100%", height: "100%" }}
      >
        <ModalHeader toggle={toggleReasonModal}>Enter Reason</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Enter reason for cancellation/hold"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmitReason}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
export default EcomOrderAction;