import React from "react";
import { Table, Input } from "reactstrap";

const ShipmentOrdersTable = ({ orders, selectedOrders, onOrderSelection }) => {
  const toggleOrderSelection = (orderId) => {
    onOrderSelection((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const toggleAllOrders = () => {
    onOrderSelection(
      selectedOrders.length === orders.length
        ? []
        : orders.map((order) => order.id)
    );
  };
  const mergedOrders = orders.map((order) => ({
    ...order,
    fullname: `${order.user.first_name} ${order.user.last_name}`,
  }));

  return (
    <Table responsive striped>
      <thead>
        <tr>
          <th>
            <Input
              type="checkbox"
              onChange={toggleAllOrders}
              checked={
                selectedOrders.length === orders.length && orders.length > 0
              }
            />
          </th>
          <th>Date</th>
          <th>Order Type</th>
          <th>Order ID</th>
          <th>Shipment Type</th>
          <th>Full Name</th>
          <th>Mobile Number</th>
          <th>Email</th>
          <th>Order Amount</th>
          <th>Insurance Charges</th>
        </tr>
      </thead>
      <tbody>
        {mergedOrders.map((order) => (
          <tr key={order.id}>
            <td>
              <Input
                type="checkbox"
                onChange={() => toggleOrderSelection(order.id)}
                checked={selectedOrders.includes(order.id)}
              />
            </td>
            <td>{new Date(order.createdAt).toLocaleString().split(",")[0]}</td>
            <td>{order.order_type}</td>
            <td>{order.display_id}</td>
            <td>{order.shipment_type}</td>
            <td>{order.fullname}</td>
            <td>{order.user.mobilenumber}</td>
            <td>{order.user.email}</td>
            <td>{order.grandTotal}</td>
            <td>{order.insuranceCharges}</td>
            <td></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ShipmentOrdersTable;

