import React from "react";
import { del, get, post } from "../helpers/api_helper";
import SearchableDropdown from "./SearchableDropdown";
import { Button, Col, Row } from "reactstrap";

function AddProductToPopularList() {
  const [products, setProducts] = React.useState([]);
  const [popularProducts, setPopularProducts] = React.useState([]);
  const [query, setQuery] = React.useState("");
  React.useEffect(() => {
    getProducts();
    getPopularProducts();
  }, []);

  async function getProducts() {
    const products = await post("/api/content-management/search-products", {
      query,
    });
    setProducts(
      products.map((el) => ({ ...el, label: el.name, value: el.id }))
    );
  }

  async function getPopularProducts() {
    const products = await get("/api/content-management/add-popular-product");
    setPopularProducts(products);
  }
  async function onSelect(item) {
    await get(`/api/content-management/add-popular-product/${item.id}`);
    await getProducts();
    await getPopularProducts();
  }
  async function deletePopularproduct(item) {
    await del(`/api/content-management/add-popular-product/${item.id}`);
    await getPopularProducts();
    await getProducts();
  }
  return (
    <>
      <h3 style={{ marginTop: "30px" }}>Popular products</h3>
      <Col md={4}>
        <SearchableDropdown options={products} onSelect={onSelect} />
      </Col>

      {popularProducts?.length
        ? popularProducts.map((item) => {
            return (
              <Row
                md={6}
                style={{
                  marginTop: "10px",
                  border: "1px solid #E3E3E3",
                  color: "#5E6363",
                  padding: "5px",
                  marginLeft : '1px',
                  borderRadius : "4px"
                }}
              >
                <Col
                  md={6}
                  style={{display: "flex",justifyContent: "flex-start",alignItems: "center",}}>
                  <b>{item?.product?.name}</b>
                </Col>
                <Col md={6} style={{display : 'flex', justifyContent : 'flex-end', alignItems : 'center'}}>
                  <Button
                    color="primary"
                    className="primary"
                    onClick={() => {
                      deletePopularproduct(item);
                    }}
                  >
                    <i className="mdi mdi-delete font-size-14"></i>
                  </Button>
                </Col>
              </Row>
            );
          })
        : null}

      <div></div>
    </>
  );
}

export default AddProductToPopularList;
