import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import ProductActionDropdown from "../../components/ProductActionDropdown";

const EcommerceOrderColumns = (getAllVendorRequests, categories) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "name",
    text: "Product name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.name}
      </Link>
    ),
  },
  {
    dataField: "description",
    text: "Product description",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.description
          ? row?.description
              ?.replace(/<\/?[^>]+(>|$)/g, "")
              ?.trim()
              ?.slice(0, 9) + "..."
          : "-"}
      </Link>
    ),
  },
  {
    dataField: "image_url",
    text: "Image",
    sort: true,
    formatter: (cellContent, row) => (
      <img
        src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${row.image_urls[0]}`}
        width="40px"
      />
    ),
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.category?.name ? row?.category.name : "-"}
      </Link>
    ),
  },
  {
    dataField: "sub-category",
    text: "Sub-Category",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.sub_category?.name ? row?.sub_category?.name : "-"}
      </Link>
    ),
  },
  {
    dataField: "child-category",
    text: "Child-Category",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.child_category?.name ? row?.child_category?.name : "-"}
      </Link>
    ),
  },

  {
    dataField: "status",
    text: "Active",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.isActive === true ? "success" : "danger")
        }
        color={row.isActive === true ? "success" : "danger"}
        pill
      >
        {row?.isActive ? "ACTIVE" : "HIDDEN"}
      </Badge>
    ),
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <ProductActionDropdown
        row={row}
        getAllVendorRequests={getAllVendorRequests}
        categories={categories}
      />
    ),
  },
];

export default EcommerceOrderColumns;
