import React, { useState } from "react";
import { Dropdown,DropdownItem,DropdownMenu,DropdownToggle } from "reactstrap";
import { useSelector } from "react-redux";
import { put } from "../../helpers/api_helper";
import toastr from "toastr";

function EcomOrderAction({ row, fetchDataAgain, count, isAdmin }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const vendorActiveTab = useSelector((state) => state.Layout.vendorActiveTab);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const orderStatusOptions = {
    PLACED: ["READY_TO_DISPATCH"],
  };

  const currentOptions = orderStatusOptions[vendorActiveTab] || [];

  const statusIcons = {
    READY_TO_DISPATCH: "mdi mdi-truck-delivery",
  };

  const updateVendorRequestStatus = async (status) => {
    try {
      await put(`/api/ecom/update-ecom-order/vendor/${row.id}`, {
        status: status,
      });
      toastr.success("Status updated!", "Success");
      fetchDataAgain();
    } catch (e) {
      toastr.error("Failed to update status", "Error");
      console.log(e);
    }
  };

  return (
    <>
      <Dropdown isOpen={dropdownOpen} direction={count.length <= 4 ? "up" : "down"} toggle={toggleDropdown}>
        <DropdownToggle color="primary" className="primary" caret>
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>
        <DropdownMenu>
          {currentOptions.map((status) => (
            <DropdownItem
              key={status}
              onClick={() => {
                updateVendorRequestStatus(status); 
              }}
            >
              <i className={`${statusIcons[status]} font-size-18 me-2`}></i>
              {status.replace(/_/g, " ")}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

export default EcomOrderAction;
