import React, { useState } from "react";
import {
  Spinner,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import toastr from "toastr";
import Breadcrumbs from "./Common/Breadcrumb";
import { post } from "../helpers/api_helper";
import ShipmentOrdersTable from "./ShipmentOrdersTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Umbrella = () => {
  const navigation = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [expected_date,setExpectedDate] = useState(null)
  const [orders, setOrders] = useState([]);
  const [emptyStatus,setEmptyStatus] = useState(false)
  
  const [containerId, setContainerId] = useState("");
  const [selectedShipment, setSelectedShipment] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");

  async function getAllShipmentOrders() {
    try {
      if (!selectedShipment) {
        toastr.error("Please select shipment type");
        return;
      }
      if (!startDate || !endDate) {
        toastr.error("Please select both start and end date");
        return;
      }
      
      const content = await post("/api/umbrella/get-all-orders", {
        status: "RECEIVED",
        startDate: startDate[0],
        endDate: endDate[0],
        shipment_type: selectedShipment,
      });
      const data = await post("/api/umbrella/marketplace-orders", {
        status: "RECEIVED",
        startDate: startDate[0],
        endDate: endDate[0],
        shipment_type: selectedShipment,
      });
      let allData = [...data, ...content];
      
      setOrders(allData);
      if (allData.length == 0) {
        setEmptyStatus(true);
      } else {
        setEmptyStatus(false);
      }
      setSelectedOrders([]);
    } catch (e) {
      console.log(e);
    }
  }

  async function saveContainer() {
    try {
      if (!containerId?.trim()) {
        toastr.error("Please enter container id");
        return;
      }
      if (!selectedOrders?.length) {
        toastr.error("Please select orders");
        return;
      }
      if (!orderStatus) {
        toastr.error("Please select order status");
        return;
      }

      await post("/api/umbrella/create-umbrella", {
        containerId,
        selectedOrders,
        orderStatus,
        selectedShipment,
        expected_date
      });
      navigation.push("/umbrella");
      toastr.success("Success!");
    } catch (e) {
      console.log(e);
      toastr.error(e?.response?.data?.message || "Something went wrong");
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Umbrella Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Umbrella", link: "/umbrella" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col md="3">
                      <FormGroup>
                        <Label for="containerId">Create Container ID</Label>
                        <Input
                          id="containerId"
                          value={containerId}
                          placeholder="Enter container id"
                          onChange={(e) => setContainerId(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>Select Start Date</Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select start date"
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                          options={{
                            dateFormat: "Y-m-d",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>Select End Date</Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select end date"
                          value={endDate}
                          onChange={(date) => setEndDate(date)}
                          options={{
                            dateFormat: "Y-m-d",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>Select Expected Date</Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select end date"
                          value={expected_date}
                          onChange={(date) => setExpectedDate(date)}
                          options={{
                            dateFormat: "Y-m-d",
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mb-2"></Row>
                  <Col md="3">
                    <FormGroup>
                      <Label>Select Shipment Type:</Label>
                      <DropDownComponent
                        options={["AIR", "SHIP"]}
                        name={selectedShipment}
                        objectToBeUpdated={setSelectedShipment}
                      />
                    </FormGroup>
                  </Col>
                  <Row>
                    <Col md="3">
                      <Button color="primary" onClick={getAllShipmentOrders}>
                        Search Orders
                      </Button>
                    </Col>
                  </Row>

                  {orders?.length ? (
                    <Row className="mt-4">
                      <Col xs="12">
                        <ShipmentOrdersTable
                          orders={orders}
                          selectedOrders={selectedOrders}
                          onOrderSelection={setSelectedOrders}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {emptyStatus == true && <p>No orders received.</p>}

                  {selectedOrders?.length ? (
                    <>
                      <Row className="mb-2 mt-2">
                        <Col md="3">
                          <FormGroup>
                            <Label>Update Order Status:</Label>
                            <DropDownComponent
                              options={["SHIPPED"]}
                              name={orderStatus}
                              defaultValue={orderStatus}
                              objectToBeUpdated={setOrderStatus}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          md="12"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button color="primary" onClick={saveContainer}>
                            Create Container
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export function DropDownComponent({
  options = [],
  name,
  objectToBeUpdated,
  defaultValue = "",
}) {
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(
    defaultValue || "Select Value"
  );

  function toggleDropdown() {
    setDropDownOpen((prev) => !prev);
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#000">
      <DropdownToggle className="bg-primary" caret>
        {selectedValue || name}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((element, index) => (
          <DropdownItem
            key={index}
            onClick={() => {
              setSelectedValue(element);
              objectToBeUpdated(element);
            }}
          >
            {element}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default Umbrella;
