import React, { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import toastr from "toastr";

const VendorPickup = () => {
  const [pickup, setPickup] = useState({address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    zipcode: "",});
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.userAuthInfo.user);
 

  useEffect(() => {
    getPickuplocation();
  }, []);

  async function getPickuplocation() {
    try {
      const content = await post("/api/pickup-location-get", {
        vendor_id: user?.id,
      });
      if(content){
        setPickup(content)
      }
    } catch (e) {
      console.log(e);
    }
  }


  async function submitRequest() {
    try {
      setLoading(true);
      const content = await post("/api/pickup-location-save", {
        ...pickup,
        vendor_id: user?.id,
      });
      setLoading(false);
      toastr.success("Pickup updated successfully!");
    } catch (e) {
      toastr.error("something went wrong!");
      setLoading(false);
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Pickup Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Pickup Management", link: "/pickup" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label for="addreessline1">Address  Line 1</Label>
                        <Input
                          type="text"
                          id="addreessline1"
                          value={pickup?.address_line_1}
                          onChange={(e) =>
                            setPickup((prev) => ({
                              ...prev,
                              address_line_1: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="addreessline2">Address Line 2</Label>
                        <Input
                          type="text"
                          id="addreessline2"
                          value={pickup?.address_line_2}
                          onChange={(e) =>
                            setPickup((prev) => ({
                              ...prev,
                              address_line_2: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="State">
                          State
                        </Label>
                        <Input
                          type="text"
                          id="State"
                          value={pickup?.state}
                          onChange={(e) =>
                            setPickup((prev) => ({
                              ...prev,
                              state: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="City">City</Label>
                        <Input
                          type="text"
                          id="City"
                          value={pickup?.city}
                          onChange={(e) =>
                            setPickup((prev) => ({
                              ...prev,
                              city: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="Zipcode">Zipcode</Label>
                        <Input
                          type="text"
                          id="Zipcode"
                          value={pickup?.zipcode}
                          onChange={(e) =>
                            setPickup((prev) => ({
                              ...prev,
                              zipcode: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "30px auto",
                    }}
                  >
                    <Button
                      size="md"
                      disabled={loading}
                      onClick={submitRequest}
                      style={{
                        backgroundColor: "#0077B6",
                        color: "white",
                      }}
                    >
                      {loading ? (
                        <Spinner
                          style={{ width: "2rem", height: "2rem" }}
                          children={false}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VendorPickup;
